import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import correct from './icons8-correct-30.png'
const PackagesAdmin = () => {

    const navigate = useNavigate();
    const [selectedPackage, setSelectedPackage] = useState(null);

    const handlePackageSelection = (packageName) => {
      setSelectedPackage(packageName); // Set the selected package
      
      // Scroll to the description section
      setTimeout(() => {
        document.querySelector(".package-description").scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100); // Allow state to update before scrolling
    };
    

    const packageDescriptions = {
      Basic: "As a landlord, you will manage your tenants manually on paper. Your tenants won't have access to the dashboard or additional tools. Tenants will book their own transport, and the property will be listed as owned by you.",
      Standard: "This package includes tenant transport for a seamless experience. Property listings and tenant matching are included, ensuring a reliable connection between landlords and tenants. Students can pay online",
      Premium: "All the features of the Standard package, plus advanced tools to independently manage your property. Gain access to enhanced tools for seamless operations.",
      Enterprise: "Includes all Premium features along with full property management services. We'll handle day-to-day operations, so you can relax and watch your property thrive."
    };
  return (
    <div>
      <style>{`



        .header-section h1 {
          font-size: 3.5em;
          font-weight: bold;
          color: #000;
          margin: 0 0 10px;
        }

        .header-section p {
  font-size: 1.5em; /* Adjusted font size */
  max-width: 700px; /* Restricts the width */
  color: #666; /* Text color */
  margin: 0 auto; /* Centers the text block */
  text-align: center; /* Aligns text to center */
}

/* Lower the font size for smaller screens */
@media (max-width: 768px) {
  .header-section h1 {
    font-size: 2em; /* Adjusted font size for mobile */
    text-align: left; /* Adjusted font size for mobile */
    padding : 5px
  }

  .header-section p {
    font-size: 1.0em;
    text-align: left; /* Adjusted font size for mobile */
 padding : 5px
  }
}
        .featured-plans {
          padding: 40px 20px;
          max-width: 1200px;
          margin: auto;
        }

        .featured-plans .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }

        .featured-plans h1 {
          font-size: 1.5em;
          margin: 0;
          font-weight: 600;
        }

        .explore-all {
          font-size: 1em;
          text-decoration: none;
          color: #333;
          font-weight: bold;
        }

        .plans-container {
          display: flex;
          justify-content: space-between;
          gap: 20px;
        }
.plan-card {
  flex: 1;
  min-width: 25%;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 300px; /* Increased height slightly to avoid overflow */
  overflow: hidden; /* Prevent content from spilling out */
}

        .plan-card h4 {
          font-size: 0.9em;
          color: #999;
          text-transform: uppercase;
          margin: 0 0 5px;
          font-weight: 500;
        }

        .plan-card h3 {
          font-size: 1.8em;
          margin: 0 0 10px;
          color: #000;
          font-weight: 600;
        }

        .plan-card p {
          font-size: 11px;
          margin: 0 0 15px;
          color: #666;
          flex-grow: 1;
          line-height: 1; 
        }

        .plan-card hr {
          border: none;
          border-top: 1px solid #ddd;
          margin: 10px 0;
        }

        .plan-card span {
          font-size: 0.85em;
          color: #999;
          margin-bottom: 10px;
        }

        .plan-card .price {
          font-size: 1.8em;
          font-weight: bold;
          margin-top: 10px;
          color: #000;
        }

        .plan-card button {
          position: absolute;
          bottom: 20px;
          right: 20px;
          background-color: #000;
          color: #fff;
          border: none;
          border-radius: 30%;
          width: 40px;
          height: 40px;
          font-size: 1.2em;
          cursor: pointer;
        }

        .plan-card.green {
          background-color: #ff5a5f;
        }

        .plan-card.black {
          background-color: #ff5a5f;
          color: #fff;
        }

        .plan-card.black button {
          background-color: #fff;
          color: #000;
        }

        .plan-card.purple {
          background-color: #ff5a5f;
        }

        .plan-card.yellow {
          background-color: #ff5a5f;
        }

        @media (max-width: 768px) {
          .plans-container {
            flex-direction: column;
            align-items: center;
          }
          .plan-card {
            max-width: 100%;
            margin-bottom: 20px;
          }
        }
          /* Recommended Plan Badge */
.plan-card.recommended {
  background-color: #ff5a5f; /* Light orange background */
  position: relative;
}

.plan-card.recommended .recommended-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff5a5f; /* Bright orange for badge */
  color: #fff;
  font-size: 0.8em;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
}.plan-card .price {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 1.8em;
  font-weight: bold;
  margin-top: 10px;
}


      `}</style>

      <section className="header-section">
      <h1>List, Relax, and <br/> Let Us Handle the Rest</h1>
<p>List your student property with us and unlock its full potential. From seamless management to maximizing your rental success, we handle the hard work so you can enjoy the rewards stress-free.</p>

      </section>

      <section className="featured-plans">
        <div className="header">
          <h1>Featured Packages</h1>
        
        </div>
        <div className="plans-container">
  <div className="plan-card green">
    <h4 style={{ color: '#F8EDED' }}>Sales Only</h4>
    <h3 style={{ color: '#F8EDED' }}>Basic</h3>
    <p style={{ color: '#F8EDED' }}>
    Focus solely on connecting with tenants while they handle their own transport.    </p>
    <p style={{ color: '#F8EDED'  }}>
    <img src={correct} style={{width:'8%'}}/> Property Listings & Tenant Matching <br/> 
     <img src={correct} style={{width:'8%'}}/> Tenant-Focused Flexibility 
    </p>
    
    <hr style={{ borderColor: '#F8EDED' }} />
    
    <div className="price">
      <i className="fas fa-shopping-cart" style={{ color: '#F8EDED' }}></i> {/* Sales Icon */}
    </div>
    <button style={{ color: '#F8EDED' }} onClick={() => handlePackageSelection("Basic")}>→</button>
  </div>
  <div className="plan-card green">
    <h4 style={{ color: '#F8EDED' }}>Sales & Transport</h4>
    <h3 style={{ color: '#fff' }}>Standard</h3>
    <p style={{ color: '#fff', marginBottom:'5px' }}>
    Includes tenant transport, offering a seamless and reliable service.    </p>
    <p style={{ color: '#F8EDED', lineHeight: '0.7'  }}>
    <img src={correct} style={{width:'8%'}}/> Property Listings & Tenant Matching <br/> 
     <img src={correct} style={{width:'8%'}}/> Tenant Transportation
    </p>
    
    <hr />
    
    <div className="price">
      <i className="fas fa-bus" style={{ color: '#F8EDED' }}></i> {/* Transport Icon */}
    </div>
    <button  onClick={() => handlePackageSelection("Standard")}>→</button>
  </div>
  <div className="plan-card purple">
    <h4 style={{ color: '#F8EDED' }}>Sales, Transport & Tools</h4>
    <h3 style={{ color: '#fff' }}>Premium</h3>
    <p style={{ color: '#F8EDED' }}>Includes everything in Standard, plus tools for independent property management.</p>
    <p style={{ color: '#F8EDED' }}>
    <img src={correct} style={{width:'8%'}}/> Property Listings & Tenant Matching <br/> 
    <img src={correct} style={{width:'8%'}}/> Tenant Transportation <br/> 
    <img src={correct} style={{width:'8%'}}/> Advanced Management Tools</p>
 
    <hr style={{ borderColor: '#F8EDED' }} />
    
    <div className="price">
      <i className="fas fa-cogs" style={{ color: '#F8EDED' }}></i> {/* System Icon */}
    </div>
    <button  onClick={() => handlePackageSelection("Premium")}>→</button>
  </div>
  <div className="plan-card recommended">
    <div className="recommended-badge">Recommended</div>
    <h4 style={{ color: '#F8EDED' }}>Management</h4>
    <h3 style={{ color: '#fff' }}>Enterprise</h3>
    <p style={{ color: '#F8EDED' }}>
    All Premium features plus complete management of your property.    </p>
    <p style={{ color: '#F8EDED' }}>
    <img src={correct} style={{width:'8%'}}/> Property Listings & Tenant Matching <br/> 
    <img src={correct} style={{width:'8%'}}/> Tenant Transportation <br/> 
    <img src={correct} style={{width:'8%'}}/> Advanced Management Tools <br/> 
    <img src={correct} style={{width:'8%'}}/> Day-to-Day Management</p>
    <hr style={{ borderColor: '#F8EDED' }} />
    
    <div className="price">
 
      <i className="fas fa-users" style={{ color: '#F8EDED' }}></i> {/* Management Icon */}
    </div>
    <button  onClick={() => handlePackageSelection("Enterprise")}>→</button>
  </div>
</div>


      </section>
      {selectedPackage && (
        <section className="package-description">
          <style>{`
            .package-description {
              background-color: #f9f9f9;
              padding: 20px;
              border-radius: 10px;
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
              margin: 20px auto;
              max-width: 800px;
              text-align: left;
            }

            .package-description h2 {
              font-size: 2em;
              font-weight: bold;
              color: #333;
              margin-bottom: 10px;
            }

            .package-description p {
              font-size: 1.2em;
              color: #666;
              line-height: 1.5;
            }

            .package-description button {
              margin-top: 20px;
              padding: 10px 20px;
              font-size: 1em;
              background-color: #000;
              color: #fff;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              transition: background-color 0.3s ease;
            }

            .package-description button:hover {
              background-color: #333;
            }
          `}</style>
          <h2>{selectedPackage} Package</h2>
          <p>{packageDescriptions[selectedPackage]}</p>
          <button onClick={() => navigate("/RegisterAdmin", { state: { packageName: selectedPackage } })}>
            Select {selectedPackage} Package
          </button>
        </section>
      )}
    </div>
  );
};

export default PackagesAdmin;
