import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthProvider';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import stars from './icons8-star-24.png'
import starg from './icons8-star.gif'

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './SignupTransport.css';
import './reserveNew.css';

import { PDFDocument, rgb } from 'pdf-lib'; // `pdf-lib` for browser-compatible PDF manipulation

function SignupTransport() {
    const { isLoggedIn, userId } = useAuth();
    const [userData, setUserData] = useState(null);
    const [selectedPrice, setSelectedPrice] = useState('R250');
    const [selectedUniversity, setSelectedUniversity] = useState('');
    const [address, setAddress] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState('');
    const [step, setStep] = useState(1);
    const [transport, setTransport] = useState('');

    const getShortRoomIdentifier = (address) => {
        const commaIndex = address.indexOf(',');
        return commaIndex !== -1 ? address.substring(0, commaIndex) : address;
    };
    const generatePDF = async () => {
        // If the user is logged in, proceed with generating the PDF
        if (userData) {
            try {
                // Fetch the PDF file as an array buffer
                const response = await fetch('https://jaesstudenthomes.co.za/STUDENT%20HOMES%20BOOKING%20FORM%202025.pdf');
                const pdfBytes = await response.arrayBuffer();
    
                // Load PDF using pdf-lib
                const pdfDoc = await PDFDocument.load(pdfBytes);
                const firstPage = pdfDoc.getPages()[0];
    
                // Add user data to the PDF
                firstPage.drawText(`${userData.Name} ${userData.Surname}`, { x: 230, y: 535, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(userData.IdNumber || userData.PassportNumber, { x: 230, y: 510, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(getShortRoomIdentifier(address), { x: 180, y: 790, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText('X', { x: 385, y: 490, size: 12, color: rgb(0, 0, 0) });
                // firstPage.drawText("X", { x: 230, y: 490, size: 12, color: rgb(0, 0, 0) });

                firstPage.drawText(address || 'N/A', { x: 230, y: 465, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(userData.Email, { x: 265, y: 440, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(userData.PhoneNumber, { x: 255, y: 415, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(new Date().toLocaleDateString('en-GB'), { x: 170, y: 85, size: 12, color: rgb(0, 0, 0) });
    
                // Save the modified PDF to a blob and trigger download
                const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
                const link = document.createElement('a');
                link.href = pdfDataUri;
                link.download = `Booking_Form_${Date.now()}.pdf`;
                document.body.appendChild(link); // Append link to the DOM for Firefox support
                link.click();
                document.body.removeChild(link); // Remove link after download
    
                console.log('PDF successfully created and downloaded.');
            } catch (error) {
                console.error('Error creating PDF:', error.message);
            }
        } else {
            console.error("User data is not available for generating PDF");
        }
    };

    const nextStep = () => {
      if (step === 1 && !selectedUniversity) {
          Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Please select a university.",
              showConfirmButton: false,
              timer: 12000
          });
          return;
      }
  
      if (step === 2 && !address) {
          Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Please enter an address.",
              showConfirmButton: false,
              timer: 12000
          });
          return;
      }
  
      setStep((prevStep) => Math.min(prevStep + 1, 3));
  };
      const prevStep = () => setStep((prevStep) => Math.max(prevStep - 1, 1));
    const navigate = useNavigate();

    const handleTransportChange = (event) => {
      setTransport(event.target.value);
    };
    // Coordinates for Ukzn Howard University
    const ukznHowardCoordinates = L.latLng(-29.8667, 30.9784);

    // Bounding box for Durban, South Africa (approximate)
    const durbanBoundingBox = {
        minLon: 30.8577,
        minLat: -30.0761,
        maxLon: 31.1295,
        maxLat: -29.7454
    };

    useEffect(() => {
        if (isLoggedIn && userId) {
            const fetchUserData = async () => {
                try {
                    const response = await fetch(`https://backendjaes.passiontrials.co.za/user_reserve/${userId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    
                    if (!response.ok) throw new Error('Failed to fetch user data');
                    const data = await response.json();
                    setUserData(data.length ? data[0] : data);
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            };
            fetchUserData();
        }
    }, [isLoggedIn, userId]);

    const handleProceedToPayment = async () => {
        if (!address  || !selectedUniversity) {
            //alert("Please select all options.");
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Please select all options.",
              showConfirmButton: false,
              timer: 3500
          });
            return;
        }

        const amountInCents = parseInt(selectedPrice.replace('R', '')) * 100;
        const successUrl = `https://jaesstudenthomes.co.za/transportpayment?userId=${encodeURIComponent(userId)}&address=${encodeURIComponent(address)}&price=${encodeURIComponent(selectedPrice)}&university=${encodeURIComponent(selectedUniversity)}`;

        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/api/pay', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    amount: amountInCents,
                    currency: 'ZAR',
                    successUrl,
                    cancelUrl: 'https://jaesstudenthomes.co.za/cancel?status=cancel',
                    room: `${address}-${userId}`,
                }),
            });

            if (!response.ok) throw new Error('Payment initiation failed');

            const data = await response.json();
            const paymentUrl = data.paymentUrl || data.redirectUrl || data.url || null;

            if (paymentUrl) {
                setPaymentStatus('Redirecting to payment gateway...');
                window.location.href = paymentUrl;
            } else {
                setPaymentStatus('No payment URL received. Please contact support.');
                console.error('No payment URL received:', data);
            }
        } catch (error) {
            console.error('Payment initiation error:', error);
            setPaymentStatus('Payment initiation failed. Please try again.');
        }
    };

    const handlePayViaEFT = async () => {
        const numericPrice = parseFloat(selectedPrice.replace('R', '')); // Convert price to a number
        const eftData = {
            userId,
            building: address,
            price: numericPrice,
            university: selectedUniversity,
            paymentStatus: 'Deactivated', // Payment status for EFT
        };
    
        // Save payment details with 'Deactivated' status
        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/save_payment', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userId,
                    building: address,
                    price: numericPrice,
                    university: selectedUniversity,
                    paymentStatus: 'Deactivated',
                    transRole: 'usertransport',
                }),
            });
    
            if (!response.ok) throw new Error('Failed to save EFT payment details');
            console.log('EFT payment details saved successfully.');
    
            // Redirect to TransportPayment with EFT data
            navigate(`/TransportPayment?userId=${encodeURIComponent(userId)}&address=${encodeURIComponent(address)}&price=${encodeURIComponent(selectedPrice)}&university=${encodeURIComponent(selectedUniversity)}&paymentStatus=Deactivated`);
        } catch (error) {
            console.error('Error saving EFT payment details:', error);
        }
    
        // Generate and download the PDF
        await generatePDF();
    };
    
    const handlePayViaEFTAndGeneratePDF = async () => {
        try {
            // Generate the PDF
            await generatePDF();
    
            // Proceed with saving the payment details and navigating
            await handlePayViaEFT();
        } catch (error) {
            console.error('Error handling Pay via EFT and PDF generation:', error);
        }
    };
    
    const handleAddressChange = async (event) => {
        const query = event.target.value;
        setAddress(query);
    
        if (query.length > 2) {
            const apiKey = 'f4c51dd4a35e4aeda050b373039cfc83';
            const url = `https://api.geoapify.com/v1/geocode/autocomplete?text=${encodeURIComponent(query)}&filter=countrycode:za&bias=rect:${durbanBoundingBox.minLon},${durbanBoundingBox.minLat},${durbanBoundingBox.maxLon},${durbanBoundingBox.maxLat}&limit=5&apiKey=${apiKey}`;
    
            try {
                const response = await fetch(url);
                const data = await response.json();
                const filteredSuggestions = data.features.map((feature) => {
                    const { housenumber, street, suburb } = feature.properties;
                    const displayName = `${housenumber || ''} ${street || ''}, ${suburb || ''}`.trim();
                    return {
                        ...feature,
                        display_name: displayName,
                        lat: feature.geometry.coordinates[1],
                        lon: feature.geometry.coordinates[0],
                    };
                });
                setSuggestions(filteredSuggestions);
            } catch (error) {
                console.error("Error fetching suggestions:", error);
                setSuggestions([]);
            }
        } else {
            setSuggestions([]);
        }
    };
    
    const handleSuggestionClick = (suggestion) => {
      const selectedLatLng = L.latLng(suggestion.lat, suggestion.lon);
      
      if (selectedUniversity === "Ukzn Howard") {
          const distance = selectedLatLng.distanceTo(ukznHowardCoordinates);
          
          if (distance <= 2500) {
              setAddress(suggestion.display_name); // Use the filtered display_name
              setSuggestions([]); // Clear suggestions after selection
          } else {
              Swal.fire({
                  position: "top-end",
                  icon: "error",
                  title: "Selected address is more than 2.5 km away from Ukzn Howard University.",
                  showConfirmButton: false,
                  timer: 1500
              });
              setAddress(''); // Clear address if it's out of range
          }
      } else if (selectedUniversity === "DUT Steve Biko") {
          // No radius check for DUT, just set the address
          setAddress(suggestion.display_name);
          setSuggestions([]);
      }
  };
  
    return (
        <div>
             <div className="full-page-container">
      <div className="full-page-content">
        <span className="close-btn" >×</span>
        {userData ? (
                <div>
                    <h1 class="subsection-module__title">Hi {userData.Name}, let's complete your booking</h1>

                </div>
            ) : (
                <p>Loading user information...</p>
            )}
       {/* Step Count */}
       <div className="amber-form-group2">
<p style={{textAlign:'left'}}>Step {step} / 3</p>
   
    </div>


        {/* Progress Bar */}
        <div className="progress-container">
          {[1, 2, 3].map((bar) => (
            <div key={bar} className={`progress-bar ${step >= bar ? 'filled' : ''}`} />
          ))}
        </div>

        {/* Step Content */}
        {step === 1 && (
          <div>
        <h2 class="subsection-module__title"> University </h2>
        

        <div className="amber-form-group2">
<p style={{textAlign:'left'}}>Please select your school to ensure accurate pricing, route planning, and availability of our transport service tailored specifically for your institution.</p>
   
    </div>
    <style>{` .suggestions-list {
    list-style: none;
    padding: 0;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    width: 100%; /* Matches the input field width */
    background-color: #fff;
    z-index: 1000; /* Ensures it appears above other elements */
    position: absolute; /* Keeps it aligned with the input field */
}

    .step-count {
    text-align: left;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px; /* Adjust spacing as needed */
}
    .checkbox-wrapper input[type="checkbox"] {
  display: none;
}

.checkbox-wrapper .terms-label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.checkbox-wrapper .terms-label .label-text {
  margin-right: 10px;
}

.checkbox-wrapper .checkbox-svg {
  width: 30px;
  height: 30px;
}

.checkbox-wrapper .checkbox-box {
  fill: rgba(207, 205, 205, 0.425);
  stroke: #ff5a5f;
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper .checkbox-tick {
  stroke: #ff5a5f;
  stroke-dasharray: 172;
  stroke-dashoffset: 172;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-box,
.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-tick {
  stroke-dashoffset: 0;
}.divider-line {
    min-width: 400px;
    border: 0;
    border-top: 10px solid #000;
    margin: 10px 0;
}

.button-group {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.button-group button {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

`}</style>
<label style={{ display: 'block', textAlign: 'left', fontSize:'17px' }}>Choose University:</label>
<div className="form-grid">
            
<div style={{ display: 'flex', gap: '15px', marginTop: '8px' }}>
  <div className="checkbox-wrapper" onClick={() => setSelectedUniversity("Ukzn Howard")}>
    <input
      id="ukzn-checkbox"
      name="checkbox"
      type="checkbox"
      checked={selectedUniversity === "Ukzn Howard"}
      readOnly
    />
    <label className="terms-label" htmlFor="ukzn-checkbox">
      <span className="label-text">Ukzn Howard</span>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
        <mask fill="white" id="path-1-inside-1_476_5-ukzn">
          <rect height="200" width="200"></rect>
        </mask>
        <rect mask="url(#path-1-inside-1_476_5-ukzn)" strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
        <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
      </svg>
    </label>
  </div>

  <div className="checkbox-wrapper" onClick={() => setSelectedUniversity("DUT Steve Biko")}>
    <input
      id="dut-checkbox"
      name="checkbox"
      type="checkbox"
      checked={selectedUniversity === "DUT Steve Biko"}
      readOnly
    />
    <label className="terms-label" htmlFor="dut-checkbox">
      <span className="label-text">DUT</span>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
        <mask fill="white" id="path-1-inside-1_476_5-dut">
          <rect height="200" width="200"></rect>
        </mask>
        <rect mask="url(#path-1-inside-1_476_5-dut)" strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
        <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
      </svg>
    </label>
  </div>
</div>


            <button className="btn" style={{color:'white'}} onClick={nextStep}>Next</button>
          </div>
          </div>
        )}

        {step === 2 && (
          <div>
            <h2 class="subsection-module__title" > Accomodation Address </h2>
            <div className="amber-form-group2">
<p style={{textAlign:'left'}}>please enter your home pickup address. If you've chosen Ukzn Howard, make sure the address is within 2.5 km of the campus to keep our routes quick and affordable. If you've chosen DUT, you can enter any address.</p>
   
    </div>
    <label style={{ textAlign: 'left', display: 'block', fontWeight:'700' }}>
    Enter Address:
</label>
<input
    type="text"
    value={address}
    onChange={handleAddressChange}
    placeholder="Enter your address..."
    style={{
        display: 'block', // Ensures the input field starts on a new line
        width: '98%', // Optional: Adjust the width as needed
        marginTop: '5px', // Adds space between the label and the input field
        padding: '8px', // Adds padding for better usability
        border: '1px solid #ccc', // Adds a subtle border
        borderRadius: '5px', // Optional: Rounds the corners
    }}
/>
<ul className="suggestions-list" style={{ textAlign: 'left', listStyleType: 'none', padding: 0, marginTop: '5px' }}>
    {suggestions.map((suggestion, index) => (
        <li
            key={index}
            onClick={() => handleSuggestionClick(suggestion)}
            style={{
                padding: '5px',
                borderBottom: '1px solid #ddd',
                cursor: 'pointer',
            }}
        >
            {suggestion.display_name}
        </li>
    ))}
</ul>

            <div className="button-group">
    <button className="btn back-btn" style={{color:'white'}}  onClick={prevStep}>Back</button>
    <button className="btn next-btn" style={{color:'white'}}  onClick={nextStep}>Next</button>
</div>

<style>{`.button-group {
    display: flex;
    justify-content: space-between; /* Positions buttons on opposite sides */
    align-items: center; /* Ensures buttons are vertically aligned */
    margin-top: 20px; /* Adds space above the button group */
    width: 100%; /* Ensures the buttons stretch across the container width */
}

.btn {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.back-btn {
    background-color: #d9534f; /* Red for the Back button */
}

.next-btn {
    background-color: #76964c; /* Green for the Proceed to Payment button */
}
.next-btn:hover {
    background-color: green; /* Green for the Proceed to Payment button */
}

.btn:hover {
    opacity: 0.8; /* Adds a hover effect */
}

`}</style>

          </div>
        )}
{step === 3 && (
    <div>
     <div class="unique-card">
     <div class="unique-property-header">
         <img src="https://images.pexels.com/photos/6816993/pexels-photo-6816993.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Property Image" class="unique-property-image" />
         <div class="unique-property-details">
             <h3>Jaes student transport</h3>
             <p>Durban</p>
             <p><img src={starg} width={`16px`} /> 4.86 </p>
         </div>
     </div>
 
     <hr class="unique-divider-line" />
 
     <div class="unique-price-details">
         <h4>Price details</h4>
         <div class="unique-price-item">
             <span>University</span>
             <span>{selectedUniversity || 'Not selected'}</span>
         </div>
 
         <div class="unique-price-item">
             <span>Address</span>
             <span style={{textAlign:'right'}}>{address || 'Not entered'}</span>
         </div>
         <div class="unique-price-item">
             <span>Amount</span>
             <span className='strikethrough'>R450</span>
         </div>
         <div class="unique-price-item">
             <span>Discount</span>
             <span>45% off</span>
         </div>
         <hr class="unique-divider-line" />
         <div class="unique-price-total">
             <strong>Total (ZAR)</strong>
             <strong>R250</strong>
         </div>
         
     </div>
     <style>{`
 
     /* Container card */
        .unique-card {
            max-width: 400px; /* Reduced width */
            margin: 20px auto;
            padding: 20px;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
           font-family: "Hepta Slab", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
        }

        /* Property header section */
        .unique-property-header {
            display: flex;
            gap: 15px;
            align-items: center;
               text-align: left; 
        }

        .unique-property-image {
            width: 100px;
            height: 100px;
            border-radius: 10px;
            object-fit: cover;
        }

        .unique-property-details h3 {
            font-size: 16px;
            margin: 0;
            color: #333;

        }

        .unique-property-details p {
            margin: 5px 0;
            font-size: 14px;
            color: #666;
        }

        /* Divider line */
        .unique-divider-line {
            border: none;
            border-top: 1px solid #ddd;
            margin: 20px 0;
        }

        /* Price details section */
        .unique-price-details h4 {
            font-size: 16px;
            margin: 0 0 10px;
            color: #333;
        }

        .unique-price-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            font-size: 14px;
            color: #555;
        }

        .unique-price-item a.unique-link {
            text-decoration: none;
            color: #007bff;
        }

        .unique-price-item a.unique-link:hover {
            text-decoration: underline;
        }

        /* Total price section */
        .unique-price-total {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            color: #333;
            margin-top: 10px;
            font-weight: bold;
        }`}</style> <div className="button-group">
           <button
                className="btn mahogany-btn"
                 onClick={prevStep}
          >
               Back
          </button>
           <button
               className="btn oak-btn"
                onClick={handleProceedToPayment}
            >
                 Pay
            </button>
        </div>
        <button 
    className="btn" 
    onClick={handlePayViaEFTAndGeneratePDF} 
    style={{ marginTop: '10px', width: "97%" }}
>
    Pay via EFT
</button>

 </div>

 </div>

    
)}

<style>{`

.confirmation-card {
    background-color: #fff; /* Card background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    max-width: 600px; /* Limit width */
    margin: 0 auto; /* Center alignment */
    border: 1px solid #e6e6e6; /* Border for structure */
}

.review-container {
    margin-top: 20px;
}

.review-section {
    display: flex;
    flex-direction: column;
    gap: 0px; /* Space between items */
}

.review-item {
    display: flex;
    justify-content: space-between; /* Space out label and value */
    align-items: center;
}

.review-label {
    font-weight: bold;
    color: #333;
    flex: 1; /* Allows labels to align consistently */
}

.review-value {
    color: #555;
    text-align: right;
    flex: 1;
}

.strikethrough {
    text-decoration: line-through;
    color: #999;
}

.divider-line {
    border: none;
    border-top: 1px solid #ccc;
    margin: 15px 0;
}

.total-section .review-item {
    font-weight: bold; /* Highlight the total */
}

.button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.btn {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Mahogany Button (Back Button) */
.mahogany-btn {
    background-color: #ff5a5f;
    width: 70%
}

.mahogany-btn:hover {
    background-color: green;
    color: white
}

/* Oak Button (Proceed to Payment Button) */
.oak-btn {
    background-color: #76964C;
     width: 70%
}

.oak-btn:hover {
    background-color: #8DB255;
    color: white
}


.button-group {
    display: flex;
    justify-content: space-between; /* Align buttons to opposite ends */
    align-items: center;
    margin-top: 20px; /* Adds space above the button group */
    width: 100%; /* Ensures the buttons span across the container width */
}

.btn {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Mahogany Button (Back Button) */
.mahogany-btn {
    background-color: #8B4513; /* Mahogany-like brown */
}

.mahogany-btn:hover {
    background-color: #A0522D; /* Slightly lighter mahogany color for hover */
}

/* Oak Button (Proceed to Payment Button) */
.oak-btn {
    background-color: #76964C; /* Oak-inspired greenish-brown */
}

.oak-btn:hover {
    background-color: #8DB255; /* Lighter oak-inspired color for hover */
}

.review-container {
    display: grid;
    grid-template-columns: 1fr auto; /* Two columns: labels and values */
    row-gap: 5px; /* Minimal vertical spacing between rows */
    column-gap: 10px; /* Minimal spacing between labels and values */
    align-items: center; /* Aligns labels and values vertically */
    margin-top: 20px; /* Adds spacing above the review section */
}

.review-item {
    display: contents; /* Ensures grid layout is preserved */
}

.review-label {
    text-align: left;
    font-weight: bold;
    color: #333;
}

.review-value {
    text-align: right;
    color: #555;
    margin-left: 5px; /* Slight margin to separate from label */
}

.strikethrough {
    text-decoration: line-through;
    color: #999;
}


.review-left,
.review-right {
    flex: 1;
}

.separator {
    width: 2px;
    background-color: #000;
    height: 100%;
    margin: 0 20px;
}

.amount-details {
    display: grid;
    grid-template-columns: auto auto; /* Two columns: one for labels and one for values */
    row-gap: 5px;
    column-gap: 20px; /* Adjusts spacing between labels and values */
}

.amount-labels,
.amount-values {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.discount-highlight {
    font-weight: bold;
}

.strikethrough {
    text-decoration: line-through;
    color: #999;
}

`}</style>

      </div>
    </div>
        </div>
    );
}

export default SignupTransport;
