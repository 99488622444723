import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider"; // Import useAuth

const Steps5Admin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useAuth(); // Get the user object from the auth context

  // Receive data from Steps4Admin
  const { typeOfHouse, typeOfPlace, streetAddress, suburb, city, province, postalCode } =
    location.state || {};

  // State for the inputs on this page
  const [values, setValues] = useState({
    bedrooms: 5,
    beds: 7,
    bathrooms: 3.5,
  });

  useEffect(() => {
    // Initialize buttons to disabled state if needed
    ["bedrooms", "beds", "bathrooms"].forEach((key) => {
      if (values[key] === 0) {
        document.getElementById(`${key}-decrement`).disabled = true;
      }
    });
  }, [values]);

  // Update values when buttons are clicked
  const updateValue = (key, change) => {
    setValues((prevValues) => {
      const newValue = Math.max(0, prevValues[key] + change);
      return { ...prevValues, [key]: newValue };
    });
  };

  // Navigate back to the previous step
  const handleBack = () => {
    navigate(-1);
  };

  // Navigate to Steps6Admin and pass all data
  const handleNext = async () => {
    // Perform the update before navigating to the next step
    const AdminId = userId; // Replace with the correct property from your user object
  
    if (!AdminId) {
      alert("Unable to fetch Admin ID. Please log in.");
      return;
    }
  
    const payload = {
      AdminId,
      PropertyType: typeOfHouse,
      Guest: typeOfPlace,
      FullAddress: `${streetAddress}, ${suburb}, ${city}, ${province}, ${postalCode}`,
      CountryOrRegion: "South Africa",
      StreetAddress: streetAddress,
      Suburb: suburb,
      CityOrVillage: city,
      Province: province,
      PostalCode: postalCode,
      BedroomCount: values.bedrooms,
      BedCount: values.beds,
      BathroomCount: values.bathrooms,
    };
  
    try {
      const response = await fetch("https://backendjaes.passiontrials.co.za/api/update-admin-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const result = await response.text();
        console.log("Data updated successfully: " + result);
  
        // Navigate to the next step after successful update
        navigate("/Steps6Admin", {
          state: {
            typeOfHouse,
            typeOfPlace,
            streetAddress,
            suburb,
            city,
            province,
            postalCode,
            ...values, // Include the data from this page
          },
        });
      } else {
        const error = await response.text();
        console.error("Error updating data:", error);
        alert("Failed to update data.");
      }
    } catch (err) {
      console.error("Error:", err);
      alert("An unexpected error occurred.");
    }
  };
  

  

  return (
    <div className="continental-container">
      <style>{`
        .continental-container {
          max-width: 600px;
          width: 100%;
          padding: 30px;
          background: #fff;
          border-radius: 8px;
          box-sizing: border-box;
          margin: 0 auto;
          font-family: Arial, sans-serif;
        }
        .continental-title {
          font-size: 24px;
          font-weight: 600;
          color: #242323;
          margin-bottom: 20px;
        }
        .continental-description {
          font-size: 14px;
          color: #555;
          margin-bottom: 20px;
        }
        .continental-item-group {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #ddd;
          padding: 10px 0;
        }
        .continental-item-label {
          font-size: 16px;
          color: #333;
        }
        .continental-item-controls {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .continental-control-button {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #ddd;
          border-radius: 50%;
          font-size: 18px;
          color: #333;
          cursor: pointer;
          transition: all 0.2s ease;
          background: #fff;
        }
        .continental-control-button:hover {
          color: #ddd;
        }
        .continental-control-button:disabled {
          border-color: #ddd;
          color: #ddd;
          cursor: not-allowed;
        }
        .continental-item-value {
          font-size: 16px;
          color: #333;
        }
       .progress-step1cont {
  position: fixed; /* Fix the container at a specific position */
  bottom: 0; /* Place it at the bottom of the viewport */
  left: 0;
  right: 0;
  width: 100%; /* Make sure it spans the full width */
  max-width: 1200px;
  margin: 0 auto; /* Center it horizontally */
  padding: 0 10px;
  box-sizing: border-box;
  background: #fff; /* Add a background to prevent overlap issues */
  z-index: 1000; /* Ensure it stays above other elements */
}

        .progress-bar {
          height: 4px;
          background: #e0e0e0;
          margin-bottom: 5px;
          position: relative;
        }
        .progress {
          height: 100%;
          width: 83.32%; /* Adjust based on progress */
          background: #000;
          transition: width 0.3s ease-in-out;
        }
        .buttons-step1cont {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 10px;
        }
        .back-button {
          text-decoration: underline;
          color: #000;
          background: none;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 12px;
        }
        .next-button {
          background: #000;
          max-width: 50px;
          color: #fff;
          border: none;
          padding: 5px 10px;
          border-radius: 4px;
          font-size: 12px;
          cursor: pointer;
        }        
@media (max-width: 768px) {
  .buttons-step1cont {
    justify-content: flex-start; /* Align all buttons to the left */
  }
  .next-button {
    margin-left: 150px; /* Add some spacing from the left edge */
  }
}
      `}</style>
      <h3 className="continental-title">Share some basics about your house</h3>
      <p className="continental-description">You'll add more details later, like bed types.</p>
      {/* <div className="data-display">
        <h4>User and Previous Page Data:</h4>
        <p><strong>User ID:</strong> {userId}</p>
        <p><strong>Type of House:</strong> {typeOfHouse}</p>
        <p><strong>Type of Place:</strong> {typeOfPlace}</p>
        <p><strong>Street Address:</strong> {streetAddress}</p>
        <p><strong>Suburb:</strong> {suburb}</p>
        <p><strong>City:</strong> {city}</p>
        <p><strong>Province:</strong> {province}</p>
        <p><strong>Postal Code:</strong> {postalCode}</p>
      </div> */}
      <div className="continental-item-group">
        <span className="continental-item-label">Bedrooms</span>
        <div className="continental-item-controls">
          <button
            className="continental-control-button"
            id="bedrooms-decrement"
            onClick={() => updateValue("bedrooms", -1)}
            disabled={values.bedrooms === 0}
          >
            −
          </button>
          <span className="continental-item-value">{values.bedrooms}</span>
          <button
            className="continental-control-button"
            onClick={() => updateValue("bedrooms", 1)}
          >
            +
          </button>
        </div>
      </div>
          <div className="continental-item-group">
        <span className="continental-item-label">Beds</span>
        <div className="continental-item-controls">
          <button
            className="continental-control-button"
            id="beds-decrement"
            onClick={() => updateValue("beds", -1)}
            disabled={values.beds === 0}
          >
            −
          </button>
          <span className="continental-item-value">{values.beds}</span>
          <button
            className="continental-control-button"
            onClick={() => updateValue("beds", 1)}
          >
            +
          </button>
        </div>
      </div>
      <div className="continental-item-group">
        <span className="continental-item-label">Bathrooms</span>
        <div className="continental-item-controls">
          <button
            className="continental-control-button"
            id="bathrooms-decrement"
            onClick={() => updateValue("bathrooms", -0.5)}
            disabled={values.bathrooms === 0}
          >
            −
          </button>
          <span className="continental-item-value">{values.bathrooms}</span>
          <button
            className="continental-control-button"
            onClick={() => updateValue("bathrooms", 0.5)}
          >
            +
          </button>
        </div>
      </div>
      <div className="progress-step1cont">
        <div className="progress-bar">
          <div className="progress"></div>
        </div>
        <div className="buttons-step1cont">
          <button className="back-button" onClick={handleBack}>
            Back
          </button>
          {/* <button className="next-button" onClick={handleUpdate}>
            Update Data
          </button> */}
          <button className="next-button" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Steps5Admin;
