import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Customers.css';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';

function Customers() {
    const [tenantDetails, setTenantDetails] = useState([]);
    const [selectedTenants, setSelectedTenants] = useState([]);

    const [walletData, setWalletData] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'Name', direction: 'asc' });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showNoCustomersMessage, setShowNoCustomersMessage] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch('https://backendjaes.passiontrials.co.za/new_all_user_full_details')
            .then(res => res.json())
            .then(data => {
                console.log("API Response:", data);
                if (Array.isArray(data)) {
                    setTenantDetails(data);
                } else {
                    setTenantDetails([]);
                    setError("Unexpected data format received from API.");
                }
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching tenant details:', err);
                setError('Failed to fetch tenant details');
                setLoading(false);
            });
    }, []);
    

    const handleCheckboxChange = (userId) => {
        setSelectedTenants(prev => 
            prev.includes(userId) ? prev.filter(id => id !== userId) : [...prev, userId]
        );
    };

    const deleteSelectedTenants = () => {
        if (selectedTenants.length === 0) return;
    
        const confirmDelete = window.confirm(
            `Are you sure you want to delete ${selectedTenants.length} tenant(s)?`
        );
    
        if (!confirmDelete) return;
    
        fetch('https://backendjaes.passiontrials.co.za/delete_tenants', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userIds: selectedTenants }),
        })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    setTenantDetails(prev =>
                        prev.filter(tenant => !selectedTenants.includes(tenant.UserId))
                    );
                    setSelectedTenants([]);
                } else {
                    setError('Failed to delete selected tenants');
                }
            })
            .catch(err => {
                console.error('Error deleting tenants:', err);
                setError('Failed to delete selected tenants');
            });
    };
    

    useEffect(() => {
        if (tenantDetails.length > 0) {
            tenantDetails.forEach(user => fetchWalletData(user.UserId, user.ApartmentId));
        }
    }, [tenantDetails]);

    const fetchWalletData = (userId, apartmentId) => {
        fetch(`https://backendjaes.passiontrials.co.za/wallet/${userId}/${apartmentId}`)
            .then(res => res.json())
            .then(data => {
                setWalletData(prev => ({
                    ...prev,
                    [userId]: data.length > 0 ? data[0] : null
                }));
            })
            .catch(err => console.error(`Error fetching wallet data for user ${userId}:`, err));
    };

    const getWalletDaysPaidFor = (userId) => {
        const wallet = walletData[userId];
        return wallet ? wallet.DaysPaidFor : null;
    };

    const getColorCode = (userId) => {
        const daysPaidFor = getWalletDaysPaidFor(userId);
        return daysPaidFor <= -1 ? 'red' : 'green';
    };

    const handleSort = (key) => {
        setSortConfig((prevConfig) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    const sortedCustomers = [...tenantDetails]
        .filter(user =>
`${user.Name} ${user.Surname} ${user.IdNumber} ${user.Email} ${user.PhoneNumber} ${user.BuildingName || ''}`
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            const direction = sortConfig.direction === 'asc' ? 1 : -1;
            if (sortConfig.key === 'DaysPaidFor') {
                const daysA = getWalletDaysPaidFor(a.UserId) || 0;
                const daysB = getWalletDaysPaidFor(b.UserId) || 0;
                return direction * (daysA - daysB);
            }
            return direction * (a[sortConfig.key].localeCompare(b[sortConfig.key]));
        });

    useEffect(() => {
        if (!loading && sortedCustomers.length === 0) {
            const timer = setTimeout(() => setShowNoCustomersMessage(true), 3000);
            return () => clearTimeout(timer);
        } else {
            setShowNoCustomersMessage(false);
        }
    }, [loading, sortedCustomers]);
    useEffect(() => {
        console.log('Selected tenants:', selectedTenants);
    }, [selectedTenants]);
    
    return (
        <div className="banana-customers-container">
            <h2>All Registered Users</h2>
            <input
                type="text"
                placeholder="Search by name, ID number, Name, Email, Phone number, Gender,  building..." value={searchTerm} onChange={e => setSearchTerm(e.target.value)} className="banana-search-bar" />
            {/* <button 
                onClick={deleteSelectedTenants} 
                disabled={selectedTenants.length === 0} 
                className="delete-button"
            >
                Delete Selected
            </button> */}
            <div className="banana-table-container">
                {loading ? (
                    <ShimmerSimpleGallery card imageHeight={300} />
                ) : sortedCustomers.length > 0 ? (
                    <table className="banana-customer-table">
                        <thead>
                            <tr>
                            <th> <button
    onClick={deleteSelectedTenants}
    disabled={selectedTenants.length === 0}
    className="delete-button"
>
    Delete Selected
</button>
<p className="selected-count">
    Selected: <strong>{selectedTenants.length}</strong>
</p>
</th>
                                <th onClick={() => handleSort('IdNumber')}>ID Number</th>
                                <th onClick={() => handleSort('Name')}>Name</th>
                                <th >User ID</th>
                                <th onClick={() => handleSort('Email')}>Email</th>
                                <th onClick={() => handleSort('PhoneNumber')}>Phone Number</th>
                                <th onClick={() => handleSort('Gender')}>Gender</th>
                                <th onClick={() => handleSort('BuildingName')}>Building</th>
                                <th onClick={() => handleSort('DaysPaidFor')}>Days Paid For</th>
                            </tr>
                        </thead>
                        <tbody>
    {sortedCustomers.map(user => (
        <tr
            key={user.UserId}
            onClick={(e) => {
                // Ignore clicks on the checkbox
              
            }}
            className={getWalletDaysPaidFor(user.UserId) <= -1 ? 'moon-red' : 'moon-green'}
            style={{ cursor: 'pointer' }}
        >
        <td>
    <label className="custom-checkbox">
        <input
            type="checkbox"
            checked={selectedTenants.includes(user.UserId)}
            onChange={() => handleCheckboxChange(user.UserId)}
            onClick={(e) => e.stopPropagation()} // Prevent row navigation
        />
        <span className="checkbox-icon">
            {selectedTenants.includes(user.UserId) && (
                <i className="fas fa-check"></i> // Font Awesome icon or any other icon
            )}
        </span>
    </label>
</td>
            <td onClick={() => window.location.href = `/UserAccess/${user.UserId}`}>{user.IdNumber}</td>
            <td onClick={() => window.location.href = `/UserAccess/${user.UserId}`}>{user.Name} {user.Surname}</td>
            <td onClick={() => window.location.href = `/UserAccess/${user.UserId}`}>{user.UserId}</td>
            <td onClick={() => window.location.href = `/UserAccess/${user.UserId}`}>{user.Email}</td>
            <td onClick={() => window.location.href = `/UserAccess/${user.UserId}`}>{user.PhoneNumber}</td>
            <td onClick={() => window.location.href = `/UserAccess/${user.UserId}`}>{user.Gender}</td>
            <td onClick={() => window.location.href = `/UserAccess/${user.UserId}`}>{user.BuildingName || 'No Building Assigned'}</td>
            <td className={`banana-wallet-status ${getWalletDaysPaidFor(user.UserId) <= -1 ? 'moon-red' : 'moon-green'}`}>
                {getWalletDaysPaidFor(user.UserId) !== null ? getWalletDaysPaidFor(user.UserId) : 'N/A'}
            </td>
        </tr>
    ))}
</tbody>

                    </table>
                ) : (
                    showNoCustomersMessage && <p>No customers found.</p>
                )}
            </div>

            {error && <div className="banana-error">{error}</div>}
        </div>
    );
}

export default Customers;
