import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider"; // Import useAuth


const Steps7Admin = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState(""); // State to handle the title input
  const { userId } = useAuth(); // Get the user object from the auth context

  const handleNext = async () => {
    if (title.trim() === "") {
      alert("Please provide a title for the house.");
      return;
    }

    try {
      const response = await fetch("https://backendjaes.passiontrials.co.za/api/update-admin-nickname", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          AdminId: userId, // Replace with the actual AdminId
          Nickname: title,
        }),
      });

      if (response.ok) {
        console.log("Nickname updated successfully.");
        navigate("/Steps8Admin"); // Pass the title to the next page
      } else {
        const errorMessage = await response.text();
        console.error("Failed to update nickname:", errorMessage);
        alert("Failed to save the nickname. Please try again.");
      }
    } catch (error) {
      console.error("Error updating nickname:", error);
      alert("An error occurred while saving the nickname. Please try again.");
    }
  };

  return (
    <div>
      <style>{`
       .step7-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the container content horizontally */
}

.title, .description, .input-container {
  width: 70%; /* Center-align container width */
  text-align: left; /* Align text inside the container to the left */
}

.input-container {
  position: relative;
  margin: 0 auto 20px;
}

.input-container textarea {
  width: 100%; /* Keep textarea width relative to its container */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  text-align: left; /* Align text inside textarea to the left */
}


        .input-counter {
          position: absolute;
          bottom: -20px;
          right: 0;
          font-size: 12px;
          color: #999;
        }

        .progress-step1cont {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 10px;
          box-sizing: border-box;
          background: #fff;
          z-index: 1000;
        }

        .progress-bar {
          height: 4px;
          background: #e0e0e0;
          margin-bottom: 5px;
          position: relative;
        }

        .progress {
          height: 100%;
          width: 93.32%; /* Adjust based on progress */
          background: #000;
          transition: width 0.3s ease-in-out;
        }

        .buttons-step1cont {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 10px;
        }

        .back-button {
          text-decoration: underline;
          color: #000;
          background: none;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 12px;
        }

        .next-button {
          background: #000;
          max-width: 50px;
          color: #fff;
          border: none;
          padding: 5px 10px;
          border-radius: 4px;
          font-size: 12px;
          cursor: pointer;
        }
      `}</style>
      <div className="step7-container">
        <h1 className="title">Now, let's give your house a title</h1>
        <p className="description">
          Short titles work best. Have fun with it—you can always change it later.
        </p>
        <div className="input-container">
          <textarea
            rows="4"
            maxLength="20"
            placeholder="Enter your house title here"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <div className="input-counter">{title.length}/20</div>
        </div>
        <div className="progress-step1cont">
          <div className="progress-bar">
            <div className="progress"></div>
          </div>
          <div className="buttons-step1cont">
            <button className="back-button" onClick={() => navigate(-1)}>
              Back
            </button>
            <button className="next-button" onClick={handleNext}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps7Admin;
