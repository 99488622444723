import React from "react";
import { useNavigate } from "react-router-dom";
import vd from './step1.mp4';

const StepsAdmin = () => {
  const navigate = useNavigate();
  const handleNext = () => {
    navigate("/steps2Admin");
  };
  return (
    <div>
  <style>{`
    .step1cont {
      display: flex;
      align-items: center;
      max-width: 1200px;
      width: 100%;
      margin: 10px auto;
      padding: 10px;
      box-sizing: border-box;
      flex-wrap: wrap;
    }
    @media (min-width: 768px) {
      .step1cont {
        flex-wrap: nowrap;
      }
      .video-section {
        order: 2;
        width: 60%;
      }
      .text-section {
        order: 1;
        width: 40%;
      }
    }
    .text-section {
      order: 2;
      width: 100%;
      margin-bottom: 10px;
    }
    .text-section h2 {
      font-size: 14px;
      margin-bottom: 5px;
      color: #888;
      text-align: left;
    }
    .text-section h3 {
      font-size: 28px;
      margin-bottom: 10px;
      color: rgb(36, 35, 35);
      text-align: left;
    }
    .text-section p {
      font-size: 14px;
      color: #555;
      line-height: 1.4;
      text-align: left;
    }
    .video-section {
      order: 1;
      width: 100%;
      margin-bottom: 10px;
    }
    .video-section video {
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto; /* Remove fixed height */
      object-fit: cover; /* Ensure the video fits the container */
    }
    @media (max-width: 768px) {
      .video-section video {
        height: 250px; /* Adjust for smaller screens if necessary */
        z-index:400000000;
      }
    }
   .progress-step1cont {
  position: fixed; /* Fix the container at a specific position */
  bottom: 0; /* Place it at the bottom of the viewport */
  left: 0;
  right: 0;
  width: 100%; /* Make sure it spans the full width */
  max-width: 1200px;
  margin: 0 auto; /* Center it horizontally */
  padding: 0 10px;
  box-sizing: border-box;
  background: #fff; /* Add a background to prevent overlap issues */
  z-index: 1000; /* Ensure it stays above other elements */
}

    .progress-bar {
      height: 4px;
      background: #e0e0e0;
      margin-bottom: 5px;
      position: relative;
    }
    .progress {
      height: 100%;
      width: 16.66%;
      background: #000;
      transition: width 0.3s ease-in-out;
    }
    .buttons-step1cont {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 0px;
        position: relative; /* Add for flexibility */

    }
    .back-button {
      text-decoration: underline;
      color: #000;
      background: none;
      border: none;
      max-width: 50px;
      cursor: pointer;
      font-size: 12px;
      padding: 15px 0px;
    }
    .next-button {
      background: #000;
      max-width: 50px;
      color: #fff;
      border: none;
      padding: 5px 10px;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
    }
        #chaport-button {
    z-index: 1 !important;/* Set a low z-index */
}

@media (max-width: 768px) {
  .buttons-step1cont {
    justify-content: flex-start; /* Align all buttons to the left */
  }
  .next-button {
    margin-left: 150px; /* Add some spacing from the left edge */
  }
}
  `}</style>
  <div className="step1cont">
    <div className="text-section">
      <h2>Step 1</h2>
      <h3>Tell us about your place</h3>
      <p>
      Start by sharing the details of your property. Let us know if tenants will rent the entire house or just a room, its location, and how many tenants it can accommodate. Simple and straightforward!
      </p>
    </div>
    <div className="video-section">
      <video autoPlay muted playsInline>
        <source src={vd} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
  <div className="progress-step1cont">
    <div className="progress-bar">
      <div className="progress"></div>
    </div>
    <div className="buttons-step1cont">
      <a href="#" className="back-button">
        Back
      </a>
      <button className="next-button" onClick={handleNext}>Next</button>
    </div>
  </div>
    </div>
  );
};

export default StepsAdmin;
