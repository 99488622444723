import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Swal from 'sweetalert2';
import img from "./icons8-photo.gif";
import { useAuth } from './AuthProvider';


const Steps6Admin = () => {
  const [photos, setPhotos] = useState({ interior: null, exterior: null });
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate
  const { userId } = useAuth();

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    if (files.length > 2) {
      alert("You can only upload two photos: one for Interior and one for Exterior.");
      return;
    }

    const updatedPhotos = { ...photos };
    files.forEach((file) => {
      if (!updatedPhotos.interior) {
        updatedPhotos.interior = file;
      } else if (!updatedPhotos.exterior) {
        updatedPhotos.exterior = file;
      }
    });

    setPhotos(updatedPhotos);
  };

  const handleSubmit = async () => {
    if (!photos.interior || !photos.exterior) {
      alert("Please upload both Interior and Exterior photos.");
      return;
    }

    setIsUploading(true);

    const formData = new FormData();
    formData.append("AdminId", userId); // Use dynamic AdminId
    formData.append("InteriorImage", photos.interior);
    formData.append("ExteriorImage", photos.exterior);

    try {
      const response = await fetch("https://backendjaes.passiontrials.co.za/api/update-admin-image", {
        method: "POST",
        body: formData,
      });

      const result = await response.text();
    
      if (response.ok) {
        // Navigate to the next step if upload is successful
        navigate("/steps7Admin");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      alert("Failed to upload images.");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="step1cont">
    <style>{`
      .step1cont {
        font-family: Arial, sans-serif;
        text-align: center;
        max-width: 600px;
        margin: 0 auto;
        padding: 30px;
      }
  
      h3 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
      }
  
      p {
        font-size: 14px;
        color: #555;
        margin-bottom: 20px;
      }
  
      .upload-box {
        border: 2px dashed #ccc;
        border-radius: 8px;
        padding: 20px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 300px;
      }
  
      .upload-box img {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
      }
  
      .add-photo-btn {
        background-color: #000;
        margin-top: 20px;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 4px;
        cursor: pointer;
      }
  
      .add-photo-btn:hover {
        background-color: #333;
      }
  
      .uploaded-photos {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
      }
  
      .uploaded-photos div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
  
      .photo-label {
        font-size: 14px;
        color: #333;
        margin-bottom: 5px;
      }
  
      .uploaded-photos img {
        width: 150px;
        height: 100px;
        object-fit: cover;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
  
      .upload-success-message {
        margin-top: 20px;
        font-size: 16px;
        color: green;
      }
    `}</style>
  
    <h3>Add photos of your house</h3>
    <p>You’ll need to upload 2 photos: one for the interior and one for the exterior.</p>
  
    <div className="upload-box">
      <img src={img} alt="Camera Icon" />
      <input
        type="file"
        multiple
        accept="image/*"
        style={{ display: "none" }}
        id="fileInput"
        onChange={handleFileChange}
      />
      <button
        className="add-photo-btn"
        onClick={() => document.getElementById("fileInput").click()}
      >
        Add photos
      </button>
      <div className="uploaded-photos">
        {photos.interior && (
          <div>
            {/* <p className="photo-label">Interior Photo</p> */}
            <img src={URL.createObjectURL(photos.interior)} alt="Interior Preview" />
          </div>
        )}
        {photos.exterior && (
          <div>
            {/* <p className="photo-label">Exterior Photo</p> */}
            <img src={URL.createObjectURL(photos.exterior)} alt="Exterior Preview" />
          </div>
        )}
      </div>
    </div>
  
    <button
      className="add-photo-btn"
      onClick={handleSubmit}
      disabled={!photos.interior || !photos.exterior || isUploading}
    >
      {isUploading ? "Uploading..." : "Submit"}
    </button>
  
    {/* Back button */}
    <button
      className="add-photo-btn"
      style={{ backgroundColor: "#ddd", color: "#000", marginTop: "10px" }}
      onClick={() => navigate(-1)}
    >
      Back
    </button>
  </div>
  
  );
};

export default Steps6Admin;
