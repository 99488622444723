import React, { useRef, useState, useEffect } from 'react';
import { Route, Routes, Link, Outlet, useNavigate } from 'react-router-dom';
import MyApartment from './myApartment';
import { useAuth } from './AuthProvider';
import Countdown from "react-countdown";
//import { set } from 'react-datepicker/dist/date_utils';
import './modal.css';
import './userdashboard.css';
//import Loader2 from './refresh';




// Dummy data for demonstration

// Placeholder components for the new routes
function Favorites() {
    return <div><h2>My Favorites</h2><p>Favorite listings and saved properties will be shown here.</p></div>;
}

function Profile() {
    return <div><h2>My Profile</h2><p>Profile settings and personal information can be updated here.</p></div>;
}

function CurrentApartment() {
    return <div><h2>Current Apartment</h2><p>Details about your current apartment will be displayed here.</p></div>;
}

function useOutsideAlerter(ref, onOutsideClick) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onOutsideClick]);
}


// const PaymentModal = ({ isVisible, onClose, onSubmit }) => {
//     const [customAmount, setCustomAmount] = useState('');
//     const [selectedAmount, setSelectedAmount] = useState(null);
//     const [isCustom, setIsCustom] = useState(false);

//     const handleAmountChange = (e) => {
//         setCustomAmount(e.target.value);
//         // setSelectedAmount('');  // Clear predefined amounts if custom is entered
//     };



//     const handleSubmit = () => {
//         const amount = isCustom ? customAmount : selectedAmount;
//         if (!amount) {
//             alert('Please select or enter an amount.');
//             return;
//         }
//         onSubmit(amount); // Pass the amount to the parent component
//     };

//     const handleAmountSelect = (amount) => {
//         setSelectedAmount(amount);
//         setIsCustom(false); // Deselect custom option if a preset amount is chosen
//     };

//     const handleCustomSelect = () => {
//         setIsCustom(true); // Show custom input when "Custom" is selected
//         setSelectedAmount(null); // Clear preset selection
//     };

//     if (!isVisible) return null;

//     return (
//         <div className="modal-overlay">
//             <div className="modal-content">
//                 <span className="close" onClick={onClose}>&times;</span>
//                 <h2>Select or Enter Payment Amount</h2>
//                 <div className="amount-options">
//                     <button onClick={() => handleAmountSelect(500)}>R500</button>
//                     <button onClick={() => handleAmountSelect(1000)}>R1000</button>
//                     <button onClick={() => handleAmountSelect(1500)}>R1500</button>
//                     <button onClick={() => handleAmountSelect(2000)}>R2000</button>
//                     <button onClick={handleCustomSelect}>Custom</button>
//                 </div>
//                 {isCustom && (
//                     <div className="custom-amount">
//                         <input
//                             type="number"
//                             placeholder="Enter amount"
//                             value={customAmount}
//                             onChange={handleAmountChange}
//                         />
//                     </div>
//                 )}
//                 <button onClick={handleSubmit} className="submit-btn">Submit</button>
//             </div>
//         </div>
//     );
// };

const Loader = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh' // This ensures it takes the full viewport height
        }}>
            <div className="🤚">
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="🌴"></div>
                <div className="👍"></div>
            </div>
        </div>
    );
};

// const CheckoutModal = ({ isVisible, onClose, onConfirm }) => {
//     if (!isVisible) return null;

//     return (
//         <div className="modal-overlay">
//             <div className="modal-content">
//                 <h2>Confirm Checkout</h2>
//                 <p>Are you sure you want to checkout?</p>
//                 <div className="modal-buttons">
//                     <button onClick={onConfirm} className="confirm-btn">Confirm</button>
//                     <button onClick={onClose} className="cancel-btn">Cancel</button>
//                 </div>
//             </div>
//         </div>
//     );
// };

function NewAdminDashboard() {
    // const [bookings, setBookings] = useState([]);
    // const [payments, setPayments] = useState([]);
    // const [profile, setProfile] = useState({});
    // const [showDropdown, setShowDropdown] = useState(false);
    // const [fetchid, setFetchId] = useState(null);
    // const { userId } = useAuth();
    // const [error, setError] = useState('');
    // const [apartment, setApartment] = useState(null);
    // const [creditAmount, setCreditAmount] = useState(null);
    // const [showModal, setShowModal] = useState(false);
    // const [enteredAmount, setEnteredAmount] = useState(null);
    // const navigate = useNavigate();
    // const [latestInfo, setLatestInfo] = useState(null);
    // const [latestPaymentTrack, setLatestPaymentTrack] = useState(null);
    // const [showCheckoutModal, setShowCheckoutModal] = useState(false);
    // const [loading, setLoading] = useState(false);
    // let theFirst = null;
    // let showButtons = null;
    // let testCount = null;
    // let informedMessage = null;
    // let tc = 0;
    // let systemInitiated = null;
    // const systemProcessCallRef = useRef(false);
    // let stopper = 0;
    const navigate = useNavigate();
    const [bookings, setBookings] = useState([]);
    const [admin, setAdmin] = useState(null);
    const [payments, setPayments] = useState([]);
    const [nonPaid, setNonPaid] = useState([]);
    const [monthPaid, setMonthPaid] = useState([]);

    const { userId, role } = useAuth();


    const [apartments, setApartments] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [error, setError] = useState('');
    const [Profile, setProfile] = useState({});
    const [showDropdown, setShowDropdown] = useState(false);
    const wrapperRef = useRef(null);

    ///// this controls the buttons that are shown
    // theFirst = latestInfo?.PayDate;

    // theFirst = new Date(theFirst).toLocaleDateString('en-US', { day: 'numeric' });
    //  showButtons = theFirst >= 15 && theFirst <= 31;
    /////

    useOutsideAlerter(wrapperRef, () => setShowDropdown(false));

    // const nextPaymentDate = new Date(new Date().setDate(new Date().getDate() + 30)); // Assuming 30 days till next payment
    // const currentDate = new Date();
    // const daysLeft = Math.ceil((nextPaymentDate - currentDate) / (1000 * 60 * 60 * 24));
    //eeei
    useEffect(() => {
        setBookings('');

        setApartments();



        if (userId) {

            fetch(`https://backendjaes.passiontrials.co.za/user_tb`, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(data => {

                    if (data) {
                        console.log("Pre insertion", data, `this is the userId ${userId}`);

                        setCustomers(data);
                    }
                    else {
                        console.log('Cannot find users');

                    }
                })
                .
                catch(error => {
                    console.error('Error', error);
                    setError(error.message);
                });
        }



        setProfile('');
    }, [userId]);


    useEffect(() => {
        if (userId) {


            fetch(`https://backendjaes.passiontrials.co.za/admin_tb/${userId}`, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        console.log("Pre insertion", data, `this is the userId ${userId}`);
                        setAdmin(data);
                        console.log("Post insertion", admin);
                    }
                    else {
                        console.log('No admin data found');
                    }
                })
                .catch(error => {
                    console.error('Error', error);
                    setError(error.message);
                });


        }
    }, [userId]);

   

   

    

    const nextPayDate = 0;
    console.log(`non converted :${nextPayDate}`);
    const nextPayDay = new Date(nextPayDate).toLocaleDateString('en-US', { day: "numeric" });
    const converted = nextPayDate ? new Date(nextPayDate) : null;
    console.log(`converted :${converted}`);

    //    nextPaymentDate.setDate(new Date(nextPaymentDate.getFullYear(), nextPaymentDate.getMonth() + 1, 0).getDate());

    //converted.setDate(new Date(converted.getFullYear(), converted.getMonth() + 1, 0).getDate());
    const dateNow = new Date();
    const daysOfStay = converted ? Math.ceil((converted - dateNow) / (1000 * 60 * 60 * 24)) : null;
    console.log('this is the converted date', converted, "this is the current date", dateNow);


    // if (!apartment) {
    //     return <div>Loading</div>
    // }
    // const systemProcess = async () => {   //intricate function i will come back to ,

    //     // if (systemInitiated) {

    //     let newDate = latestInfo.PayNextDate;

    //     let converted = new Date(newDate);

    //     converted.setMonth(converted.getMonth() + 1, 1);
    //     let formatedDate = converted.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });


    //     const systemRecord = await fetch('https://backendjaes.passiontrials.co.za/payment_tb', {
    //         method: 'POST',
    //         headers: { 'Content-Type': "application/json" },
    //         body: JSON.stringify({
    //             UserId: latestInfo.UserId,
    //             ApartmentId: latestInfo.ApartmentId,
    //             PayDate: latestInfo.PayNextDate,
    //             PayNextDate: formatedDate,
    //             PayAmount: latestInfo.PayableRent,
    //             PayNote: 'System Initiated',
    //             PaymentStatus: 'OVERDUE',
    //             PaymentType: "Rent",
    //             PayAdvancePeriod: '15',
    //             PayGracePeriod: '3',
    //             PayMethod: "No Payment Attempt",
    //             Paid: true,
    //             PaidInAdvance: false,
    //             PaidInAdvanceDate: "N/A",
    //             IsGracePeriod: true,
    //             ChosenPlan: latestInfo.ChosenPlan,
    //             ServiceFee: latestInfo.ServiceFee,
    //             PayableRent: latestInfo.PayableRent,
    //             RentCost: latestInfo.RentCost

    //         })
    //     });

    //     // }

    // };


    // if (latestInfo) {
    //     theFirst = new Date(latestInfo.PayDate).toLocaleDateString('en-US', { day: 'numeric' });
    //     const currentD = new Date(dateNow).toLocaleDateString('en-US', { day: 'numeric' });
    //     showButtons = theFirst >= 1 && theFirst <= 31 || currentD >= 1 && currentD <= 31;
    //     //showButtons = true; for testing
    //     testCount = latestInfo?.PayNote == 'Checkout Month';
    //     tc = latestInfo?.PayNote == 'Monthly rent'; //has no html code to go with yet

    //     let convertedDate = new Date(latestInfo.PayNextDate);
    //     let currentDate = new Date();

    //     systemInitiated = convertedDate < currentDate;
    //     // if (daysOfStay < 0) {


    //     //     console.log('the condition is true');
    //     //     if (latestInfo?.PayNote == 'System Initiated') {
    //     //         systemProcess();
    //     //         console.log('the record of the system is recorded');
    //     //     }
    //     //     // 
    //     // }

    // }

    // useEffect(() => {
    //     if (latestInfo) {
    //         let convertedDate = new Date(latestInfo.PayNextDate);
    //         let currentDate = new Date();

    //         systemInitiated = convertedDate < currentDate;

    //         if (systemInitiated && daysOfStay < 0) {
    //             console.log('outside the function');
    //             if (latestInfo.PayNote == 'Monthly rent') {
    //                 systemProcess();
    //                 console.log('the function is called');
    //             }
    //         }

    //     }
    // }, [latestInfo]);

    // // if (daysOfStay ==)
    // informedMessage = latestInfo?.PaymentStatus == 'OVERDUE';


    let testCount = 0;

    const backToDash = () => {
        navigate('/NewAdminDashboard');
    }

    const handleProperty = () => {
        navigate('/Properties');
    };

    const handleRooms = () => {

        navigate('/ReserveCustomers');

    };









    // if (loading) {
    //     return <Loader />; // Show only the loader while loading is true
    // }
    const goToTransport = () => {
        if (Loader) {
            navigate(`/transport-admin`);
        }
    };
    const goToTransportPayment = () => {
        if (Loader) {
            navigate(`/TransportAdminPayment`);
        }
    };
    const goToReserve = () => {
        if (Loader) {
            navigate(`/ReserveCustomers`);
        }
    };
    const goTotenants = () => {
        if (Loader) {
            navigate(`/customers`);
        }
    };

    return (
        <div >

            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', padding: '20px', }}>

                {/* <div ref={wrapperRef} onClick={toggleDropdown} style={{ display: 'flex', alignItems: "center", cursor: "pointer" }}>
                    <img style={{ width: "50px", height: "50px", borderRadius: '50%', marginRight: "10px", objectFit: 'cover' }} src={`http://http://backendjaes.passiontrials.co.za/${apartment.FaceImage}` || "https://picsum.photos/200/300?random=30"} alt='User' className="profile-image" />
                    <span>{apartment.Name} {apartment.Surname}</span>


                    {showDropdown && <DropdownMenu />}
                </div> */}


            </div>
            <nav>
                {/* <Link to={`MyApartment/${userId}/${fetchid}`}>My Apartment</Link> */}
                {/* <Link to="/UserPayment">Payments</Link> |
                <Link to="/favorites">Favorites</Link> |
                <Link to="/profile">Profile</Link> */}
                {/* <Link to="/current-apartment">Current Apartment</Link> */}
                {/* <button onClick={goToTransport}>Go to Transport</button> */}
            </nav>
            <Routes>
                <Route path="/bookings" element={<BookingList bookings={bookings} />} />
                <Route path="/payments" element={<PaymentList payments={payments} />} />
                <Route path="/favorites" element={<Favorites />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/current-apartment" element={<CurrentApartment />} />
            </Routes>
            <Outlet />
            {/* <h2 style={{ top: '10px', marginRight: '758px', marginLeft: '0px' }}>Hello {apartment.Name}</h2> */}

            <body class="dashboard-page">
                <h1 class="welcome-message">Hello, <span style={{ color: '#1F509A' }}>{admin?.AdminName} {admin?.AdminSurname}</span> </h1>

                <div class="dashboard">

                    <div class="card first-card">

                        <img style={{ zIndex: '0' }} src="https://backendjaes.passiontrials.co.za/src/Building_images/IMG_20200725_132724.jpg" alt="Account Image" class="card-bg-img" />
                        <h2 style={{ color: 'white' }}> {testCount ? `Overdue Tanents: ${daysOfStay} days` : `Overdue Tanents: 8 Tenants`}
                        </h2>
                        {/* <h2 style={{ color: 'white' }}>  {systemInitiated ?
                            "its overdue" : ""
                        }</h2> */}

                        <small style={{ color: 'white' }}>JAES Properties </small>
                        <div class="card-buttons">
                            <button style={{ backgroundColor: '#1F509A' }} class="button-primary" onClick={() => handleProperty()}>Properties</button>
                            <button class="button-secondary" onClick={() => handleRooms()}>Reservations</button>
                        </div>
                    </div>
                    <div class="transport-group card"  style={{ backgroundColor: '#1F509A' }}>
                    <img style={{ zIndex: '0' }} src="/transport--new.jpg" alt="Account Image" class="card-bg-img" />
    <div 
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',  // Adjust opacity as needed
            zIndex: '1',
        }}
        className="dimming-overlay"
    ></div>
        <div class="card" onClick={goToTransport}  style={{ backgroundColor: 'transparent', border: '2px solid #ffffff' }}>
            <h2 style={{color: "#fff"}}>Transport schedules</h2>
            <small style={{color: "#ffffff"}}>View the latest transport schedule</small>
        </div>
        <div class="card" onClick={goToTransportPayment}  style={{ backgroundColor: 'transparent', border: '2px solid #ffffff'  }}>
            <h2 style={{color: "#ffffff"}}>Transport Students</h2>
            <small style={{color: "#ffffff"}}>Link Student with Transport</small>
        </div>
          </div>
                    <Link to="/UserPayment">
                        <div style={{ backgroundColor: '#1F509A' }} class="card">
                        <img style={{ zIndex: '0' }} src="Online-shopping.jpg" alt="Account Image" class="card-bg-img" />
    <div 
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',  // Adjust opacity as needed
            zIndex: '1',
        }}
        className="dimming-overlay"
    ></div>
                            <h2 style={{ color: '#ffffff' }}>Payment</h2>
                            <small style={{ color: '#ffffff' }}>See tenant transcations</small>
                        </div>
                    </Link>
<div class="transport-group card"  style={{ backgroundColor: '#1F509A' }}>
                    <img style={{ zIndex: '0' }} src="blogjan.webp" alt="Account Image" class="card-bg-img" />
    <div 
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',  // Adjust opacity as needed
            zIndex: '1',
        }}
        className="dimming-overlay"
    ></div>
        <div class="card" onClick={goTotenants}  style={{ backgroundColor: 'transparent', border: '2px solid #ffffff' }}>
            <h2 style={{color: "#fff"}}>Tenants</h2>
            <small style={{color: "#ffffff"}}>take a look at your tenants details</small>
        </div>
        <div class="card" onClick={goToReserve}  style={{ backgroundColor: 'transparent', border: '2px solid #ffffff'  }}>
            <h2 style={{color: "#ffffff"}}>Reserves</h2>
            <small style={{color: "#ffffff"}}>take a look at your Reserved</small>
        </div>
          </div>

                    <Link to="/ServiceBoxAdmin">
                    <div style={{ backgroundColor: '#1F509A' }} class="card">
                    <img style={{ zIndex: '0' }} src="https://starfieldconsulting.nl/wp-content/uploads/elementor/thumbs/pexels-yan-krukov-8867474-scaled-peek3pzknc3hl3712gn5ohxfq1oah5jmyp50swwhiw.jpg" alt="Account Image" class="card-bg-img" />
    <div 
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',  // Adjust opacity as needed
            zIndex: '1',
        }}
        className="dimming-overlay"
    ></div>
                        <h2 style={{ color: '#ffffff' }}>Service Box</h2>
                        <small style={{ color: '#ffffff' }}>View Tenants Requests </small>
                    </div>
                    </Link>
                </div>
            </body>
            {/* <div style={{ padding: '20px', backgroundImage: `url(${apartment.ApartmentRoomImage})`, backgroundSize: 'cover' }}>

                <div style={{ display: 'flex', justContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <h2></h2>

                        <p>
                        </p>

                        {testCount && (
                            <>
                                <p style={{ color: 'Red' }}>Final Month Of Stay</p>
                            </>
                        )}
                        {informedMessage && (
                            <>
                                <p style={{ color: 'Yellow' }}> This months stay is on credit,and will be added to your next payment.  </p>
                            </>
                        )}
                    </div>
                    <div>
                        {showButtons && (
                            <>
                                <button onClick={goToPayment} >Renew</button>
                            </>
                        )}
                        <button onClick={checkOut}>Checkout</button>

                    </div>
                    <p>
                        {systemInitiated ?
                            "its overdue" : "there is nothing here"
                        }
                    </p>
                </div>
            </div> */}
        </div >
    );
}

function BookingList({ bookings }) {
    return (
        <div>
            <h2>My Bookings</h2>
            {bookings.map(booking => (
                <div key={booking.id}>
                    <p>{booking.location} - {booking.date}</p>
                </div>
            ))}
        </div>
    );
}

function PaymentList({ payments }) {
    return (
        <div>
            <h2>My Payments</h2>
            {payments.map(payment => (
                <div key={payment.id}>
                    <p>Amount: {payment.amount} - Status: {payment.status} - Date: {payment.date}</p>
                </div>
            ))}
        </div>
    );
}

function DropdownMenu() {
    return (
        <div style={{
            position: 'absolute',
            right: 4,
            top: "170px",
            backgroundColor: "#ffffff",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            borderRadius: "8px",
            width: "200px",
            zIndex: 1000

        }}>

            <Link style={{ display: "block", padding: "10px", textDecoration: "none", color: "#333" }} to="/profile">My Profile</Link>
            <Link style={{ display: "block", padding: "10px", textDecoration: "none", color: "#333" }} to="/favorite">Favorites</Link>
            <Link style={{ display: "block", padding: "10px", textDecoration: "none", color: "#333" }} to="/setting">Settings</Link>
            <Link style={{ display: "block", padding: "10px", textDecoration: "none", color: "#333" }} to="/">Logout</Link>



        </div>
    );
}
export default NewAdminDashboard;
