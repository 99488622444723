import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from './AuthProvider';


function RegisterAdmin() {
  const location = useLocation();
  const navigate = useNavigate();

  const { login } = useAuth();
  const { packageName } = location.state || { packageName: "Unknown" };

    const [formData, setFormData] = useState({
        AdminUsername: '',
        AdminPassword: '',
        ConfirmPassword: '',
        AdminName: '',
        AdminSurname: '',
        AdminEmail: '',
        AdminCell: '',
        Package: ''
    });
    const [loading, setLoading] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState("");

    const [currentStep, setCurrentStep] = useState(1); // Step tracking


    const packageMessages = {
      Basic: "Basic Package: Includes only Sales. Tenants are exluded from transport.",
      Standard:
        "Standard Package: Includes Sales and Transport. Transport is provided to your tenants.",
      Premium:
        "Premium Package: Includes Sales, Transport, and System. everything is Standard and include receiving email for Service",
      Enterprise:
        "Enterprise Package: Includes Sales, Transport, System, and Management. Properties will be displayed as managed by the company, while other packages will show 'Managed by [Property Name]'.",
    };
    

  // Set the selected package from the URL state
  useEffect(() => {
    setFormData((prevFormData) => ({ ...prevFormData, Package: packageName }));
  }, [packageName]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
         if (name === "Package") {
      setSelectedMessage(packageMessages[value]);
    }
    };
    const handleNext = (e) => {
        e.preventDefault();
        setCurrentStep(2); // Move to step 2
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (formData.AdminPassword !== formData.ConfirmPassword) {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Passwords do not match!",
                showConfirmButton: false,
                timer: 5500
            });
            setLoading(false);
            return;
        }

        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/register_admin', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
            });

            const data = await response.json();
            Swal.fire({
                position: "top-end",
                icon: data.error ? "error" : "success",
                title: data.message,
                showConfirmButton: false,
                timer: 5500
            });

            if (!data.error) {
                setFormData({
                    AdminUsername: '',
                    AdminPassword: '',
                    ConfirmPassword: '',
                    AdminName: '',
                    AdminSurname: '',
                    AdminEmail: '',
                    AdminCell: '',
                    Package: ''
                });
                setCurrentStep(1); // Reset to step 1 after successful registration
                setSelectedMessage("");
                await normalLogin(formData.AdminEmail, formData.AdminPassword);

            }
        } catch (error) {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Something went wrong!",
                showConfirmButton: false,
                timer: 5500
            });
            console.error("Error during registration:", error);
        } finally {
            setLoading(false);
        }
    };
    const normalLogin = async (email, password) => {
      try {
          const loginResponse = await fetch('https://backendjaes.passiontrials.co.za/new_loginn', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ Email: email, Password: password }),
          });
  
          const loginData = await loginResponse.json();
  
          if (loginResponse.ok) {
            login(loginData.userId, loginData.Role);

            // Navigate to `buildingRegister` after successful login
            navigate('/StepsAdmin', { state: { userId: loginData.userId, role: loginData.Role, email } });
        } else {
            //alert(loginData.message || 'Failed to login');
             Swal.fire({
        position: "top-end",
        icon: "error",
        title: loginData.message || 'Failed to login',
        showConfirmButton: false,
        timer: 5500,
      });
        }
      } catch (error) {
          console.error('Login error:', error);
          alert('An error occurred during login');
      }
  };
  
    return (
        <div>     
    <div class="container">
    <form onSubmit={handleSubmit} >
        <div class="form-card">
          <h1> sign up </h1>
          <div class="line"></div> 
        <p>You have selected the <strong>{packageName} </strong>package.</p>
          <div  class="step active">
            <div className="input-row">
                        <input
                            name="AdminName"
                            value={formData.AdminName}
                            onChange={handleChange}
                            placeholder="First Name"
                            className="zhongxin-input"
                            required
                        /> 
                        <input
                            name="AdminSurname"
                            value={formData.AdminSurname}
                            onChange={handleChange}
                            placeholder="Last Name"
                            className="zhongxin-input"
                            required
                        />
                    </div>   
                        <input
                            name="AdminEmail"
                            value={formData.AdminEmail}
                            onChange={handleChange}
                            placeholder="Email"
                            type="email"
                            className="zhongxin-input"
                            required
                        />
                             <input type="tel" id="phone-number" name="AdminCell" required  value={formData.AdminCell}
                            onChange={handleChange} placeholder="Phone number"/>
                  <div className="input-row">
                        <input
                            name="AdminPassword"
                            value={formData.AdminPassword}
                            onChange={handleChange}
                            placeholder="Password"
                            type="password"
                            className="zhongxin-input"
                            required
                        />
                        <input
                            name="ConfirmPassword"
                            value={formData.ConfirmPassword}
                            onChange={handleChange}
                            placeholder="Confirm Password"
                            type="password"
                            className="zhongxin-input"
                            required
                        />
                    </div>
                    <button type="submit" className="btn-primary">Register</button>
          </div>
           <p class="text-muted">We'll call or text you to confirm your number. Standard message and data rates apply. <a href="#">Privacy Policy</a></p>
        </div>
        </form>
      </div>
      <style>
        {`
        .radio-group {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          justify-content: space-around;
        }

        .radio-option {
          position: relative;
          padding: 15px 20px;
          border: 2px solid #ddd;
          border-radius: 8px;
          cursor: pointer;
          text-align: center;
          flex: 1 1 calc(45% - 10px);
          background-color: #fff;
          transition: all 0.3s ease;
        }

        .radio-option.selected {
          border-color: #ff385c;
          background-color: #ffe6ea;
        }

        .radio-option input[type="radio"] {
          display: none;
        }

        .radio-message.active {
          font-size: 14px;
          color: #333;
          text-align: center;
          font-weight: bold;
          margin-top: 15px;
        }
        `}
      </style>
      <style>
        {`
        /* From Uiverse.io by andrew-demchenk0 */ 
.wrapper {
  display: flex;
  justify-content: center; /* Centers items horizontally */
  align-items: center; /* Centers items vertically */
  gap: 20px; /* Adds spacing between cards */
}

.card {
  position: relative;
  width: 150px;
  height: 100px;
  background: #fff;
  border-radius: 10px;
  transition: all 0.3s;
  margin: 10px;
}

.card:hover {
  transform: scale(1.05);
}

.input {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  appearance: none;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  z-index: 10;
  box-shadow: 1px 1px 10px #aaaaaa,
              -1px -1px 10px #ffffff;
}

.input + .check::before {
  content: "";
  position: absolute;
  top: 15px;
  right: 15px;
  width: 16px;
  height: 16px;
  border: 2px solid #d0d0d0;
  border-radius: 50%;
  background-color: #E8E8E8;
}

.input:checked + .check::after {
  content: '';
  position: absolute;
  top: 19px;
  right: 19px;
  width: 12px;
  height: 12px;
  background-color: rgba(255,0,0,0.7);
  border-radius: 50%;
}

.input[value="standart"]:checked + .check::after {
  background-color: rgba(255,165,0,0.7);
}

.input[value="premium"]:checked + .check::after {
  background-color: rgba(0,128,0,0.7);
}

.input[value="basic"]:checked {
  border: 1.5px solid rgba(255,0,0,0.7);
}

.input[value="standart"]:checked {
  border: 1.5px solid rgba(255,165,0,0.7);
}

.input[value="premium"]:checked {
  border: 1.5px solid rgba(0,128,0,0.7);
}

.label {
  color: #323232;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.label .title {
  margin: 10px 0 0 15px;
  font-weight: 900;
  font-size: 15px;
}

.label .price {
  margin: 10px 1px 1px 15px;
  font-size: 20px;
  font-weight: 900;
}

.label .span {
  color: gray;
  font-weight: 700;
  font-size: 15px;
}
`}
      </style>
      <style>
        {`



.container {
    border: 1px solid #ddd; /* Light gray border */
  border-radius: 10px;    /* Optional for rounded corners */
 
  width: 100%;
  max-width: 500px;
  padding: 20px;
}

.form-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

h1 {
  font-size: 14px;
  margin-bottom: 20px;
}

label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  text-align: left;
}

select,
input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px
}

input[type="email"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 10px
  }
input[type="tel"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px
}

.input-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px
}

.input-row input[type="text"] {
  width: calc(50% - 5px); /* Ensures two inputs fit in one row with a gap */
}


.btn-primary {
  width: 100%;
  padding: 10px;
  background-color: #ff385c;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #e73352;
}

.text-muted {
  font-size: 12px;
  color: #666;
  margin: 10px 0;
}

.text-muted a {
  color: #ff385c;
  text-decoration: none;
}

.divider {
  margin: 20px 0;
  font-size: 14px;
  color: #999;
  position: relative;
}

.divider::before,
.divider::after {
  content: '';
  height: 1px;
  background-color: #ddd;
  position: absolute;
  top: 50%;
  width: 40%;
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}

.email {
  background-color: #fff;
  color: #333;
}

.line {
  height: 1px;
  background-color: #ddd;
  margin: 10px 0 20px;
  width: 100%;
}

.radio-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px; /* Space between buttons */
}

.radio-option {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
  flex: 1 1 calc(50% - 10px);
  box-sizing: border-box;
}

.radio-option input[type="radio"] {
  display: none; /* Hide the default radio button */
}
/* Ensure the input is visually styled when selected */
.input:checked + .check {
  border: 2px solid #ff385c; /* Add a border for the selected state */
  background-color: #ffe6ea; /* Light background for selected state */
}

.input:checked + .check::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: #ff385c; /* Inner circle color */
  border-radius: 50%;
}

.radio-option input[type="radio"]:checked + span {
  border: 2px solid #ff385c; /* Changes the border color when selected */
  color: #ff385c; /* Changes text color */
  background-color: #ffe6ea; /* Optional: Light background for selected button */
  padding: 10px 20px;
}

.radio-option:hover {
  border-color: #bbb; /* Slightly darker border on hover */
}

.radio-option input[type="radio"]:checked + span {
  border-color: #ff385c; /* Ensures the border changes color when selected */
}

.radio-message {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
  text-align: center;
  font-weight: bold;
  display: none; /* Hidden by default */
}

.radio-message.active {
  display: block; /* Show only when active */
}


`}
      </style>
    </div>
    );
}

export default RegisterAdmin;
