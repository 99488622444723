import React, { useRef, useState, useEffect } from 'react';
import { Route, Routes, Link, Outlet, useNavigate } from 'react-router-dom';
import MyApartment from './myApartment';
import { useAuth } from './AuthProvider';
import Countdown from "react-countdown";
import './modal.css';
import './userdashboard.css';

function useOutsideAlerter(ref, onOutsideClick) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onOutsideClick]);
}
const Loader = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh' // This ensures it takes the full viewport height
        }}>
            <div className="🤚">
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="🌴"></div>
                <div className="👍"></div>
            </div>
        </div>
    );
};
function RegularAdmin() {
    const navigate = useNavigate();
    const [bookings, setBookings] = useState([]);
    const [admin, setAdmin] = useState(null);
    const [payments, setPayments] = useState([]);
    const [nonPaid, setNonPaid] = useState([]);
    const [monthPaid, setMonthPaid] = useState([]);
    const { userId, role } = useAuth();
    const [apartments, setApartments] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [error, setError] = useState('');
    const [Profile, setProfile] = useState({});
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedGuestFavorites, setSelectedGuestFavorites] = useState([]);
    const [selectedAdditionalAmenities, setSelectedAdditionalAmenities] = useState([]);
    const [selectedSafetyItems, setSelectedSafetyItems] = useState([]);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setShowDropdown(false));
    useEffect(() => {
        setBookings('');
        setApartments();
        if (userId) {
            fetch(`https://backendjaes.passiontrials.co.za/user_tb`, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        console.log("Pre insertion", data, `this is the userId ${userId}`);
                        setCustomers(data);
                    }
                    else {
                        console.log('Cannot find users');
                    }
                })
                .
                catch(error => {
                    console.error('Error', error);
                    setError(error.message);
                });
        }
        setProfile('');
    }, [userId]);
    useEffect(() => {
        if (userId) {
            fetch(`https://backendjaes.passiontrials.co.za/admin_tb/${userId}`, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        console.log("Pre insertion", data, `this is the userId ${userId}`);
                        setAdmin(data);
                        console.log("Post insertion", admin);
                    }
                    else {
                        console.log('No admin data found');
                    }
                })
                .catch(error => {
                    console.error('Error', error);
                    setError(error.message);
                });

        }
    }, [userId]);
    useEffect(() => {
      if (userId) {
          fetch(`https://backendjaes.passiontrials.co.za/api/get-admin-amenities?AdminId=${userId}`)
              .then((response) => response.json())
              .then((data) => {
                  setSelectedGuestFavorites(JSON.parse(data.GuestFavorites || "[]"));
                  setSelectedAdditionalAmenities(JSON.parse(data.AdditionalAmenities || "[]"));
                  setSelectedSafetyItems(JSON.parse(data.SafetyItems || "[]"));
              })
              .catch((error) => console.error("Error fetching data:", error));
      }
  }, [userId]);
  

    
   
    const nextPayDate = 0;
    console.log(`non converted :${nextPayDate}`);
    const nextPayDay = new Date(nextPayDate).toLocaleDateString('en-US', { day: "numeric" });
    const converted = nextPayDate ? new Date(nextPayDate) : null;
    console.log(`converted :${converted}`);
     const dateNow = new Date();
    const daysOfStay = converted ? Math.ceil((converted - dateNow) / (1000 * 60 * 60 * 24)) : null;
    console.log('this is the converted date', converted, "this is the current date", dateNow);

    let testCount = 0;
    const backToDash = () => {
        navigate('/RegularAdmin');
    }
    const handleProperty = () => {
        navigate('/LandLordProperties');
    };
    const goToTransport = () => {
        if (Loader) {
            navigate(`/transport-admin`);
        }
    };
    return (
        <div >
               {admin && admin.Status === 'Approved' ? (
            <body class="dashboard-page">    
                  <h1 class="welcome-message">Hello, <span style={{ color: 'red' }}>{admin?.AdminName} {admin?.AdminSurname}</span> </h1>

                <div class="dashboard">
          
                    <div class="card first-card">
                        <img style={{ zIndex: '0' }} src="https://backendjaes.passiontrials.co.za/src/Building_images/IMG_20200725_132724.jpg" alt="Account Image" class="card-bg-img" />
                        <h2 style={{ color: 'white' }}> {testCount ? `My Properties: ${daysOfStay} days` : `My properties: ${daysOfStay} days`}
                        </h2>
                        <small style={{ color: 'white' }}>{admin?.AdminName}</small>
                        <div class="card-buttons">
                            <button class="button-primary" onClick={() => handleProperty()}>Properties</button>
                            <button class="button-secondary" onClick={{}}>Notify Tenants</button>
                        </div>
                    </div>
                    <div class="card" onClick={goToTransport}>
                    <img style={{ zIndex: '0' }} src="/transport--new.jpg" alt="Account Image" class="card-bg-img" />
    <div 
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',  // Adjust opacity as needed
            zIndex: '1',
        }}
        className="dimming-overlay"
    ></div>
                        <h2 style={{ color: '#ffffff' }}>Transport schedule</h2>
                        <small style={{ color: '#ffffff' }}>View the latest transport schedule</small>
                    </div>
                    <Link to="/RegularAdminPay">
                        <div class="card">
                        <img style={{ zIndex: '0' }} src="/Online-shopping.jpg" alt="Account Image" class="card-bg-img" />
    <div 
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',  // Adjust opacity as needed
            zIndex: '1',
        }}
        className="dimming-overlay"
    ></div>
                            <h2 style={{ color: '#ffffff' }}>Payment</h2>
                            <small style={{ color: '#ffffff' }}>See tenant transcations</small>
                        </div>
                    </Link>
                    <Link to="/LandLord">
                        <div class="card">
                        <img style={{ zIndex: '0' }} src="blogjan.webp" alt="Account Image" class="card-bg-img" />
    <div 
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',  // Adjust opacity as needed
            zIndex: '1',
        }}
        className="dimming-overlay"
    ></div>
                            <h2 style={{ color: '#ffffff' }}>Tenants</h2>
                            <small style={{ color: '#ffffff' }}>take a look at your tenants details</small>
                        </div>
                    </Link>
                    <Link to="/ServiceBoxAdmin">
                    <div class="card">
                    <img style={{ zIndex: '0' }} src="https://starfieldconsulting.nl/wp-content/uploads/elementor/thumbs/pexels-yan-krukov-8867474-scaled-peek3pzknc3hl3712gn5ohxfq1oah5jmyp50swwhiw.jpg" alt="Account Image" class="card-bg-img" />
    <div 
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',  // Adjust opacity as needed
            zIndex: '1',
        }}
        className="dimming-overlay"
    ></div>
                        <h2 style={{ color: '#ffffff' }}>Service Box</h2>
                        <small style={{ color: '#ffffff' }}>View tenants complaints  </small>
                    </div>
                    </Link>
                </div>
            </body>
              ) : (
                <div className="dashboard-page">
                {/* Header Section */}
            {/*    <div className="header-section">
     <div className="profile-picture">
      <img src={`https://backendjaes.passiontrials.co.za/src${admin?.ExteriorImage}`} alt="Profile" />
    </div> 
    <div className="profile-info">
      <h1 className="welcome-message">
        Hello, <span className="profile-name">{admin?.AdminName} {admin?.AdminSurname}</span>
      </h1>
      <h2 className="approval-status">Review your data while you wait for approval</h2>
      <p className="support-message">
        Your account is not approved at the moment. <br />
        A representative from our team will contact you shortly.
      </p>
    </div>
  </div>*/}
              
                {/* Profile Details Section */}
                <div  className="details-container">
                <div className="header-section">
    {/* <div className="profile-picture">
      <img src={`https://backendjaes.passiontrials.co.za/src${admin?.ExteriorImage}`} alt="Profile" />
    </div> */}
    <div className="profile-info">
      <h1 className="welcome-message">
        Hello, <span className="profile-name">{admin?.AdminName} {admin?.AdminSurname}</span>
      </h1>
      <h2 className="approval-status">Review your data while you wait for approval</h2>
      <p className="support-message">
        Your account is not approved at the moment. <br />
        A representative from our team will contact you shortly.
      </p>
    </div>
  </div>
                <div className="details-section" ><div className="detail-item">
      <strong>Address:</strong>
      <span className="detail-value">{admin?.StreetAddress}</span>
    </div>
    <div className="detail-items">
      <strong>Property: </strong>
      <span className="detail-value">{admin?.PropertyType}</span>
    </div>
    <div className="detail-items">
      <strong>Tenants: </strong>
      <span className="detail-value">{admin?.Guest}</span>
    </div>
    
  </div>
  
  {/* <div className="details-section">
    <div className="detail-item">
      <strong>Bedrooms:</strong>
      <span className="detail-value">{admin?.BedroomCount}</span>
    </div>
    <div className="detail-item">
      <strong>Bathrooms:</strong>
      <span className="detail-value">{admin?.BathroomCount}</span>
    </div>
    <div className="detail-item">
      <strong>Beds:</strong>
      <span className="detail-value">{admin?.BedCount}</span>
    </div>
  </div> */}
                  </div>
               {/* Amenities Section <h2 style={{ color: '#ffffff' }}>Your place has to offer</h2>*/}
               <div className="amenities-section">
                        {/* <h2 style={{ color: '#ffffff' }}>Amenities</h2> */}

                        {/* Guest Favorites */}
                        <div className="amenity-category">
                            <h3>Guest Favorites</h3>
                            <ul>
                                {selectedGuestFavorites.length > 0 ? (
                                    selectedGuestFavorites.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))
                                ) : (
                                    <p>No Guest Favorites selected.</p>
                                )}
                            </ul>
                        </div>

                        {/* Additional Amenities */}
                        <div className="amenity-category">
                            <h3>Additional Amenities</h3>
                            <ul>
                                {selectedAdditionalAmenities.length > 0 ? (
                                    selectedAdditionalAmenities.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))
                                ) : (
                                    <p>No Additional Amenities selected.</p>
                                )}
                            </ul>
                        </div>

                        {/* Safety Items */}
                        <div className="amenity-category">
                            <h3>Safety Items</h3>
                            <ul>
                                {selectedSafetyItems.length > 0 ? (
                                    selectedSafetyItems.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))
                                ) : (
                                    <p>No Safety Items selected.</p>
                                )}
                            </ul>
                        </div>
                    </div>
   {/* Images Section */}
   {/* <div className="images-section">
    <h3>Images of Your Property</h3>
    <div className="property-images">
      <img src={`https://backendjaes.passiontrials.co.za/src${admin?.InteriorImage}`} alt="Interior" />
      <img src={`https://backendjaes.passiontrials.co.za/src${admin?.ExteriorImage}`} alt="Exterior" />
    </div>
  </div> */}
 

              
               
              </div>
 
            )}
             <style>
                {`
               .amenities-section {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Space between sections */
    
    margin-bottom: 20px;
    flex-wrap: wrap; /* Ensure content wraps to the next row if needed */
}

.amenity-category {
    flex: 1 1 calc(33.33% - 20px); /* Each section takes up 1/3 of the row minus the gap */
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.amenity-category h3 {
    font-size: 18px;
    color: #555;
    margin-bottom: 10px;
}

.amenity-category ul {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1; /* Allows the list to take up available vertical space */
}

.amenity-category li {
    font-size: 16px;
    margin: 5px 0;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .amenities-section {
        flex-direction: column; /* Stack sections vertically on smaller screens */
        gap: 10px;
    }

    .amenity-category {
        flex: 1 1 100%; /* Full width for smaller screens */
    }
}

                `}
            </style>
            <style>
                {`
                /* Details Section */
.details-container {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Space between the details sections */
  margin-bottom: 20px;
}
                
                .dashboard-page {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
}

/* Header Section */
.header-section {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-picture img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #ff5a5f;
}

.profile-info {
  flex: 1;
}

.welcome-message {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.profile-name {
  color: #ff5a5f;
}

.approval-status {
  font-size: 18px;
  color: #555;
}

.support-message {
  font-size: 14px;
  color: #888;
}

/* Details Section */
.details-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.detail-item {
  font-size: 16px;
  color: #333;
}

.detail-item strong {
  color: #555;
  display: block;
  margin-bottom: 5px;
}

.detail-value {
  color: #000;
  font-weight: bold;
}

/* Images Section */
.images-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.images-section h3 {
  margin-bottom: 20px;
  font-size: 20px;
  color: #ff5a5f;
}

.property-images {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.property-images img {
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Mobile Design */
@media (max-width: 768px) {
  .header-section {
    flex-direction: column;
    text-align: center;
  }

  .profile-picture img {
    margin: 0 auto;
  }

  .details-section {
    grid-template-columns: 1fr;
  }

  .property-images {
    justify-content: center;
  }
}
`}
            </style>
        </div >
    );
}
export default RegularAdmin;
