import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import houseIcon from './icons8-house-50.png'; 
import DoublehouseIcon from './159-512.webp'; 
import Apartment from './icons8-company-50.png'; 
import Townhouse from './townhouse.jpg'; 
import Cottage from './cottage.jpg'; 

const Steps2Admin = () => {
  const [typeOfHouse, settypeOfHouse] = useState(null);
  const navigate = useNavigate(); // Initialize the navigate function

  const handleNext = () => {
    if (typeOfHouse) {
      navigate("/Steps3Admin", { state: { typeOfHouse } }); // Pass typeOfHouse to /Steps3Admin
    } else {
      alert("Please select an option before proceeding.");
    }
  };

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleOptionClick = (option) => {
    settypeOfHouse(option);
  };

  return (
    <div>
      <style>{`
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 0;
          background-color: #fff;
        }
        .container {
          max-width: 700px;
          margin: 50px auto 0;
          padding: 20px;
          text-align: center;
        }
        .container h3 {
          font-size: 28px;
          margin-bottom: 30px;
          color: rgb(36, 35, 35);
        }
        .options-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 20px;
          margin-bottom: 40px;
        }
        @media (max-width: 768px) {
          .options-grid {
            grid-template-columns: repeat(2, 1fr); /* Two per row for smaller screens */
          }
          .container {
            max-width: 100%; /* Adjust width for smaller screens */
            padding: 10px; /* Add padding on all sides */
            margin: 20px auto; /* Center horizontally and add vertical margin */
          }
          .progress-step1cont {
            margin-bottom: 100px; /* Move slightly upward for mobile devices */
          }
        }
        .option {
          border: 1px solid #ddd;
          border-radius: 8px;
          padding: 20px;
          display: flex; 
          align-items: center;
          justify-content: flex-start; 
          gap: 10px; 
          cursor: pointer;
          transition: border-color 0.3s;
        }
        .option:hover {
          border-color: #000;
        }
        .option img {
          max-width: 40px;
        }
        .option span {
          font-size: 16px;
          color: #333;
          text-align: left; 
        }
        .option.selected {
          border-color: #000;
          box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
        }
       .progress-step1cont {
  position: fixed; /* Fix the container at a specific position */
  bottom: 0; /* Place it at the bottom of the viewport */
  left: 0;
  right: 0;
  width: 100%; /* Make sure it spans the full width */
  max-width: 1200px;
  margin: 0 auto; /* Center it horizontally */
  padding: 0 10px;
  box-sizing: border-box;
  background: #fff; /* Add a background to prevent overlap issues */
  z-index: 1000; /* Ensure it stays above other elements */
}

        .progress-bar {
          height: 4px;
          background: #e0e0e0;
          margin-bottom: 5px;
          position: relative;
        }
        .progress {
          height: 100%;
          width: 33.32%;
          background: #000;
          transition: width 0.3s ease-in-out;
        }
        .buttons-step1cont {
          display: flex;
          justify-content: space-between; /* Space between buttons */
          align-items: center;
          width: 100%;
          margin-top: 10px; /* Add some space above buttons */
        }
        .back-button {
          text-decoration: underline;
          color: #000;
          background: none;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 12px; /* Reduced font size */
          padding: 15px 0px; /* Smaller padding */
        }
        .next-button {
          background: #000;
          max-width: 50px;
          color: #fff;
          border: none;
          padding: 5px 10px; /* Smaller padding */
          border-radius: 4px;
          font-size: 12px; /* Reduced font size */
          cursor: pointer;
        }

        
@media (max-width: 768px) {
  .buttons-step1cont {
    justify-content: flex-start; /* Align all buttons to the left */
  }
  .next-button {
    margin-left: 150px; /* Add some spacing from the left edge */
  }
}
      `}</style>
      <div className="container">
        <h3>Which of these best describes your place?</h3>
        <div className="options-grid">
          <div
            className={`option ${typeOfHouse === "House" ? "selected" : ""}`}
            onClick={() => handleOptionClick("House")}
          >
            <img src={houseIcon} alt="House" />
            <span>House</span>
          </div>
          <div
            className={`option ${typeOfHouse === "Double Story" ? "selected" : ""}`}
            onClick={() => handleOptionClick("Double Story")}
          >
            <img src={DoublehouseIcon} alt="Apartment" />
            <span>Double Story</span>
          </div>
          <div
            className={`option ${typeOfHouse === "Apartment" ? "selected" : ""}`}
            onClick={() => handleOptionClick("Apartment")}
          >
            <img src={Apartment} alt="Cottage" />
            <span>Apartment</span>
          </div>
          <div
            className={`option ${typeOfHouse === "Townhouse" ? "selected" : ""}`}
            onClick={() => handleOptionClick("Townhouse")}
          >
            <img src={Townhouse} alt="Townhouse" />
            <span>Townhouse</span>
          </div>
          <div
            className={`option ${typeOfHouse === "Cottage" ? "selected" : ""}`}
            onClick={() => handleOptionClick("Cottage")}
          >
            <img src={Cottage} alt="Apartment" />
            <span>Cottage</span>
          </div>
        </div>
      </div>
      <div className="progress-step1cont">
        <div className="progress-bar">
          <div className="progress"></div>
        </div>
        <div className="buttons-step1cont">
          <button className="back-button" onClick={handleBack}>
            Back
          </button>
          <button className="next-button" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Steps2Admin;
