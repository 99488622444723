import React, { useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider"; // Import useAuth

import wifi from "./icons8-wifi-32.png"; // Import images as needed
import tvIcon from "./smart-tv.png";
import kitchenIcon from "./gas-stove.png";
import washerIcon from "./tumble-dry.png";
import freeparcking from "./parking.png";
import freeparckingmeter from "./parking-meter.png";
import aircon from "./air-conditioner.png";
import workplace from "./workplace.png";

import pool from "./swimming-pool.png";
import hottub from "./hot-bath.png";
import patio from "./silhouette.png";
import bbq from "./bbq.png";
import fireplace from "./fireplace.png";
import Area from "./area.png";
import pooltable from "./billiards.png";
import piano from "./grand-piano.png";
import exercise from "./gym.png";
import beach from "./beach.png";
import OutShower from "./shower.png";

import SmokeAlarm from "./smoke-detector.png";
import FirsttKit from "./first-aid-kit.png";
import FireExting from "./fire-extinguisher.png";
import CarbonMon from "./fire-alarm.png";

const AmenityOption = ({ amenity, isSelected, toggleSelection }) => {
  return (
    <div
      className={`option ${isSelected ? "selected" : ""}`}
      onClick={() => toggleSelection(amenity.name)}
    >
      <img src={amenity.icon} alt={amenity.name} />
      <span>{amenity.name}</span>
    </div>
  );
};

const Steps9Admin = () => {
  const [selectedGuestFavorites, setSelectedGuestFavorites] = useState([]);
  const [selectedAdditionalAmenities, setSelectedAdditionalAmenities] = useState([]);
  const [selectedSafetyItems, setSelectedSafetyItems] = useState([]);
  const navigate = useNavigate();
  const { userId } = useAuth(); // Get the user object from the auth context


  const toggleSelection = (amenityName, category) => {
    const setter =
      category === "guestFavorites"
        ? setSelectedGuestFavorites
        : category === "additionalAmenities"
        ? setSelectedAdditionalAmenities
        : setSelectedSafetyItems;

    setter((prev) =>
      prev.includes(amenityName)
        ? prev.filter((item) => item !== amenityName)
        : [...prev, amenityName]
    );
  };
  useEffect(() => {
    if (userId) { // Ensure userId is available before making the request
      fetch(`https://backendjaes.passiontrials.co.za/api/get-admin-amenities?AdminId=${userId}`)
        .then((response) => response.json())
        .then((data) => {
          setSelectedGuestFavorites(JSON.parse(data.GuestFavorites || "[]"));
          setSelectedAdditionalAmenities(JSON.parse(data.AdditionalAmenities || "[]"));
          setSelectedSafetyItems(JSON.parse(data.SafetyItems || "[]"));
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [userId]); // Add userId as a dependency to re-run when it changes
  
  
  const handleNext = () => {
    if (
      selectedGuestFavorites.length === 0 &&
      selectedAdditionalAmenities.length === 0 &&
      selectedSafetyItems.length === 0
    ) {
      alert("Please select at least one item.");
      return;
    }
  
    // Make a POST request to save data
    fetch("https://backendjaes.passiontrials.co.za/api/update-admin-amenities", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        AdminId: userId, // Replace with dynamic AdminId
        GuestFavorites: selectedGuestFavorites,
        AdditionalAmenities: selectedAdditionalAmenities,
        SafetyItems: selectedSafetyItems,
      }),
    })
      .then((response) => {
        if (response.ok) {
          navigate("/Steps3Admin");
        } else {
          alert("Error saving data");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  

  const handleBack = () => {
    navigate(-1);
  };

  const guestFavorites = [
    { name: "Wifi", icon: wifi },
    { name: "TV", icon: tvIcon },
    { name: "Kitchen", icon: kitchenIcon },
    { name: "Washer", icon: washerIcon },
    { name: "Free parking on premises", icon: freeparcking },
    { name: "Paid parking on premises", icon: freeparckingmeter },
    { name: "Air conditioning", icon: aircon },
    { name: "Dedicated workspace", icon: workplace },
  ];

  const additionalAmenities = [
    { name: "Pool", icon: pool },
    { name: "Hot tub", icon: hottub },
    { name: "Patio", icon: patio },
    { name: "BBQ grill", icon: bbq },
    { name: "Outdoor dining area", icon: Area },
    { name: "Indoor fireplace", icon: fireplace },
    { name: "Pool table", icon: pooltable },
    { name: "Piano", icon: piano },
    { name: "Exercise equipment", icon: exercise },
    { name: "Beach access", icon: beach },
    { name: "Outdoor shower", icon: OutShower },
  ];

  const safetyItems = [
    { name: "Smoke alarm", icon: SmokeAlarm },
    { name: "First aid kit", icon: FirsttKit },
    { name: "Fire extinguisher", icon: FireExting },
    { name: "Carbon monoxide alarm", icon: CarbonMon },
  ];

  return (
    <div>
      <style>{`
        .container {
          max-width: 600px;
          margin: 50px auto;
          padding: 20px;
          font-family: Arial, sans-serif;
        }
        .container h3 {
          font-size: 24px;
          margin-bottom: 10px;
          color: #242323;
        }
        .container p {
          font-size: 16px;
          margin-bottom: 20px;
          color: #555;
        }
        .options-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
          margin-bottom: 40px;
        }
        @media (min-width: 768px) {
          .options-grid {
            grid-template-columns: repeat(3, 1fr);
          }
        }
        .option {
          border: 1px solid #ddd;
          border-radius: 8px;
          padding: 10px;
          cursor: pointer;
          transition: border-color 0.3s, box-shadow 0.3s;
          text-align: left;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .option:hover {
          border-color: #000;
          box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
        }
        .option.selected {
          border-color: #000;
          box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
          background-color: #f0f0f0;
        }
        .option span {
          font-size: 11px;
          color: #333;
          text-align: left;
          width: 100%;
        }
        .progress-step1cont {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          background: #fff;
          z-index: 1000;
          padding: 10px 20px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .progress-bar {
          height: 4px;
          background: #e0e0e0;
          position: relative;
          flex: 1;
          margin-right: 20px;
        }
        .progress {
          height: 100%;
          width: 75%;
          background: #000;
          transition: width 0.3s ease-in-out;
        }
        .back-button,
        .next-button {
          background: #000;
          color: #fff;
          padding: 10px 20px;
          border-radius: 4px;
          border: none;
          font-size: 14px;
          cursor: pointer;
        }
        .back-button {
          background: none;
          color: #000;
          text-decoration: underline;
          font-size: 12px;
        }
        .option img {
          width: 30px;
          height: 30px;
          flex-shrink: 0;
          align-self: flex-start;
        }
      `}</style>
      <div className="container">
        <h3>Tell guests what your place has to offer</h3>
        <p>You can add more amenities after you publish your listing.</p>

        <h3>Guest Favorites</h3>
        <div className="options-grid">
          {guestFavorites.map((amenity) => (
            <AmenityOption
              key={amenity.name}
              amenity={amenity}
              isSelected={selectedGuestFavorites.includes(amenity.name)}
              toggleSelection={(name) => toggleSelection(name, "guestFavorites")}
            />
          ))}
        </div>

        <h3>Do you have any standout amenities?</h3>
        <div className="options-grid">
          {additionalAmenities.map((amenity) => (
            <AmenityOption
              key={amenity.name}
              amenity={amenity}
              isSelected={selectedAdditionalAmenities.includes(amenity.name)}
              toggleSelection={(name) => toggleSelection(name, "additionalAmenities")}
            />
          ))}
        </div>

        <h3>Safety Items</h3>
        <div className="options-grid">
          {safetyItems.map((item) => (
            <AmenityOption
              key={item.name}
              amenity={item}
              isSelected={selectedSafetyItems.includes(item.name)}
              toggleSelection={(name) => toggleSelection(name, "safetyItems")}
            />
          ))}
        </div>
      </div>
      <div className="progress-step1cont">
        <div className="progress-bar">
          <div className="progress"></div>
        </div>
        <div className="buttons-step1cont">
          <button className="back-button" onClick={handleBack}>
            Back
          </button>
          <button className="next-button" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Steps9Admin;

