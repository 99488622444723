import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import Apartments from './Apartments';
import defaults from "./default_room_room.jpg";
import './invoice.css'
import './loaders.css'
import { PDFDocument, rgb } from 'pdf-lib';
import RegistrationModal from './components/ReserveRegistrationModal.js'; // Adjust the path based on your folder structure
import logo from './logoblack.png';
import { Star, Bus, MapPinned, PhoneForwarded, User } from 'lucide-react';


const FrontPage = ({ onContinue }) => (
    <div className="full-page-content2">
        <div className="front-page">
            <div className="gif-section">
                <img height="100%" width="100%" alignItems="center"
                    src="https://i.pinimg.com/originals/c8/94/25/c89425e21101aab70a7882867304ce41.gif"
                    class="Image-module"
                    aria-hidden="true"
                    style={{ borderRadius: '8px' }} />
            </div>
            <div className="subsection-module__title register-section">
                <img src={logo} alt="Logo" className="logo" />
                <h2 className="subsection-module__title" style={{ color: 'white' }} >Welcome to JAES Student Home</h2>
                <p style={{ color: 'white', display: 'none' }} className="desktop-only">
                    Discover a new way of student living with JAES.
                </p>

                <p style={{ color: 'white' }}>
                    Press continue to complete your reservation form.
                </p>
                <button className="btn456" onClick={onContinue} >Continue</button>
            </div>
        </div>
    </div>

);

function NewReserve({ handleClose, handleSubmit }) {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);
    const [selectedPlan1, setSelectedPayment] = useState('');
    const [userData, setUserData] = useState(null);
    const [downloadAfterLogin, setDownloadAfterLogin] = useState(false);
    const [clickedAction, setClickedAction] = useState(null); // Track the action button pressed
    let [totalAmount, setTotalAmount] = useState(null);
    let [serviceFee, setServiceFee] = useState(null);
    let [chosenPlan, setChosenPlan] = useState(null);
    const [thePrice, setThePrice] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showHouseRulesModal, setShowHouseRulesModal] = useState(false);
    const [isEmergencyChecked, setIsEmergencyChecked] = useState(false);
    const [isHouseRulesChecked, setIsHouseRulesChecked] = useState(false);
    const [isIncidentFormChecked, setIsIncidentFormChecked] = useState(false);
    const [isLeaseClauseChecked, setIsLeaseClauseChecked] = useState(false);
    const [step, setStep] = useState(0);
    const [transport, setTransport] = useState('');
    const [fundingOption, setFundingOption] = useState("");
    const [totalCost, setTotalCost] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const navigate = useNavigate();
    const [selectedPlan, setSelectedPlan] = useState('');
    const transportCost = transport === "yes" ? 250 : 0;
    const { isLoggedIn, role } = useAuth();
    const { date, apartment, apartmentandbuilding } = location.state || {};
    let chosenDefault = 'not used';
    const [showLoginPrompt, setShowLoginPrompt] = useState(false);


    const { userId } = useAuth(); // Get userId from AuthContext
    let amount = null;
    function isNullOrUndefined(value) {
        return value == null || value == undefined || value == '';
    }
    useEffect(() => {
        // Calculate the total whenever selectedPlan or transport changes
        const planCost = selectedPlan ? parseInt(selectedPlan) : 0;
        if (fundingOption === 'nsfas') {
            setTransport('yes'); // Automatically set transport to "yes"
            setTotalCost(planCost); // Exclude transport cost from total for NSFAS users
        } else {
            // For non-NSFAS users, include transport cost if transport is "yes"
            const transportCost = transport === "yes" ? 250 : 0;
            setTotalCost(planCost + transportCost);
        }
       // setTotalCost(planCost + transportCost);

        const items = [];
        if (transport === 'yes') items.push('Transport (R250)');
        if (selectedPlan) {
            const planName =
                selectedPlan === '900'
                    ? 'Early Entry'
                    : selectedPlan === '1200'
                        ? 'Standard Entry'
                        : 'Late Entry';
            items.push(`Plan (${planName})`);
        }

        setSelectedItems(items);
    }, [selectedPlan, transport]);


    useEffect(() => {
        // Check if all required data is present before trying to access it
        if (!date || !apartment || !apartmentandbuilding) {
            console.error("Missing necessary data from location.state", date, apartment, apartmentandbuilding);
            setLoading(false); // Set loading false to show error or missing data message
            return; // Prevent further execution
        }
        setLoading(false);
        window.scrollTo(0, 0); // Data is present, so you can turn off loading
    }, [date, apartment, apartmentandbuilding]); // Ensure useEffect runs when any of these dependencies update




    useEffect(() => {
        if (isLoggedIn && userId) {
            const fetchUserData = async () => {
                try {
                    const response = await fetch(`https://backendjaes.passiontrials.co.za/user_reserve/${userId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    if (!response.ok) {
                        throw new Error('Failed to fetch user data');
                    }

                    const data = await response.json();
                    setUserData(data.length ? data[0] : data);

                    console.log("dsdasdasadasd", data)

                    setFundingOption(data[0].TypeOfFunding.toLowerCase().replace(" ", "-"));

                    const funding = data[0].TypeOfFunding.toLowerCase().replace(" ", "-");
                    const Designation = apartment?.RoomDesignation?.toLowerCase()?.replace(" ", "-");

                    console.log("the funding type", funding, 'the actual from the database', data[0].TypeOfFunding);

                    if (funding == 'nsfas') {
                        setTransport('yes');
                        console.log(`this is the funding ${funding}  type and this is the `, Designation);


                        if (isNullOrUndefined(Designation) || Designation !== 'nsfas') {
                            Swal.fire({
                                title: "Non-supported Apartment",
                                text: "This apartment is not NSFAS funded. Please choose an NSFAS-supported apartment.",
                                icon: "warning"
                            });
                            console.log(`this is the user funding $ ${funding}  type and this is the room $ `, Designation);
                            navigate(-1);
                        }

                    }



                    setShowRegistrationModal(false);

                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            };
            fetchUserData();
        }
        else {
            setShowRegistrationModal(true);
        }
    }, [isLoggedIn, userId]);

    // const nextStep = () => setStep((prevStep) => Math.min(prevStep + 1, 5));
    // const prevStep = () => setStep((prevStep) => Math.max(prevStep - 1, 1));

    const nextStep = () => {
        setStep((prevStep) => {
            if (fundingOption === "nsfas" && prevStep === 1) {
                return 3; // Skip step 2 and go directly to step 3
            }
            return Math.min(prevStep + 1, fundingOption === "nsfas" ? 5 : 5); // 5 steps in both cases, but step 2 is skipped for NSFAS
        });
    };

    const prevStep = () => {
        setStep((prevStep) => {
            if (fundingOption === "nsfas" && prevStep === 3) {
                return 1; // Go back to step 1 from step 3 for NSFAS users
            }
            return Math.max(prevStep - 1, 1); // Ensure minimum step is 1
        });
    };

    const handleFundingChange = (event) => {
        setFundingOption(event.target.value);

        // if (selectedFunding === "nsfas") {
        //     setTransport("yes");
        // } else {
        //     setTransport(""); // Reset transport for other funding types
        // }
    };
    const CHECKBOX_OPTIONS = [
        { id: 'emergency-protocol', label: 'Emergency Protocol', stateKey: 'isEmergencyChecked' },
        { id: 'house-rules', label: 'House Rules', stateKey: 'isHouseRulesChecked' },

    ];

    const handleCardClick = (planCost) => {
        setSelectedPlan(planCost);
        nextStep();
    };


    // Define state for each checkbox item
    const [checkboxStates, setCheckboxStates] = useState({
        isEmergencyChecked: false,
        isHouseRulesChecked: false,

    });

    // Function to toggle checkbox state based on the key
    const toggleCheckbox = (key) => {
        setCheckboxStates((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    if (step === 0) {
        // Show the front page first
        return <FrontPage onContinue={() => setStep(1)} />;

    }



    const allCheckboxesChecked = isEmergencyChecked && isHouseRulesChecked && isIncidentFormChecked && isLeaseClauseChecked;



    const handlePayment2 = async () => {
        try {
            const amountInCents = Math.round(parseFloat(totalAmount) * 100);
            const response = await fetch('https://backendjaes.passiontrials.co.za/api/pay', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    amount: amountInCents,
                    currency: 'ZAR',
                    successUrl: `https://jaesstudenthomes.co.za/Payment?status=success&user=50`,
                    cancelUrl: 'https://jaesstudenthomes.co.za/cancel?status=cancel'
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error: ${response.status} - ${errorText}`);
            }

            const data = await response.json();
            console.log('Payment response:', data);

            if (data.redirectUrl) {
                window.location.href = data.redirectUrl;
            }
        } catch (error) {
            console.error('Payment error:', error);
        }
    };

    const generatePDF = async () => {
        setClickedAction('downloadPDF'); // Set action as 'downloadPDF'
        if (!isEmergencyChecked || !isHouseRulesChecked || !isIncidentFormChecked || !isLeaseClauseChecked) {
            alert('Please agree to all the terms by checking the boxes.');
            return;
        }
        if (!isLoggedIn) {
            // If the user is not logged in, set the flag and show the registration modal
            setDownloadAfterLogin(true);
            setShowRegistrationModal(true);
            return;
        }

        // If the user is logged in, proceed with generating the PDF
        if (userData) {
            try {
                // Fetch the PDF file as an array buffer
                const response = await fetch('https://jaesstudenthomes.co.za/STUDENT%20HOMES%20BOOKING%20FORM%202025.pdf');
                const pdfBytes = await response.arrayBuffer();

                // Load PDF using pdf-lib
                const pdfDoc = await PDFDocument.load(pdfBytes);
                const firstPage = pdfDoc.getPages()[0];

                // Add user data to the PDF
                firstPage.drawText(`${userData.Name} ${userData.Surname}`, { x: 230, y: 535, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(userData.IdNumber || userData.PassportNumber, { x: 230, y: 510, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(apartmentandbuilding.RoomIdentifier, { x: 180, y: 790, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText("X", { x: 385, y: 490, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText("X", { x: 230, y: 490, size: 12, color: rgb(0, 0, 0) });

                firstPage.drawText(apartmentandbuilding.BuildingLocation || 'N/A', { x: 230, y: 465, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(userData.Email, { x: 265, y: 440, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(userData.PhoneNumber, { x: 255, y: 415, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(new Date().toLocaleDateString('en-GB'), { x: 170, y: 85, size: 12, color: rgb(0, 0, 0) });

                // Save the modified PDF to a blob and trigger download
                const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
                const link = document.createElement('a');
                link.href = pdfDataUri;
                link.download = `Booking_Form_${Date.now()}.pdf`;
                document.body.appendChild(link); // Append link to the DOM for Firefox support
                link.click();
                document.body.removeChild(link); // Remove link after download

                console.log('PDF successfully created and downloaded.');
            } catch (error) {
                console.error('Error creating PDF:', error.message);
            }
        } else {
            console.error("User data is not available for generating PDF");
        }
    };
    // Function to handle payment
    const handlePayment = () => {
        setClickedAction('pay'); // Set action as 'pay'

        if (role === 'Admin' || role === 'Super Admin') {
            alert('Admins are not permitted to book an apartment');
            navigate(-1);
            return;
        }

        if (selectedPlan && allCheckboxesChecked) {
            if (!isLoggedIn) {
                // Show the registration modal if user is not logged in
                setShowRegistrationModal(true);
                return;
            }

            setLoading(true);
            setTimeout(() => {
                setLoading(false);
                navigate('/YocoPayment', { state: { date, apartment, apartmentandbuilding: apartmentandbuilding.BuildingId, totalCost, serviceFee, chosenDefault, nextPaymentDate, Email: userData.Email } });
            }, 5000);
        } else if (!allCheckboxesChecked) {
            alert('Please agree to all the terms by checking the boxes.');
        } else {
            alert('Please select a payment plan');
        }
    };

    const handlePaymentCurrent = () => {
        setClickedAction('pay'); // Set action as 'pay'
        console.log("handlePayment triggered");

        if (role === 'Admin' || role === 'Super Admin') {
            alert('Admins are not permitted to book an apartment');
            navigate(-1);
            return;
        }
        // if (!selectedRadioAmount) {
        //     alert('Please select an amount.');
        //     return;
        // }
        // if (!selectedFunding) {
        //     alert('Please select a funding option.');
        //     return;
        // }
        // if (!allCheckboxesChecked) {
        //     alert('Please agree to all the terms by checking the boxes.');
        //     return;
        // }

        if (!isLoggedIn) {
            console.log("User not logged in, opening registration modal");
            setShowRegistrationModal(true);
            return;
        }

        // Proceed with payment if all checks are passed
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setTotalAmount(totalCost);
            setServiceFee(0)
            // setSelectedFunding(fundingOption);
            // selectedTransport = transport;
            // let chosenDefault = 'not used';

            console.log("Navigating to Reservepayment with the following state data:");
            console.log("Date:", date);
            console.log("Apartment:", apartment);
            console.log("Apartment and Building ID:", apartmentandbuilding.BuildingId);
            console.log("Total Amount:", totalCost);
            console.log("Service Fee:", serviceFee);
            console.log("Chosen Plan:", chosenPlan);
            console.log("selectedTransport:", transport);
            console.log("Next Payment Date:", nextPaymentDate);
             if(fundingOption ==='nsfas'){
                setTransport('yes');
             }
            navigate('/ReserveYocoPayment', {
                state: {
                    date,
                    apartment,
                    apartmentandbuilding: apartmentandbuilding.BuildingId,
                    totalCost,
                    ServiceFee: chosenDefault,
                    chosenDefault,
                    nextPaymentDate,
                    transport,
                    fundingOption,
                    Email: userData.Email
                }
            });
        }, 5000);
    };


    const handleGender = (binary) => {
        if (binary) {
            if (binary == 0) {
                return 'Female';
            }

            if (binary == 1) {

                return 'Male';
            }

            if (binary == 2) {
                return 'Mixed';
            }
        }
    }

    const handleSetup = (binary) => {

        if (binary) {
            if (binary == 0) {
                return "Single";
            }

            if (binary == 1) {
                return 'Single with Ensuite';
            }

            if (binary == 2) {
                return 'Sharing Double';
            }

            if (binary == 3) {
                return 'Sharing Double with Ensuite';
            }

            if (binary == 4) {
                return 'Sharing Triple';

            }

            if (binary == 5) {
                return 'Bachelor Pods';
            }
        }
    };

    const handleSelectedPlan = (e) => {
        const { name, value } = e.target;
        let plan = e?.target.options[e?.target.selectedIndex]?.text;
        setSelectedPayment(Number(value));
        console.log('this is the plan amount chosen', Number(value) + amount);
        // setTotalAmount(Number(value) + amount);
        setServiceFee(Number(value));

        setChosenPlan(plan);


        let splited = plan.split('-')[0].trim();

        console.log('its split :', splited);
        if (splited == 'Plan A') {
            amount = apartment?.ApartmentPrice;
            //represents plan a
            console.log("plan a:,", amount);
            setThePrice(amount);
            setTotalAmount(amount + Number(value));
        }

        if (splited == 'Plan B') {
            amount = apartment?.PriceB;
            console.log("plan b:,", amount);
            setThePrice(amount);
            setTotalAmount(amount + Number(value));

        }

    };

    // React.useEffect(() => {
    //     console.log('This is the chosen plan:', chosenPlan);
    //     setChosenPlan(chosenPlan);
    // }, [chosenPlan]);

    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh' // This ensures it takes the full viewport height
        }}>
            <div className="🤚">
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="🌴"></div>
                <div className="👍"></div>
            </div>;     </div>// Show loading while checking for data
    }

    if (!date || !apartment || !apartmentandbuilding) {
        return <div>Error: Missing invoice data. Please ensure all data is provided.</div>; // Show error if data is missing
    }
    // Callback function after successful registration
    const handleRegistrationSuccess = () => {
        setShowRegistrationModal(false); // Close the modal

        // Debug log to check which action is triggered
        console.log("Registration Success:", clickedAction);

        if (downloadAfterLogin || clickedAction === 'downloadPDF') {
            console.log("Downloading PDF after login..."); // Check if this line is reached
            generatePDF(); // Call `generatePDF()` to download the PDF
            setDownloadAfterLogin(false); // Reset the flag
        } else if (clickedAction === 'pay') {
            console.log("Redirecting to YocoPayment...");
            navigate('/ReserveYocoPayment', { state: { /* your existing parameters */ } });
        }
    };
    // Format dates
    const pickedDate = new Date(date).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }); //current date no picking
    const nextPaymentDate = new Date(date);
    nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 3);

    nextPaymentDate.setDate(new Date(nextPaymentDate.getFullYear(), nextPaymentDate.getMonth() + 1, 0).getDate());

    ///= apartment.ApartmentPrice;
    let currentDay = date.toLocaleDateString('en-US', { day: 'numeric' });
    let earlyDate = 0;
    let lateDate = 0;
    let AmountAfterDeduction = 0;
    let AmountAfterIncrease = 0;

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('image', selectedFile);

        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/upload',
                {
                    headers: { 'Content-Type': 'multipart/form-data', },
                    method: "POST",
                    body: formData,
                });

            if (response.ok) {
                const data = await response.json();
                console.log('Image uploaded successfully: ', data);

            }
            else {
                console.error('Error uploading image:');
            }
        }
        catch (error) {
            console.error('error uploading image:', error);
        }

    };


    // if (currentDay > 1 && currentDay < 25) {

    //     currentDay = currentDay - 1;
    //     earlyDate = currentDay / 100 * amount;

    //     AmountAfterDeduction = amount - earlyDate;

    //     amount = AmountAfterDeduction;

    // }
    // else if (currentDay > 24 && currentDay <= 31) {
    //     currentDay = currentDay - 1;
    //     lateDate = currentDay / 100 * amount;

    //     AmountAfterIncrease = lateDate + amount;

    //     amount = AmountAfterIncrease;

    //     nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1, 1);

    // }

    return (
        <>

            <div class="containerinvoice" >


                <div className="rounded-lg lg:shadow-lg lg:p-3 lg:border border-gray-100 booking-summary">
                    <div>
                        <div className="flex">
                            <p className="content-font uppercase font-semibold text-gray-500">Reservation Summary</p>
                        </div>
                        <img
                            alt="Classic Ensuite"
                            src={apartment.ApartmentRoomImage ? `https://backendjaes.passiontrials.co.za/src${apartment.ApartmentRoomImage}` : defaults}
                            width="301"
                            height="200"
                            className="w-full h-40 object-cover rounded-md my-2"
                        />
                    </div>
                    <div className="text-gray-700">
                        <h3 className="subsection-module__title">{apartmentandbuilding.BuildingName}</h3>
                        <ul className="content-font">
                            <li className="flex justify-between pb-0.5 flex-col lg:flex-row mb-2 lg:mb-1">
                                <span className="flex-shrink-0 lg:mr-2 font-semibold">Room Type</span>
                                <span>{apartment.LivingArrangement}</span>
                            </li>
                            <li className="flex justify-between mb-2 lg:mb-1">
                                <span className="flex-shrink-0 lg:mr-2 font-semibold">Suburb</span>
                                <span>{apartmentandbuilding.Suburb}</span>
                            </li>
                        </ul>
                        <div className="divider my-3"></div>
                        <ul className="content-font">
                            <li className="flex items-center gap-x-6 justify-between md:justify-start">
                                <div>
                                    <p>Move-in</p>
                                    <p className="text-base font-semibold">8 Feb 2025</p>
                                </div>
                                <svg className="svg" fill="none" stroke="currentColor" strokeWidth="2.5" width="40" height="40" viewBox="0 0 24 24">
                                    <path d="M8.25 4.5l7.5 7.5-7.5 7.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                                <div className="text-right md:text-left">
                                    <p>Move-out</p>
                                    <p className="text-base font-semibold">30 Nov 2025</p>
                                </div>
                            </li>
                        </ul>
                        <div className="divider my-3"></div>
                        <ul className="mb-2">
                            {
                                fundingOption !== 'nsfas' ? (
                                    <li className="flex justify-between pb-0.5">
                                        <span className="content-font">Transport</span>
                                        <span >R{transportCost}</span>
                                    </li>
                                ) : (
                                    <li className="flex justify-between pb-0.5">
                                        <span className="content-font">Transport</span>
                                        <span className="strikethrough">R{250}</span>
                                    </li>
                                )
                            }

                            <li className="flex justify-between pb-0.5">
                                <span className="content-font">Reserve Fee</span>
                                <span>R{selectedPlan}</span>
                            </li>
                            <li className="flex justify-between">
                                <span className="font-semibold">Total</span>
                                <span className="text-theme-orange font-semibold"> R{fundingOption !== "nsfas" ? totalCost : selectedPlan}</span>

                            </li>
                        </ul>
                    </div>
                </div>
                <div className="full-page-content">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",


                        }}
                    >
                        <h1 className="subsection-module__title">Hi, let's complete your reservation form</h1>
                        <img
                            src={logo}
                            alt="Logo"
                            className="logo"
                            style={{
                                width: "100px", // Adjust width as needed
                                height: "auto", // Maintain aspect ratio
                            }}
                        />
                    </div>
                    <h2 className="subsection-module__title">Step {step}/5: </h2>

                    {/* Progress Bar */}
                    {/* <div className="progress-container">
                        {[1, 2, 3, 4, 5].map((bar) => (
                            <div key={bar} className={`progress-bar ${step >= bar ? 'filled' : ''}`} />

                        ))}
                    </div> */}
                    <div className="progress-container">
                        {(fundingOption === "nsfas" ? [1, 3, 4, 5] : [1, 2, 3, 4, 5]).map((bar) => (
                            <div key={bar} className={`progress-bar ${step >= bar ? "filled" : ""}`} />
                        ))}
                    </div>

                    {/* Step Content */}
                    {step === 1 && (
                        <div>

                            <label style={{ textAlign: 'left', display: 'block', fontSize: '20px' }}>
                                Personal Details <span style={{ color: 'red' }}>*</span>
                            </label>

                            <div className="amber-form-group2">
                                <div>

                                    <label className={fundingOption === "self-funded" ? "selected" : ""}>
                                        <input
                                            type="radio"
                                            name="fundingOption"
                                            value="self-funded"
                                            checked={fundingOption === "self-funded"}
                                        // onChange={handleFundingChange}
                                        />
                                        self-funded
                                    </label>

                                    <label className={fundingOption === "nsfas" ? "selected" : ""}>
                                        <input
                                            type="radio"
                                            name="fundingOption"
                                            value="nsfas"
                                            checked={fundingOption === "nsfas"}
                                        // onChange={handleFundingChange}
                                        />
                                        NSFAS
                                    </label>

                                    <label className={fundingOption === "bursary" ? "selected" : ""}>
                                        <input
                                            type="radio"
                                            name="fundingOption"
                                            value="bursary"
                                            checked={fundingOption === "bursary"}
                                        // onChange={handleFundingChange}
                                        />
                                        Other Bursaries
                                    </label>

                                </div>
                            </div>

                            {((fundingOption === "nsfas" &&
                                (isNullOrUndefined(apartment?.RoomDesignation) ||
                                    apartment?.RoomDesignation?.toLowerCase() !== "nsfas")) ||
                                ((fundingOption === "self-funded" || fundingOption === "bursary") &&
                                    apartment?.RoomDesignation?.toLowerCase() === "nsfas")) ? (
                                <div>
                                    <p
                                        style={{
                                            color: "red",
                                            fontSize: "16px",
                                            margin: "20px 0",
                                            textAlign: "center",
                                        }}
                                    >
                                        {fundingOption === "nsfas"
                                            ? "This room does not support NSFAS-funded students. Please choose another room."
                                            : "This room is designated for NSFAS-funded students only. Please choose another room."}
                                    </p>
                                    <button className="btn456" onClick={() => navigate(-1)}>
                                        Go Back
                                    </button>
                                </div>
                            ) : (
                                <div className="form-grid">

                                    {showLoginPrompt && (
                                        <p
                                            style={{
                                                color: "red",
                                                fontSize: "16px",
                                                margin: "auto",
                                                textAlign: "center",
                                            }}
                                        >
                                            You need to log in to proceed. Please log in to continue with your reservation.
                                        </p>
                                    )}

                                    {fundingOption === "self-funded" && (
                                        <>

                                            <div className="amber-form-group">
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Name <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Eg. Single , Married , Etc..." value={userData.Name} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Email Address <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter funders address" value={userData.Email} required />
                                                </div>

                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Institution <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Eg. Single , Married , Etc..." value={userData.InstituteName} required />
                                                </div>
                                            </div>
                                            <div className="amber-form-group">
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Surname <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter funders address" value={userData.Surname} required />
                                                </div>


                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Contact <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Eg. Single , Married , Etc..." value={userData.PhoneNumber} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Gender <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter funders address" value={userData.Gender} required />
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {fundingOption === "nsfas" && (
                                        <>
                                            <div className="amber-form-group">
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Name <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter NSFAS reference number" value={userData.Name} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Email Address <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter your email adress here." value={userData.Email} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Institution <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter your home address here." value={userData.InstituteName} required />
                                                </div>
                                            </div>
                                            <div className="amber-form-group">
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Surname <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter NSFAS reference number" value={userData.Surname} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Contact <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter your email adress here." value={userData.PhoneNumber} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Gender <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter your home address here." value={userData.Gender} required />
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {fundingOption === "bursary" && (
                                        <>
                                            <div className="amber-form-group">
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Bursary Name <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter bursary organization name" value={userData.BurseryOrganizationName} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Reference Number <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter bursary reference number" value={userData.BurseryReferenceNumber} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Bursary Type <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Full bursary , partial bursary , Etc..." value={userData.BurseryType} required />
                                                </div>
                                            </div>
                                            <div className="amber-form-group">
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Bursary Start Date <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter the date funding starts " value={userData.BurseryStartDate} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Bursary End Date <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter the end of your funding for the year. " value={userData.BurseryEndDate} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Coverage Details <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Accomodation, tution , food , transport Etc ... " value={userData.CoverageDetails} required />
                                                </div>
                                            </div>
                                            <div className="amber-form-group">
                                                {/* <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                <label>Bursary Contact/Administrator <span style={{ color: 'red' }}>*</span></label>
                                                <input type="text" placeholder="Enter phone number or email. " value={userData.Gender} required />
                                            </div> */}
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Institution <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter your institution. e.g DUT, MUT " value={userData.InstituteName} required />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {!isLoggedIn ? (<button className="btn456" onClick={() => setShowRegistrationModal(true)} >Login to proceed</button>
                                    ) : (<button className="btn456" onClick={nextStep} disabled={!fundingOption}>Next</button>
                                    )}
                                </div>
                            )}

                            {/* Dynamic input fields based on funding option */}

                        </div>
                    )}

                    {step === 2 && (
                        <div>
                            <label style={{ textAlign: 'left', display: 'block', fontSize: '20px' }}>
                                Transport <span style={{ color: 'red' }}>*</span>
                            </label>
                            <div className="transport-options" style={{ marginTop: '20px' }}>
                                <div
                                    className={`transport-option-wrapper ${transport === 'yes' ? 'selected' : ''}`}
                                    onClick={() => fundingOption !== 'nsfas' && setTransport('yes')}
                                >
                                    <input
                                        type="radio"
                                        name="transport"
                                        id="transportYes"
                                        value="yes"
                                        checked={transport === 'yes'}
                                        onChange={(e) => setTransport(e.target.value)}
                                        disabled={fundingOption === "nsfas"}
                                        className="radio-button"
                                    />
                                    <label htmlFor="transportYes" className="transport-label">
                                        <div className="transport-option">
                                            <p className="subsection-module__title">Yes, I need transport</p>
                                            <p className="subsection-module__subtitle">
                                                <span className="discount-highlight">SAVE 45%</span> R250 <span className="strikethrough">R450</span><br />
                                                Enjoy safe, reliable transport to and from your institution.<br />
                                                <span className="discount-highlight">Transport Deal ends 01 Feb 2025.</span>

                                            </p>
                                        </div>
                                    </label>
                                </div>
                                <div
                                    className={`transport-option-wrapper ${transport === 'no' ? 'selected' : ''}`}
                                    onClick={() => fundingOption !== "nsfas" && setTransport('no')}
                                >
                                    <input
                                        type="radio"
                                        name="transport"
                                        id="transportNo"
                                        value="no"
                                        checked={transport === 'no'}
                                        onChange={(e) => setTransport(e.target.value)}
                                        disabled={fundingOption === "nsfas"}
                                        className="radio-button"
                                    />
                                    <label htmlFor="transportNo" className="transport-label">
                                        <div className="transport-option">
                                            <p className="subsection-module__title">No, I do NOT need transport</p>
                                            <p className="subsection-module__subtitle">
                                                Choose this option if you have your own transport arrangements.
                                            </p>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="form-grid2">
                                <button className="btn456" onClick={prevStep}>Back</button>
                                <button className="btn456" onClick={nextStep} disabled={!transport}>Next</button>
                            </div>
                        </div>
                    )}

                    {step === 3 && (
                        <div>
                            <label style={{ textAlign: 'left', display: 'block', fontSize: '20px' }}>
                                Terms and Conditions <span style={{ color: 'red' }}>*</span>
                            </label>
                            <div className="booking-form scrollable">
                                <div className="step">Terms and conditions</div>
                                <p className="step">JAES STUDENT HOMES - GENERAL HOUSE RULES</p>
                                <p className="step">The following is the general house rules for all properties. Each property has specific added terms that are to be adhered to in addition to the following:</p>

                                <ul className="bullet-list">
                                    <li>&#8226; Communal areas should be kept clean at all times.</li>
                                    <li>&#8226; Gates and house entrances should be locked AT ALL TIMES for tenants’ safety.</li>
                                    <li>&#8226; Rooms should be kept locked when tenant is not on the premises...</li>
                                    <li>&#8226; Every tenant’s privacy should be respected...</li>
                                    <li>&#8226; No smoking permitted inside the premises...</li>
                                    <li>&#8226; No washing is to be hanged in the bathroom.</li>
                                    <li>&#8226; No more than 2 visitors per lessee allowed...</li>
                                    <li>&#8226; No visitors allowed before 10am and after 21:00 pm. NO SLEEPOVERS.</li>
                                    <li>&#8226; Down time after 21:30 pm.</li>
                                </ul>

                                <div className="step3" style={{ textAlign: 'left', display: 'block' }}>Fines (of up to R950) will be charged to tenant account for each rule break.</div>
                                <hr />

                                <label style={{ textAlign: 'left', display: 'block' }}>
                                    Check all boxes to accept terms and conditions <span style={{ color: 'red' }}>*</span>
                                </label>
                                <div style={{ textAlign: 'left', display: 'block' }}>
                                    By ticking the boxes below, you confirm that you have read, understood, and agreed to the Terms and Conditions, General House Rules, and Emergency Protocols outlined above. Please note, non-compliance with these rules may result in penalties and may prevent you from successfully booking or reserving a space.                                </div>
                                <div className="form-grid2">
                                    {CHECKBOX_OPTIONS.map(({ id, label, stateKey }) => (
                                        <div key={id} className="checkbox-wrapper" onClick={() => toggleCheckbox(stateKey)}>
                                            <input
                                                id={id}
                                                name={id}
                                                type="checkbox"
                                                checked={checkboxStates[stateKey]}
                                                onChange={() => toggleCheckbox(stateKey)}
                                            />
                                            <label className="terms-label" htmlFor={id}>
                                                <span className="label-text">{label}</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
                                                    <mask fill="white" id={`path-1-inside-1_476_5-${id}`}>
                                                        <rect height="200" width="200"></rect>
                                                    </mask>
                                                    <rect mask={`url(#path-1-inside-1_476_5-${id})`} strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
                                                    <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
                                                </svg>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <style>{`
  /* Container for checkboxes */
.checkbox-container {
display: inline, /* Align checkboxes side by side */
  flex-wrap: wrap; /* Allow wrapping to the next line if necessary */
  gap: 15px; /* Add spacing between checkboxes */
  justify-content: flex-start; /* Align items to the left */
  margin: 20px 0; /* Add spacing around the container */
}

.checkbox-wrapper {
  display: inline, /* Display each checkbox and its label inline */
  align-items: center; /* Align items vertically */
  gap: 10px; /* Add spacing between the checkbox and the label */
}

.checkbox-wrapper input[type="checkbox"] {
  display: none; /* Hide the default checkbox */
}

.checkbox-wrapper .terms-label {
  cursor: pointer;
  display: in-line;
  align-items: center;
}

.checkbox-wrapper .terms-label .label-text {
  margin-right: 10px; /* Add spacing after the text */
}

.checkbox-wrapper .checkbox-svg {
  width: 30px;
  height: 30px;
}

.checkbox-wrapper .checkbox-box {
  fill: rgba(207, 205, 205, 0.425);
  stroke: #ff5a5f;
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper .checkbox-tick {
  stroke: #ff5a5f;
  stroke-dasharray: 172;
  stroke-dashoffset: 172;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-box,
.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-tick {
  stroke-dashoffset: 0;
}


`}</style>
                            </div>
                            <div className="form-grid2">
                                <button className="btn456" onClick={prevStep}>Back</button>
                                <button
                                    className="btn456"
                                    onClick={nextStep}
                                    disabled={!Object.values(checkboxStates).every((checked) => checked)}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    )}
                    {step === 4 && (

                        <section>
                            <label style={{ textAlign: 'left', display: 'block', fontSize: '20px' }}>
                                Reservation Fee Plan <span style={{ color: 'red' }}>*</span>
                            </label>
                            <div className="amber-form-grid">
                                <div className="pricingTable">
                                    {/* Early Entry Card */}
                                    <div
                                        className="col-sm-4"
                                        onClick={() => (fundingOption === "self-funded" || fundingOption === "nsfas") &&
                                            handleCardClick("900")}
                                        style={{
                                            cursor:
                                                fundingOption === "bursary" ? "not-allowed" : "pointer", // Disable for Bursary
                                            opacity: fundingOption === "bursary" ? 0.5 : 1, // Dim for Bursary
                                        }}
                                    >
                                        <div className={`card text-center ${selectedPlan === '900' ? 'selected' : ''}`}>
                                            <div class="pricingTable-firstTable_table">
                                                <h1 class="pricingTable-firstTable_table__header">Deal ends 15 Dec</h1>
                                                <p class="pricingTable-firstTable_table__pricing"><span>R</span><span>900</span></p>
                                                <ul class="pricingTable-firstTable_table__options">

                                                    <li class="pricingTable-firstTable_table__header"><i>
                                                    </i > Reserve room</li>
                                                    <li class="pricingTable-firstTable_table__header"><i>
                                                    </i> Partial Fee</li>
                                                </ul>
                                                <button class="pricingTable-firstTable_table__getstart ">Reserve room</button>
                                            </div>
                                            {/* <div className="pricingTable-firstTable_table">
                                                <i className="fa fa-handshake-o" aria-hidden="true"></i>
                                                <h2>Reserve Early</h2>
                                            </div>
                                            <div className="price">
                                                <h4><sup>R</sup>900</h4>
                                            </div>
                                            <div className="option">
                                                <ul>
                                                    <li><i className="fa fa-check" aria-hidden="true"></i> Pay R900 now</li>
                                                    <li><i className="fa fa-check" aria-hidden="true"></i> Secure room</li>
                                                    <li><i className="fa fa-check" aria-hidden="true"></i> Deal ends 15 Dec</li>
                                                    { <li><i className="fa fa-times" aria-hidden="true"></i> Live Support</li> 
                                                </ul>
                                            </div> */}

                                        </div>
                                    </div>

                                    {/* Standard Entry Card */}
                                    <div
                                        className="col-sm-4"
                                        onClick={() => fundingOption === "self-funded" && handleCardClick("1200")}
                                        style={{
                                            cursor: fundingOption !== "self-funded" ? "not-allowed" : "pointer", // Disable for NSFAS and Bursary
                                            opacity: fundingOption !== "self-funded" ? 0.5 : 1, // Dim for NSFAS and Bursary
                                        }}
                                    >
                                        <div className={`card text-center ${selectedPlan === '1200' ? 'selected' : ''}`}>
                                            {/* <div className="title">
                                                <i className="fa fa-home" aria-hidden="true"></i>
                                                <h2>Standard Reserve</h2>
                                            </div>
                                            <div className="price">
                                                <h4><sup>R</sup>1200</h4>
                                            </div>
                                            <div className="option">
                                                <ul>
                                                    <li><i className="fa fa-check" aria-hidden="true"></i> Pay R1200 now</li>
                                                    <li><i className="fa fa-check" aria-hidden="true"></i> Secure room</li>
                                                    <li><i className="fa fa-check" aria-hidden="true"></i> Deal ends 15 Jan</li>
                                                     <li><i className="fa fa-times" aria-hidden="true"></i> Live Support</li> 
                                                </ul>
                                            </div> */}
                                            <div class="pricingTable-firstTable_table">
                                                <h1 class="pricingTable-firstTable_table__header"> Deal ends 15 Jan</h1>
                                                <p class="pricingTable-firstTable_table__pricing"><span>R</span><span>1200</span></p>
                                                <ul class="pricingTable-firstTable_table__options">

                                                    <li class="pricingTable-firstTable_table__header"><i></i>Reserve room</li>
                                                    <li class="pricingTable-firstTable_table__header"><i></i>Partial Fee</li>
                                                </ul>
                                                <button class="pricingTable-firstTable_table__getstart">Reserve room</button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Late Entry Card */}
                                    <div
                                        className="col-sm-4"
                                        onClick={() => (fundingOption === "self-funded" || fundingOption === "bursary") &&
                                            handleCardClick("1850")}
                                        style={{
                                            cursor: fundingOption === "nsfas" ? "not-allowed" : "pointer",
                                            opacity: fundingOption === "nsfas" ? 0.5 : 1, // Dimmed for NSFAS
                                        }}
                                    >
                                        <div className={`card text-center ${selectedPlan === '1850' ? 'selected' : ''}`}>
                                            {/* <div className="title">
                                                <i className="fa fa-bed" aria-hidden="true"></i>
                                                <h2>Reserve Now</h2>
                                            </div>
                                            <div className="price">
                                                <h4><sup>R</sup>1850</h4>
                                            </div>
                                            <div className="option">
                                                <ul>
                                                    <li><i className="fa fa-check" aria-hidden="true"></i> Pay R1850 now</li>
                                                    <li><i className="fa fa-check" aria-hidden="true"></i> Secure room</li>
                                                    <li><i className="fa fa-check" aria-hidden="true"></i> Reserve closes 15 Feb</li>
                                                     <li><i className="fa fa-times" aria-hidden="true"></i> Live Support</li> 
                                                </ul>
                                            </div> */}
                                            <div class="pricingTable-firstTable_table">
                                                <h1 class="pricingTable-firstTable_table__header"> Deal ends 15 Feb</h1>
                                                <p class="pricingTable-firstTable_table__pricing"><span>R</span><span>1850</span></p>
                                                <ul class="pricingTable-firstTable_table__options">

                                                    <li class="pricingTable-firstTable_table__header"><i></i> Reserve room</li>
                                                    <li class="pricingTable-firstTable_table__header"><i></i> Standard Fee </li>
                                                </ul>
                                                <button class="pricingTable-firstTable_table__getstart">Reserve room</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="form-grid2">
                                    <button className="btn456" onClick={() => setStep(3)}>Back</button>
                                    <button className="btn456" onClick={() => setStep(5)} disabled={!selectedPlan}>
                                        Next
                                    </button>
                                </div>
                            </div>
                        </section>

                    )}
                    {step === 5 && (
                        <div>
                            <label style={{ textAlign: 'left', display: 'block', fontSize: '20px', marginBottom: '25px' }}>
                                Submit to reserve for 2025 <span style={{ color: 'red' }}>*</span>
                            </label>
                            {/* <p className="amber-form-group2">
                                Click "Submit" to complete your reservation, and proceed to pay.                            </p> */}
                            <div className="contentinvoice">

                                <div className="amber-form-grid">
                                    <div className="form-grid">
                                        <Bus size={20} />
                                        <p className="subsection-module__title">
                                            <strong>Effortless Commuting:</strong>
                                        </p>
                                        <p className="subsection-module__subtitle">
                                            Reliable daily transport to get you where you need to be, on time, every time.
                                        </p>
                                    </div>

                                    <div className="form-grid">
                                        <Star size={20} />
                                        <p className="subsection-module__title">
                                            <strong>Prime Campus Location:</strong>
                                        </p>
                                        <p className="subsection-module__subtitle">
                                            Immerse yourself in a vibrant neighborhood, just steps away from your academic pursuits.
                                        </p>
                                    </div>

                                    <div className="form-grid">
                                        <PhoneForwarded size={20} />
                                        <p className="subsection-module__title">
                                            <strong>Round-the-Clock Support: </strong>
                                        </p>
                                        <p className="subsection-module__subtitle">
                                            Our dedicated team is always ready to assist, ensuring a smooth and stress-free stay.
                                        </p>
                                    </div>

                                    <div className="form-grid">
                                        <MapPinned size={20} />
                                        <p className="subsection-module__title">
                                            <strong>Privacy and Peace: </strong>
                                        </p>
                                        <p className="subsection-module__subtitle">
                                            Your own personal space, designed for relaxation and focus.
                                        </p>
                                    </div>
                                </div>


                                <div className="rounded-lg lg:shadow-lg lg:p-3 lg:border border-gray-100 booking-summary">
                                    <div>
                                        <div className="flex">
                                            <p className="content-font uppercase font-semibold text-gray-500">reservation Summary</p>
                                        </div>
                                    </div>
                                    <div className="text-gray-700">
                                        <h3 className="subsection-module__title">{apartmentandbuilding.BuildingName} - {apartmentandbuilding.ApartmentNumber}</h3>

                                        <div className="divider my-3"></div>
                                        {fundingOption == 'nsfas' && (
                                            <span style={{
                                                color: "red",
                                                fontSize: "12px",
                                                marginTop: '10px',
                                                textAlign: "left",
                                                marginLeft: '-18px'
                                            }} >Reservation fee refundable</span>
                                        )}

                                        <ul className="mb-2">
                                            {fundingOption !== 'nsfas' && (
                                                <li className="flex justify-between pb-0.5">
                                                    <span className="content-font">Transport :</span>
                                                    <span>R{transportCost}</span>
                                                </li>
                                            )}

                                            <li className="flex justify-between pb-0.5">
                                                <span className="content-font">Reserve Fee :</span>
                                                <span>R{selectedPlan}</span>
                                            </li>
                                            <div className="divider my-3"></div>

                                            <li className="flex justify-between">
                                                <span className="font-semibold">Total Due:</span>
                                                <span className="text-theme-orange font-semibold">R{totalCost}</span>

                                            </li>
                                            <div className="form-grid2">
                                                <button className="btn456" onClick={prevStep}>Back</button>
                                                <button className="btn456" onClick={() => handlePaymentCurrent()}>Submit</button>
                                            </div>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )}


                </div>
                {/* Render the registration modal */}
                {showRegistrationModal && (
                    <RegistrationModal
                        // Pass down clickedAction
                        clickedAction={clickedAction}
                        onSuccess={handleRegistrationSuccess}
                        onClose={() => {
                            setShowRegistrationModal(false);
                            if (!isLoggedIn) {
                                setShowLoginPrompt(true); // Show login prompt if user cancels without logging in
                            }
                        }}
                        date={date}
                        apartment={apartment}
                        apartmentandbuilding={apartmentandbuilding}
                        totalCost={totalCost}
                        serviceFee={serviceFee}
                        chosenDefault={chosenDefault}
                        nextPaymentDate={nextPaymentDate}

                    />

                )}
            </div >


        </>
    );
}

export default NewReserve;



