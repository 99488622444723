
import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider'; // Assuming you have AuthProvider

function Payment() {
    const navigate = useNavigate();
    const location = useLocation();
    const paymentUpdatedRef = useRef(false); // Ref to track if payment has been updated
    const { userId } = useAuth(); // Get userId from AuthContext

    // Extract the query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    const date = queryParams.get('date');
    const apartmentId = queryParams.get('apartment');
    const RentAmount = queryParams.get('totalCost');
    const chosenDefault = queryParams.get('chosenDefault');
    const serviceFee = queryParams.get('serviceFee');
    // const apartmentNumber = queryParams.get('apartmentnumber');
    // const apartmentType = queryParams.get('apartmenttype');
    // const apartmentPreferedGender = queryParams.get('apartmentpreferedgender');
    // const apartmentDeposit = queryParams.get('apartmentdeposit');
    // const apartmentRating = queryParams.get('apartmentrating');
    // const bedRoomStatus = queryParams.get('bedroomstatus');
    // const bedRoomCount = queryParams.get('bedroomcount');
    // const dateParam = queryParams.get('date'); // If the date is being passed like this
    // const apartmentBathroomImage = queryParams.get('apartmentbathroomimage');
    // const apartmentRoomImage = queryParams.get('apartmentroomimage');
    // const apartmentSize = queryParams.get('apartmentsize');
    // const totalNumOfBeds = queryParams.get('totalnumofbeds');
    // const priceB = queryParams.get('priceb');
    // const createdDate = queryParams.get('createddate');
    // const createdBy = queryParams.get('createdby');
    // const capacity2 = queryParams.get('capacity2');
    // const roomIdentifier = queryParams.get('roomidentifier');
    // const nextPaymentDate = queryParams.get('nextPaymentDate');
    const ApartmentPrice = queryParams.get('ApartmentPrice');

    useEffect(() => {
        // Ensure all necessary data is available before proceeding
        if (!userId || !apartmentId || !date || !RentAmount || !chosenDefault ) {
            console.error('Missing necessary data for payment processing', {
                userId,
                date,
                apartmentId,
                RentAmount,
                serviceFee,
                chosenDefault,
                // apartmentNumber,
                // apartmentType,
                // apartmentPreferedGender,
                // apartmentDeposit,
                // apartmentRating,
                // bedRoomStatus,
                // bedRoomCount,
                // apartmentBathroomImage,
                // apartmentRoomImage,
                // apartmentSize,
                // totalNumOfBeds,
                // priceB,
                // createdDate,
                // createdBy,
                // capacity2,
                // roomIdentifier
            });
            return;
        }

        // Ensure the API call happens only once
        if (!paymentUpdatedRef.current) {
            paymentUpdatedRef.current = true; // Set ref to true to prevent multiple calls
            handlePaymentSuccess();
        }
    }, [userId, apartmentId, date, RentAmount, chosenDefault, serviceFee]);

    const handlePaymentSuccess = async () => {
        try {
            // Step 1: Associate user with apartment
            await associateUserWithApartment();


            // Step 3: Fetch ApartmentPrice and calculate daily rate
            const apartmentData = await fetchApartmentData();
            const apartmentPrice = ApartmentPrice;

            // Calculate daily rate
            const paymentDate = new Date(date);
            const year = paymentDate.getFullYear();
            const month = paymentDate.getMonth(); // 0-based month
            const daysInMonth = new Date(year, month + 1, 0).getDate();
            const dailyRate = apartmentPrice / daysInMonth;

            // DaysPaidFor: daysInMonth (assuming full month paid)
            const daysPaidFor = daysInMonth;

            // Step 4: Save to wallet
            await saveToWallet(apartmentPrice, dailyRate, daysPaidFor);


            // Step 6: Mark apartment as occupied
            await markApartmentAsOccupied();

            // Redirect the user to the homepage after success
            setTimeout(() => {
                navigate('/UserDashboard'); // Or replace with the correct redirect route
            }, 5000); // Redirect after 5 seconds
        } catch (error) {
            console.error('Error processing payment success:', error);
            alert(`Error processing your payment: ${error.message}`);
        }
    };

    const handleReturnHome = () => {
        navigate('/'); // This redirects the user to the homepage
    };

    const associateUserWithApartment = async () => {
        try {
            const moveInDate = new Date(date);
            const moveOutDate = new Date(moveInDate); // Clone the move-in date
            moveOutDate.setMonth(moveOutDate.getMonth() + 1);

            // Format the moveOutDate to the required format
            const formattedMoveOutDate = moveOutDate.toLocaleString('en-US', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true,
            });

            const response = await fetch('https://backendjaes.passiontrials.co.za/user_apartment_tb', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    UserId: userId,
                    ApartmentId: apartmentId,
                    MoveInDate: moveInDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
                    MoveOutDate: formattedMoveOutDate, // Use the formatted date
                    RentAmount: RentAmount,
                    LeaseTerm: '1 Month',
                    IsActive: true,
                    DepositPaid: true,
                    ApartmentRating: 5,
                    Guest: 1, // Default guest number
                    Notes: 'No specific notes',
                    TenantAgreementType: 'Month-to-Month',
                    ChosenPlan: chosenDefault,
                    ServiceFee: 0,
                    RentCost: RentAmount,
                    PayableRent: RentAmount
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to associate user with apartment: ${errorText}`);
            }

            console.log('User associated with apartment successfully!');
        } catch (error) {
            throw new Error(`Error associating user with apartment: ${error.message}`);
        }
    };

   

    const fetchApartmentData = async () => {
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/apartment_tb/${apartmentId}`);
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to fetch apartment data: ${errorText}`);
            }
            const apartmentData = await response.json();
            console.log('Fetched Apartment Data:', apartmentData); // For debugging
            return apartmentData;
        } catch (error) {
            throw new Error(`Error fetching apartment data: ${error.message}`);
        }
    };


    const saveToWallet = async (apartmentPrice, dailyRate, daysPaidFor) => {
        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/wallet', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    UserId: userId,
                    ApartmentId: apartmentId,
                    PaymentDate: new Date(date).toISOString().split('T')[0], // Format as 'YYYY-MM-DD'
                    AmountPaid: RentAmount,
                    DailyRate: dailyRate.toFixed(2),
                    DaysPaidFor: daysPaidFor,
                    MonthYear: new Date(date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' }),
                    PaymentMethod: "Yoco"
                })
            });
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to save to wallet: ${errorText}`);
            }
            console.log('Wallet record saved successfully!');
        } catch (error) {
            throw new Error(`Error saving to wallet: ${error.message}`);
        }
    };

 

    const markApartmentAsOccupied = async () => {
        try {
            // Ensure apartmentId exists before proceeding
            if (apartmentId) {
                // Decrement the Capacity2 in the apartment_tb table
                const decrementCapacity = await fetch(`https://backendjaes.passiontrials.co.za/decrement-capacity/${apartmentId}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' }
                });

                if (!decrementCapacity.ok) {
                    const errorText = await decrementCapacity.text();
                    throw new Error(`Failed to decrement apartment capacity: ${errorText}`);
                }

                console.log('Apartment capacity has been decremented successfully');
            } else {
                console.log('No apartment or room found');
            }
        } catch (error) {
            throw new Error(`Error marking apartment as occupied: ${error.message}`);
        }
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '150px' }}>
            <h1>Payment Success</h1>
            <p>Your payment has been processed successfully!</p>
            <p>Thank you for your transaction.</p>
            <button
                onClick={handleReturnHome}
                style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer', backgroundColor: '#ff5a5f', color: 'white', border: 'none', borderRadius: '5px' }}>
                Return Home
            </button>
        </div>
    );
}

export default Payment;
