import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import { useAuth } from './AuthProvider'; // Assuming you have AuthProvider


function Step3_1Admin() {
    const navigate = useNavigate(); // Initialize the navigate function
    const location = useLocation();
    const { userId } = useAuth(); // Get userId from AuthContext
    const [adminEmail, setAdminEmail] = useState(""); // State to store admin email

   // Fetch user data by userId
       // Fetch user data by userId
       useEffect(() => {
        const fetchAdminEmail = async () => {
            try {
                const response = await fetch(`https://backendjaes.passiontrials.co.za/admin_email/${userId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch admin email');
                }
                const data = await response.json();
                if (data && data.AdminEmail) {
                    setAdminEmail(data.AdminEmail); // Set admin email in state
                    setFormData((prevData) => ({
                        ...prevData,
                        OwnerEmail: data.AdminEmail, // Assign fetched email to OwnerEmail in formData
                    }));
                    console.log('Fetched admin email:', data.AdminEmail); // Log the email
                }
            } catch (error) {
                console.error('Error fetching admin email:', error);
            }
        };

        if (userId) {
            fetchAdminEmail(); // Fetch admin email if userId exists
        }
    }, [userId]);  
    const { typeOfHouse, typeOfPlace } = location.state || {};
    const handleNext = () => {
        if (adminbuildings.length > 0) {
            navigate("/Steps3_2Admin", { state: { typeOfHouse, typeOfPlace } }); // Proceed if adminbuildings exist
        } else {
            alert("Please add a building before proceeding.");
        }
    };
    
    const handleBack = () => {
      navigate(-1); // Navigate back to the previous page
    };
    const [imagePreviews, setImagePreviews] = useState({
        BuildingInteriorImage1: "",
        BuildingInteriorImage2: "",
        BuildingInteriorImage3: "",
        BuildingExteriorImage4: "",
        BuildingExteriorImage5: "",
        BuildingExteriorImage6: "",
    });
    const [formData, setFormData] = useState({
        BuildingName: "",
        BuildingLocation: "",
        BuildingTotalRooms: 10,
        BuildingInteriorImage1: "",
        BuildingInteriorImage2: "",
        BuildingInteriorImage3: "",
        BuildingExteriorImage4: "",
        BuildingExteriorImage5: "",
        BuildingExteriorImage6: "",
        BuildingManager: "",
        BuildingCity: "",
        BuildingDescript: "",
        CreatedBy: "",
        CreatedDate: "",
        OwnerEmail: "",
        Zone: "",
        ZoneName: "",
        Setup: [],
        Best_Price: 0,
        University: "",
        Capacity: "",
        SessionID: "",
    });
    const [adminbuildings, setadminbuildings] = useState([]);
    const [selectedSetups, setSelectedSetups] = useState([]);
    const sessionID = localStorage.getItem("sessionID") || Date.now();
    localStorage.setItem("sessionID", sessionID);

    useEffect(() => { // 11 nov 2024
        fetchAlladminbuildings();
    }, []);
    const fetchAlladminbuildings = async () => {
        if (!adminEmail) return; // Ensure adminEmail is set before fetching
        try {
            const response = await fetch(
                `https://backendjaes.passiontrials.co.za/new_building_tb_for_listing?ownerEmail=${encodeURIComponent(adminEmail)}`
            );
            const data = await response.json();
            setadminbuildings(data);
        } catch (error) {
            console.error("Error fetching adminbuildings by admin email:", error);
        }
    };
    
    // Trigger fetchAlladminbuildings after adminEmail is set
    useEffect(() => {
        if (adminEmail) {
            fetchAlladminbuildings();
        }
    }, [adminEmail]);
    
    const handleRoomTypeSelect = (roomType) => {
        setSelectedSetups((prevSelectedSetups) => {
            if (prevSelectedSetups.includes(roomType)) {
                console.log("the types", `${roomType} `);
                // If room type is already selected, deselect it
                return prevSelectedSetups.filter((type) => type !== roomType);
            } else {
                // Otherwise, add the room type to the selected list
                console.log("the types", `${roomType} `);
                return [...prevSelectedSetups, roomType];
            }
        });
        console.log("the types", `${roomType} `);
        setFormData((prevData) => ({
            ...prevData,
            Setup: selectedSetups.includes(roomType)
                ? selectedSetups.filter((type) => type !== roomType)
                : [...selectedSetups, roomType]
        }));
    };
    const handleBuildingChange = (e) => {
        const { name, value } = e.target;

        // Check if the user is changing the ZoneName field
        if (name === 'ZoneName') {
            let zone = '';
            switch (value) {
                case 'Westridge':
                case 'Manor Gardens Area':
                    zone = 1;
                    break;
                case 'Glenmore':
                case 'Carrington Heights Area':
                    zone = 4;
                    break;
                case 'Umbilo':
                case 'Lower Glenwood Area':
                    zone = 3;
                    break;
                case 'Glenwood':
                    zone = 2;
                    break;
                default:
                    zone = ''; // Set empty if no match
            }

            setFormData((prevState) => ({
                ...prevState,
                ZoneName: value,
                Zone: zone
            }));
        } else {
            // Handle other changes
            setFormData((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }

    };
    const handleBuildingImageChange = (e, fieldName) => {
        const file = e.target.files[0];
        setImagePreviews((prev) => ({ ...prev, [fieldName]: URL.createObjectURL(file) }));
        setFormData((prev) => ({ ...prev, [fieldName]: file }));


        ////////////////////////////////


        // if (file) {
        //     const previewUrl = URL.createObjectURL(file); // Create a URL for the image preview
        //     setImagePreviews(prev => ({
        //         ...prev,
        //         [imageKey]: previewUrl // Update the preview state with the new image URL
        //     }));

        //     // Store the file in the formData for backend submission
        //     setFormData(prev => ({
        //         ...prev,
        //         [imageKey]: file
        //     }));
        // }
    };
    const roomTypesWithVariations = {
        "Single": ["Standard", "Ensuite", "Kitchenette", "Flatlet", "Deluxe"],
        "Double": ["Standard", "Ensuite", "Kitchenette", "Flatlet", "Deluxe"],
        "Twin": ["Standard", "Ensuite", "Kitchenette", "Flatlet", "Deluxe"],
        "Bachelor": ["Flatlet", "Deluxe"]

    }
    const handleadminbuildingsubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formData.BuildingManager = "not specified";
        formData.CreatedBy = "not specified";
        formData.OwnerEmail = adminEmail;
        formData.SessionID = sessionID;
        Object.keys(formData).forEach((key) => {
            formDataToSend.append(key, formData[key]);
        });

        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/new_building_tb', {
                method: "POST",
                body: formDataToSend,
            });
            if (response.ok) {
                fetchAlladminbuildings();
                alert("Building added successfully!");
                setFormData({
                    BuildingName: "",
                    BuildingLocation: "",
                    BuildingTotalRooms: "",
                    BuildingInteriorImage1: "",
                    BuildingInteriorImage2: "",
                    BuildingInteriorImage3: "",
                    BuildingExteriorImage4: "",
                    BuildingExteriorImage5: "",
                    BuildingExteriorImage6: "",
                    BuildingManager: "",
                    BuildingCity: "",
                    BuildingDescript: "",
                    CreatedBy: "",
                    CreatedDate: "",
                    OwnerEmail: "",
                    Zone: "",
                    Capacity: "",
                    PreferedGender: "",
                    Setup: "",
                    ZoneName: "",
                    Best_Price: "",
                });
                setImagePreviews({});
            } else {
                console.error("Failed to add building");
            }
        } catch (error) {
            console.error("Error saving building:", error);
        }
    };

    return (
        <div className="apollo-container"  style={{backgroundColor: 'white', marginBottom:'520px'}}>
             <style>{`
        .container {
          max-width: 600px;
          margin: 10px auto;
          padding: 20px;
          text-align: center;
        }
        .container h3 {
          font-size: 24px;
          margin-bottom: 20px;
          color: #242323;
          text-align: left;
        }
        .options-container {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
        .option {
          border: 1px solid #ddd;
          border-radius: 8px;
          padding: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          transition: border-color 0.3s;
        }
        .option:hover {
          border: 2px solid #000;
        }
        .option.selected {
          border: 2px solid #000; /* Highlight color for selected option */
        }
        .option img {
          max-width: 30px;
          margin-left: 15px;
        }
        .option .text {
          text-align: left;
          flex-grow: 1;
        }
        .option .text h4 {
          font-size: 18px;
          margin: 0;
          color: #333;
        }
        .option .text p {
          font-size: 12px;
          color: #555;
          margin: 5px 0 0;
        }
             /* Responsive Styles for Mobile */
        @media (max-width: 600px) {
          .option {
            width: 100%; /* Decrease width on small screens */
          }
          .container h3 {
            font-size: 20px;
          }
          .option img {
            max-width: 25px;
          }
        }

         .progress-step1cont {
  position: fixed; /* Fix the container at a specific position */
  bottom: 0; /* Place it at the bottom of the viewport */
  left: 0;
  right: 0;
  width: 100%; /* Make sure it spans the full width */
  max-width: 1200px;
  margin: 0 auto; /* Center it horizontally */
  padding: 0 10px;
  box-sizing: border-box;
  background: #fff; /* Add a background to prevent overlap issues */
  z-index: 1000; /* Ensure it stays above other elements */
}

        .progress-bar {
          height: 4px;
          background: #e0e0e0;
          margin-bottom: 5px;
          position: relative;
        }
        .progress {
          height: 100%;
          width: 50%;
          background: #000;
          transition: width 0.3s ease-in-out;
        }
        .buttons-step1cont {
          display: flex;
          justify-content: space-between; /* Space between buttons */
          align-items: center;
          width: 100%;
          margin-top: 10px; /* Add some space above buttons */
        }
        .back-button {
          text-decoration: underline;
          color: #000;
          background: none;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 12px; /* Reduced font size */
          padding: 15px 0px; /* Smaller padding */
        }
        .next-button {
          background: #000;
          max-width: 50px;
          color: #fff;
          border: none;
          padding: 5px 10px; /* Smaller padding */
          border-radius: 4px;
          font-size: 12px; /* Reduced font size */
          cursor: pointer;
        }        
@media (max-width: 768px) {
  .buttons-step1cont {
    justify-content: flex-start; /* Align all buttons to the left */
  }
  .next-button {
    margin-left: 150px; /* Add some spacing from the left edge */
  }
}
      `}</style>
            <h1>Add Building</h1>
            <form onSubmit={handleadminbuildingsubmit} className="apollo-form">
                {/* <input type="text" name="BuildingName" value={formData.BuildingName} onChange={handleBuildingChange} placeholder="Building Name" required />
                <input type="text" name="BuildingLocation" value={formData.BuildingLocation} onChange={handleBuildingChange} placeholder="Building Location" required />
                <input type="number" name="TotalRooms" value={formData.TotalRooms} onChange={handleBuildingChange} placeholder="Total Rooms" required />



                <input type="file" onChange={(e) => handleBuildingImageChange(e, "BuildingInteriorImage1")} /> */}

<div className="apollo-form-group">
    <div className="apollo-input-container">
        <label htmlFor="BuildingName" className="apollo-label">Building Name</label>
        <input
            type="text"
            id="BuildingName"
            name="BuildingName"
            value={formData.BuildingName}
            onChange={handleBuildingChange}
            placeholder="Enter Building Name"
            className="apollo-input"
            required
        />
    </div>

    <div className="apollo-input-container">
        <label htmlFor="BuildingLocation" className="apollo-label">Building Location</label>
        <input
            type="text"
            id="BuildingLocation"
            name="BuildingLocation"
            value={formData.BuildingLocation}
            onChange={handleBuildingChange}
            placeholder="Enter Building Location"
            className="apollo-input"
            required
        />
    </div>
</div>

<style>{`
    .apollo-form-group {
        display: flex;
        gap: 10px; /* Adds space between the grouped inputs */
        justify-content: space-between; /* Distribute inputs with space in between */
        
    }

    .apollo-input-container {
        flex: 1; /* Make the inputs evenly distributed */
        display: flex;
        flex-direction: column;
    }

    .apollo-label {
        font-size: 14px;
        font-weight: bold;
        color: #333;
        text-align: left;
    }

    .apollo-input {
        padding: 10px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 100%;
    }@media (max-width: 768px) {
    .apollo-form-group {
        flex-direction: column; /* Stack inputs on smaller screens */
    }
}

`}</style>

<div className="apollo-form-group">
    <div className="apollo-input-container">
        <label className="apollo-label" htmlFor="BuildingTotalRooms">Total Rooms</label>
        <input
    type="number"
    id="BuildingTotalRooms"
            name="BuildingTotalRooms"
            value={formData.BuildingTotalRooms}
            onChange={handleBuildingChange}
            placeholder="Total Rooms"
            className="apollo-input"
            required
        />
    </div>

    <div className="apollo-input-container">
        <label className="apollo-label" htmlFor="Capacity">Capacity</label>
        <input
            type="number"
            id="Capacity"
            name="Capacity"
            value={formData.Capacity}
            onChange={handleBuildingChange}
            placeholder="Capacity"
            className="apollo-input"
            required
        />
    </div>
</div>

                <label className="apollo-label">Building Description</label>
                <input
                    type="text"
                    name="BuildingDescript"
                    value={formData.BuildingDescript}
                    onChange={handleBuildingChange}
                    placeholder="Building Description"
                    className="apollo-input"
                    required
                />
               

               <div className="apollo-form-group">
    <div className="apollo-input-container">
        <label className="apollo-label" htmlFor="Best_Price">Your cheapest room</label>
        <input
            type="number"
            id="Best_Price"
            name="Best_Price"
            value={formData.Best_Price}
            onChange={handleBuildingChange}
            placeholder="Best Price"
            className="apollo-input"
            required
        />
    </div>

    <div className="apollo-input-container">
        <label className="apollo-label" htmlFor="BuildingCity">City</label>
        <select
            id="BuildingCity"
            name="BuildingCity"
            value={formData.BuildingCity}
            onChange={handleBuildingChange}
            className="apollo-select"
            required
        >
            <option value="">Select City</option>
            <option value="Durban">Durban</option>
            <option value="Pietermaritzburg">Pietermaritzburg</option>
            <option value="Johannesburg">Johannesburg</option>
            <option value="Cape Town">Cape Town</option>
            <option value="Pretoria">Pretoria</option>
        </select>
    </div>
</div>

<div className="apollo-form-group">
    <div className="apollo-input-container">
        <label className="apollo-label" htmlFor="PreferedGender">Preferred Gender</label>
        <select
            id="PreferedGender"
            name="PreferedGender"
            value={formData.PreferedGender}
            onChange={handleBuildingChange}
            className="apollo-select"
            required
        >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Male and Female">Male and Female</option>
        </select>
    </div>

    <div className="apollo-input-container">
        <label className="apollo-label" htmlFor="ZoneName">Zone Area</label>
        <select
            id="ZoneName"
            name="ZoneName"
            value={formData.ZoneName}
            onChange={handleBuildingChange}
            className="apollo-select"
            required
        >
            <option value="">Select Zone</option>
            <option value="Westridge">Westridge</option>
            <option value="Manor Gardens Area">Manor Gardens Area</option>
            <option value="Glenmore">Glenmore</option>
            <option value="Carrington Heights Area">Carrington Heights Area</option>
            <option value="Umbilo">Umbilo</option>
            <option value="Glenwood Area">Glenwood Area</option>
            <option value="Lower Glenwood Area">Lower Glenwood Area</option>
        </select>
    </div>
</div>


                <label className="apollo-label apollo-label-center">Types of Apartments Offered (Room Types)</label>
                <div className="apollo-checkbox-group">
                    {Object.keys(roomTypesWithVariations).map((roomType) => (
                        <div key={roomType}>
                            {/* Main room type label */}
                            <label
                                onClick={() => handleRoomTypeSelect(roomType)}
                                className={`apollo-checkbox ${selectedSetups.includes(roomType) ? 'selected' : 'deselected'}`}

                            >
                                {roomType}
                            </label>
                        </div>
                    ))}
                </div>

                <div className="apollo-form-group">
                    <div className="apollo-input-wrapper">
                        <label className="apollo-label">Building Interior Image 1:</label>
                        <input
                            type="file"
                            name="BuildingInteriorImage1"
                            onChange={(e) => handleBuildingImageChange(e, 'BuildingInteriorImage1')}
                            className="apollo-file-input"
                        />
                    </div>
                    <div className="apollo-input-wrapper">
                        <label className="apollo-label">Building Interior Image 2:</label>
                        <input
                            type="file"
                            name="BuildingInteriorImage2"
                            onChange={(e) => handleBuildingImageChange(e, 'BuildingInteriorImage2')}
                            className="apollo-file-input"
                        />
                    </div>
                    <div className="apollo-input-wrapper">
                        <label className="apollo-label">Building Interior Image 3:</label>
                        <input
                            type="file"
                            name="BuildingInteriorImage3"
                            onChange={(e) => handleBuildingImageChange(e, 'BuildingInteriorImage3')}
                            className="apollo-file-input"
                        />
                    </div>
                </div>

                <div className="apollo-form-group">
                    <div className="apollo-input-wrapper">
                        <label className="apollo-label">Building Exterior Image 4:</label>
                        <input
                            type="file"
                            name="BuildingExteriorImage4"
                            onChange={(e) => handleBuildingImageChange(e, 'BuildingExteriorImage4')}
                            className="apollo-file-input"
                        />
                    </div>
                    <div className="apollo-input-wrapper">
                        <label className="apollo-label">Building Exterior Image 5:</label>
                        <input
                            type="file"
                            name="BuildingExteriorImage5"
                            onChange={(e) => handleBuildingImageChange(e, 'BuildingExteriorImage5')}
                            className="apollo-file-input"
                        />
                    </div>
                    <div className="apollo-input-wrapper">
                        <label className="apollo-label">Building Exterior Image 6:</label>
                        <input
                            type="file"
                            name="BuildingExteriorImage6"
                            onChange={(e) => handleBuildingImageChange(e, 'BuildingExteriorImage6')}
                            className="apollo-file-input"
                        />
                    </div>
                </div>

                <button type="submit">Add Building</button>
            </form>

            <div>
            <>
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px' }}>
        {adminbuildings.length === 0 ? (
            <p>No adminbuildings associated with this email yet.</p>
        ) : (
            adminbuildings.map((building) => (
                <div style={{
                    width: '30%',
                    backgroundColor: '#f8f9fa',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    padding: '20px',
                    margin: '10px',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                    backgroundImage: `url(${building.BuildingInteriorImage1})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: '#333',
                }} key={building.BuildingId}>
                    <h2 style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '5px', borderRadius: '5px' }}>{building.BuildingName}</h2>
                    <p style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '5px', borderRadius: '5px' }}>{building.PreferedGender}</p>
                    <h3 style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '5px', borderRadius: '5px' }}>
                        <span>{building.Capacity > 0 ? `${building.Capacity} Beds Available` : 'Add Beds on the next page'}</span>
                    </h3>
                    <p style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '5px', borderRadius: '5px' }}>From {building.Best_Price} per room</p>
                    <p style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '5px', borderRadius: '5px' }}>{building.BuildingLocation}</p>
                </div>
            ))
        )}
    </div>
</>

            </div>


           <div className="progress-step1cont">
        <div className="progress-bar">
          <div className="progress"></div>
        </div>
        <div className="buttons-step1cont">
          <button className="back-button" onClick={handleBack}>
            Back
          </button>
          <button 
    className="next-button" 
    onClick={handleNext} 
    disabled={adminbuildings.length === 0} // Disable when no adminbuildings exist
>
    Next
</button>
<style>{`
    .next-button:disabled {
        background-color: #ccc; /* Greyed-out background */
        cursor: not-allowed;   /* Change cursor to indicate it's not clickable */
    }
`}</style>

        </div>
      </div> 
        </div>



    );
}

export default Step3_1Admin;
