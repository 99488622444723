import React, { useState, useEffect } from 'react';

const ApartmentTable = ({ buildingId }) => {
  const [apartments, setApartments] = useState([]);

  useEffect(() => {
    const fetchApartments = async () => {
      try {
        const response = await fetch(`https://backendjaes.passiontrials.co.za/apartment_tbAdmin?buildingId=${buildingId}`);
        if (!response.ok) throw new Error('Failed to fetch apartments.');
        const data = await response.json();
        setApartments(data);
      } catch (error) {
        console.error('Error fetching apartments:', error);
      }
    };

    if (buildingId) {
      fetchApartments(); // Fetch apartments when BuildingId is available
    }
  }, [buildingId]);

  return (
    <div>
      <h1>Apartment List</h1>
      {buildingId ? (
        <table border="1">
          <thead>
            <tr>
              <th>Apartment Number</th>
              <th>Room Identifier</th>
              <th>Price</th>
              <th>Preferred Gender</th>
              <th>Living Arrangement</th>
              <th>Room Designation</th>
            </tr>
          </thead>
          <tbody>
            {apartments.length > 0 ? (
              apartments.map((apartment) => (
                <tr key={apartment.ApartmentId}>
                  <td>{apartment.ApartmentNumber}</td>
                  <td>{apartment.RoomIdentifier}</td>
                  <td>{apartment.ApartmentPrice}</td>
                  <td>{apartment.ApartmentPreferedGender}</td>
                  <td>{apartment.LivingArrangement}</td>
                  <td>{apartment.RoomDesignation}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No apartments found for the selected building.</td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <p>Please select a building at the top,  to view apartments.</p>
      )}
    </div>
  );
};

export default ApartmentTable;
