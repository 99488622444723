import React, { useState, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import Apartments from './Apartments';
import defaults from "./default_room_room.jpg";
import './invoice.css'
import './loaders.css'
import { PDFDocument, rgb } from 'pdf-lib';
import RegistrationModal from './components/RegistrationModal.js'; // Adjust the path based on your folder structure
import logo from './logoblack.png';
import Swal from 'sweetalert2';

import { House, CalendarClock, ScanSearch, Star, Bus, MapPinned, PhoneForwarded, User } from 'lucide-react';


const FrontPage = ({ onContinue }) => (
    <div className="full-page-content3">
        <div className="front-page">
            <div className="gif-section">
                <img height="100%" width="100%" alignItems="center"
                    src="https://i.pinimg.com/originals/c3/a6/cb/c3a6cbbc1e1d5fed032ea0a49bb9f545.gif"
                    class="Image-module"
                    aria-hidden="true"
                    style={{ borderRadius: '8px' }} />
            </div>
            <div className="subsection-module__title register-section">
                <img src={logo} alt="Logo" className="logo" />
                <h2 className="subsection-module__title" style={{ color: 'white' }} >Welcome to JAES Student Home</h2>
                <p className="subsection-module__title" style={{ color: 'black' }} >
                    Discover a new way of student living with JAES.
                </p>

                <p style={{ color: 'white' }}>
                    Press continue and finish booking .
                </p>
                <button className="btn456" onClick={onContinue} >Continue</button>
            </div>
        </div>
    </div>

);

const CHECKBOX_OPTIONS = [
    { id: 'emergency-protocol', label: 'Emergency Protocol', stateKey: 'isEmergencyChecked' },
    { id: 'house-rules', label: 'House Rules', stateKey: 'isHouseRulesChecked' },
];


function NewInvoice4({ handleClose, handleSubmit }) {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);
    const [selectedPlan1, setSelectedPayment] = useState('');
    const [userData, setUserData] = useState(null);
    const [downloadAfterLogin, setDownloadAfterLogin] = useState(false);
    const [clickedAction, setClickedAction] = useState(null); // Track the action button pressed
    let [totalAmount, setTotalAmount] = useState(null);
    const [chosenPlan, setChosenPlan] = useState('');
    const [thePrice, setThePrice] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showHouseRulesModal, setShowHouseRulesModal] = useState(false);
    const [isEmergencyChecked, setIsEmergencyChecked] = useState(false);
    const [isHouseRulesChecked, setIsHouseRulesChecked] = useState(false);
    const [isIncidentFormChecked, setIsIncidentFormChecked] = useState(false);
    const [isLeaseClauseChecked, setIsLeaseClauseChecked] = useState(false);
    const [step, setStep] = useState(0);
    const [transport, setTransport] = useState('');
    const [serviceFee, setServiceFee] = useState('');
    const serviceFeeCost = serviceFee === "yes" ? 1850 : 0;
    const [fundingOption, setFundingOption] = useState("");
    const [totalCost, setTotalCost] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const navigate = useNavigate();
    const [selectedPlan, setSelectedPlan] = useState('');
    let chosenDefault = 'not used';
    const [showLoginPrompt, setShowLoginPrompt] = useState(false);


    const toggleCheckbox = (stateKey) => {
        setCheckboxStates((prevState) => ({
            ...prevState,
            [stateKey]: !prevState[stateKey],
        }));
    };
    // useEffect(() => {
    //     // Calculate the total whenever selectedPlan or transport changes
    //     const planCost = selectedPlan ? parseInt(selectedPlan) : 0;
    //     setTotalCost(planCost + transportCost + serviceFeeCost);

    //     const items = [];
    //     if (transport === 'yes') items.push('Transport (R250)');
    //     if (serviceFee === 'yes') items.push('Service Fee (R1850)')
    //     if (selectedPlan) {
    //         const planName =
    //             selectedPlan === '900'
    //                 ? 'Early Entry'
    //                 : selectedPlan === '1200'
    //                     ? 'Standard Entry'
    //                     : 'Late Entry';
    //         items.push(`Plan (${planName})`);
    //     }

    //     setSelectedItems(items);
    // }, [selectedPlan, transport]);

    useEffect(() => {
        // Calculate the total whenever selectedPlan, transport, or serviceFee changes
        const planCost = selectedPlan ? parseInt(selectedPlan) : 0;
        if (fundingOption === 'nsfas') {
            setTransport('yes'); // Automatically set transport to "yes"
            setTotalCost(planCost+ serviceFeeCost); // Exclude transport cost from total for NSFAS users
        } else {
            // For non-NSFAS users, include transport cost if transport is "yes"
            const transportCost = transport === "yes" ? 250 : 0;
            setTotalCost(planCost + transportCost+ serviceFeeCost);
        }
      //  setTotalCost(planCost + transportCost + serviceFeeCost);

        const items = [];
        if (transport === 'yes') items.push('Transport (R250)');
        if (serviceFee === 'yes') items.push('Service Fee (R1850)');
        if (selectedPlan) {
            const planName =
                selectedPlan === '0'
                    ? 'Pay Zero Rent Promo'
                    : selectedPlan === `${apartment.ApartmentPrice / 2}`
                        ? 'Pay 50% of Rent Promo'
                        : 'Pay in Full';
            items.push(`Plan (${planName})`);
        }

        setSelectedItems(items);
    }, [selectedPlan, transport, serviceFee]);

    const [checkboxStates, setCheckboxStates] = useState({
        isEmergencyChecked: false,
        isHouseRulesChecked: false,
    });
    const transportCost = transport === "yes" ? 250 : 0;


    const { isLoggedIn, role } = useAuth();
    const { date, apartment, apartmentandbuilding } = location.state || {};

    const { userId } = useAuth(); // Get userId from AuthContext
    let amount = null;
    useEffect(() => {
        const items = [];
        if (checkboxStates.transport) items.push('Transport (R250)');
        setSelectedItems(items);
    }, [checkboxStates]);


    useEffect(() => {
        // Check if all required data is present before trying to access it
        if (!date || !apartment || !apartmentandbuilding) {
            console.error("Missing necessary data from location.state", date, apartment, apartmentandbuilding);
            setLoading(false); // Set loading false to show error or missing data message
            return; // Prevent further execution
        }
        setLoading(false);
        window.scrollTo(0, 0); // Data is present, so you can turn off loading
    }, [date, apartment, apartmentandbuilding]); // Ensure useEffect runs when any of these dependencies update

    function isNullOrUndefined(value) {
        return value == null || value == undefined || value == '';
    }

    useEffect(() => {
        if (isLoggedIn && userId) {
            setShowRegistrationModal(false);
            const fetchUserData = async () => {
                try {
                    const response = await fetch(`https://backendjaes.passiontrials.co.za/user_reserve/${userId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    if (!response.ok) {
                        throw new Error('Failed to fetch user data');
                    }

                    const data = await response.json();
                    setUserData(data.length ? data[0] : data);

                    console.log("dsdasdasadasd", data);

                    setFundingOption(data[0].TypeOfFunding.toLowerCase().replace(" ", "-"));

                    const funding = data[0].TypeOfFunding.toLowerCase().replace(" ", "-");
                    const Designation = apartment?.RoomDesignation?.toLowerCase()?.replace(" ", "-");

                    console.log("the funding type", funding, 'the actual from the database', data[0].TypeOfFunding);
                    console.log(`this is the funding --${funding}  type and this is the Room Designation-- `, Designation);

                    if (funding == 'nsfas') {
                        setTransport('no');
                        console.log(`this is the funding ${funding}  type and this is the `, Designation);


                        if (isNullOrUndefined(Designation) || Designation !== 'nsfas') {
                            Swal.fire({
                                title: "Non-supported Apartment",
                                text: "This apartment is not NSFAS funded. Please choose an NSFAS-supported apartment.",
                                icon: "warning"
                            });
                            console.log(`this is the user funding $ ${funding}  type and this is the room $ `, Designation);
                            navigate(-1);
                        }

                    }





                    setShowRegistrationModal(false);

                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            };
            fetchUserData();
        }
        else {
            setShowRegistrationModal(true);
        }
    }, [isLoggedIn, userId, apartment, navigate]);

    // const nextStep = () => setStep((prevStep) => Math.min(prevStep + 1, 5));
    const nextStep = () => {
        setStep((prevStep) => {
            if (fundingOption === "nsfas" && prevStep === 1) {
                return 3; // Skip step 2 and go directly to step 3
            }
            return Math.min(prevStep + 1, fundingOption === "nsfas" ? 5 : 5); // 5 steps in both cases, but step 2 is skipped for NSFAS
        });


    };

    const prevStep = () => {
        setStep((prevStep) => {
            if (fundingOption === "nsfas" && prevStep === 3) {
                return 1; // Go back to step 1 from step 3 for NSFAS users
            }
            return Math.max(prevStep - 1, 1); // Ensure minimum step is 1
        });
    };
    // const prevStep = () => setStep((prevStep) => Math.max(prevStep - 1, 1));

    const handleFundingChange = (event) => {
        setFundingOption(event.target.value);

        // if (selectedFunding === "nsfas") {
        //     setTransport("yes");
        // } else {
        //     setTransport(""); // Reset transport for other funding types
        // }
    };


    const handleCardClick = (planCost) => {
        const planLabel = planCost === 0 ? 'Pay R0'
        : planCost === apartment.ApartmentPrice / 2 ? 'Pay 50% Rent'
            : 'Pay in Full';

    setChosenPlan(planLabel);
        setSelectedPlan(planCost);
        nextStep();
    };


    // Define state for each checkbox item

    if (step === 0) {
        // Show the front page first
        return <FrontPage onContinue={() => setStep(1)} />;

    }



    const allCheckboxesChecked = isEmergencyChecked && isHouseRulesChecked && isIncidentFormChecked && isLeaseClauseChecked;



    const handlePayment2 = async () => {
        try {
            const amountInCents = Math.round(parseFloat(totalCost) * 100);
            const response = await fetch('https://backendjaes.passiontrials.co.za/api/pay', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    amount: amountInCents,
                    currency: 'ZAR',
                    successUrl: `https://jaesstudenthomes.co.za/Payment?status=success&user=50`,
                    cancelUrl: 'https://jaesstudenthomes.co.za/cancel?status=cancel'
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error: ${response.status} - ${errorText}`);
            }

            const data = await response.json();
            console.log('Payment response:', data);

            if (data.redirectUrl) {
                window.location.href = data.redirectUrl;
            }
        } catch (error) {
            console.error('Payment error:', error);
        }
    };

    const generatePDF = async () => {
        setClickedAction('downloadPDF'); // Set action as 'downloadPDF'
        if (!isEmergencyChecked || !isHouseRulesChecked || !isIncidentFormChecked || !isLeaseClauseChecked) {
            alert('Please agree to all the terms by checking the boxes.');
            return;
        }
        if (!isLoggedIn) {
            // If the user is not logged in, set the flag and show the registration modal
            setDownloadAfterLogin(true);
            setShowRegistrationModal(true);
            return;
        }

        // If the user is logged in, proceed with generating the PDF
        if (userData) {
            try {
                // Fetch the PDF file as an array buffer
                const response = await fetch('https://jaesstudenthomes.co.za/STUDENT%20HOMES%20BOOKING%20FORM%202025.pdf');
                const pdfBytes = await response.arrayBuffer();

                // Load PDF using pdf-lib
                const pdfDoc = await PDFDocument.load(pdfBytes);
                const firstPage = pdfDoc.getPages()[0];

                // Add user data to the PDF
                firstPage.drawText(`${userData.Name} ${userData.Surname}`, { x: 230, y: 535, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(userData.IdNumber || userData.PassportNumber, { x: 230, y: 510, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(apartmentandbuilding.RoomIdentifier, { x: 180, y: 790, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText("X", { x: 385, y: 490, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText("X", { x: 230, y: 490, size: 12, color: rgb(0, 0, 0) });

                firstPage.drawText(apartmentandbuilding.BuildingLocation || 'N/A', { x: 230, y: 465, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(userData.Email, { x: 265, y: 440, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(userData.PhoneNumber, { x: 255, y: 415, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(new Date().toLocaleDateString('en-GB'), { x: 170, y: 85, size: 12, color: rgb(0, 0, 0) });

                // Save the modified PDF to a blob and trigger download
                const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
                const link = document.createElement('a');
                link.href = pdfDataUri;
                link.download = `Booking_Form_${Date.now()}.pdf`;
                document.body.appendChild(link); // Append link to the DOM for Firefox support
                link.click();
                document.body.removeChild(link); // Remove link after download

                console.log('PDF successfully created and downloaded.');
            } catch (error) {
                console.error('Error creating PDF:', error.message);
            }
        } else {
            console.error("User data is not available for generating PDF");
        }
    };
    // Function to handle payment
    const handlePayment = () => {
        setClickedAction('pay'); // Set action as 'pay'

        if (role === 'Admin' || role === 'Super Admin') {
            alert('Admins are not permitted to book an apartment');
            navigate(-1);
            return;
        }

        if (selectedPlan && allCheckboxesChecked) {
            if (!isLoggedIn) {
                // Show the registration modal if user is not logged in
                setShowRegistrationModal(true);
                return;
            }

            setLoading(true);
            setTimeout(() => {
                setLoading(false);
                navigate('/YocoPayment', { state: { date, apartment, apartmentandbuilding: apartmentandbuilding.BuildingId, totalCost, serviceFee, chosenPlan, nextPaymentDate, Email: userData.Email } });
            }, 5000);
        } else if (!allCheckboxesChecked) {
            alert('Please agree to all the terms by checking the boxes.');
        } else {
            alert('Please select a payment plan');
        }
    };

    const handleGender = (binary) => {
        if (binary) {
            if (binary == 0) {
                return 'Female';
            }

            if (binary == 1) {

                return 'Male';
            }

            if (binary == 2) {
                return 'Mixed';
            }
        }
    }

    const handleSetup = (binary) => {

        if (binary) {
            if (binary == 0) {
                return "Single";
            }

            if (binary == 1) {
                return 'Single with Ensuite';
            }

            if (binary == 2) {
                return 'Sharing Double';
            }

            if (binary == 3) {
                return 'Sharing Double with Ensuite';
            }

            if (binary == 4) {
                return 'Sharing Triple';

            }

            if (binary == 5) {
                return 'Bachelor Pods';
            }
        }
    };

    const handleSelectedPlan = (e) => {
        const { name, value } = e.target;
        let plan = e?.target.options[e?.target.selectedIndex]?.text;
        setSelectedPayment(Number(value));
        console.log('this is the plan amount chosen', Number(value) + amount);
        // setTotalAmount(Number(value) + amount);
        setServiceFee(Number(value));

        setChosenPlan(plan);


        let splited = plan.split('-')[0].trim();

        console.log('its split :', splited);
        if (splited == 'Plan A') {
            amount = apartment?.ApartmentPrice;
            //represents plan a
            console.log("plan a:,", amount);
            setThePrice(amount);
            setTotalAmount(amount + Number(value));
        }

        if (splited == 'Plan B') {
            amount = apartment?.PriceB;
            console.log("plan b:,", amount);
            setThePrice(amount);
            setTotalAmount(amount + Number(value));

        }

    };

    // React.useEffect(() => {
    //     console.log('This is the chosen plan:', chosenPlan);
    //     setChosenPlan(chosenPlan);
    // }, [chosenPlan]);

    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh' // This ensures it takes the full viewport height
        }}>
            <div className="🤚">
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="🌴"></div>
                <div className="👍"></div>
            </div>;     </div>// Show loading while checking for data
    }

    if (!date || !apartment || !apartmentandbuilding) {
        return <div>Error: Missing invoice data. Please ensure all data is provided.</div>; // Show error if data is missing
    }
    // Callback function after successful registration
    const handleRegistrationSuccess = () => {
        setShowRegistrationModal(false); // Close the modal

        // Debug log to check which action is triggered
        console.log("Registration Success:", clickedAction);

        if (downloadAfterLogin || clickedAction === 'downloadPDF') {
            console.log("Downloading PDF after login..."); // Check if this line is reached
            generatePDF(); // Call `generatePDF()` to download the PDF
            setDownloadAfterLogin(false); // Reset the flag
        } else if (clickedAction === 'pay') {
            console.log("Redirecting to YocoPayment...");
            navigate('/ReserveYocoPayment', { state: { /* your existing parameters */ } });
        }
    };
    // Format dates
    const pickedDate = new Date(date).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }); //current date no picking
    const nextPaymentDate = new Date(date);
    nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 3);

    nextPaymentDate.setDate(new Date(nextPaymentDate.getFullYear(), nextPaymentDate.getMonth() + 1, 0).getDate());

    ///= apartment.ApartmentPrice;
    let currentDay = date.toLocaleDateString('en-US', { day: 'numeric' });
    let earlyDate = 0;
    let lateDate = 0;
    let AmountAfterDeduction = 0;
    let AmountAfterIncrease = 0;

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('image', selectedFile);

        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/upload',
                {
                    headers: { 'Content-Type': 'multipart/form-data', },
                    method: "POST",
                    body: formData,
                });

            if (response.ok) {
                const data = await response.json();
                console.log('Image uploaded successfully: ', data);

            }
            else {
                console.error('Error uploading image:');
            }
        }
        catch (error) {
            console.error('error uploading image:', error);
        }

    };
    const sendEmailToStudent = async () => {
        try {

            Swal.fire({
                title: "Booking Approval?",
                text: "An email will be sent to you shortly",
                icon: "success"
            });

            const response = await fetch('https://backendjaes.passiontrials.co.za/sendBookingEmailForNSFAS', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    apartmentId: 'NSFAS Booking',
                    userId,
                    name: `${userData?.Name} ${userData?.Surname}`,
                    IdNumber: `${userData?.IdNumber}`,
                    BookingNo: `${apartment?.RoomIdentifier}`,
                    // Date: new Date().toLocaleDateString('en-GB'),
                    Date: new Date().toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }),
                    Cell: `${userData?.PhoneNumber}`,
                    Address: `${apartmentandbuilding?.BuildingLocation}`,
                    Transport: "Yes",
                    Homes: "Transport",
                    userEmail: `${userData?.Email}`
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to send booking email: ${errorText}`);
            } else {
                console.log('Booking email sent successfully!');

                const makeARecord = await fetch("https://backendjaes.passiontrials.co.za/nsfas-bookings", {
                    method: 'POST',
                    headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify({
                        UserId: userId,
                        ApartmentId: apartment?.ApartmentId,
                        BookingNumber: apartment?.RoomIdentifier,
                        Date: new Date().toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }),
                        Name: userData?.Name,
                        Surname: userData?.Surname,
                        Email: userData?.Email,
                        ApprovalStatus: 'Pending',
                        Approval: false
                    })
                });

                if (!makeARecord.ok) {
                    const errorText = await response.text();
                    throw new Error(`Failed to record booking information : ${errorText}`);
                } else {
                    console.log('Booking record saved successfully!');
                }
            }

            console.log('the NSFAS students');


        }
        catch (error) {
            console.log('Error sending booking email for NSFAS student', error);
        }
    }

    const handleBookingPayment = () => {
        setClickedAction('pay'); // Set action as 'pay'

        if (role === 'Admin' || role === 'Super Admin') {
            alert('Admins are not permitted to book an apartment');
            navigate(-1);
            return;
        }

        if (isLoggedIn) {
            // if (!isLoggedIn) {
            // Show the registration modal if user is not logged in
            // setShowRegistrationModal(true);
            // return;
            // }

            const room = apartment.RoomDesignation;
            if (room == 'Self Funded' || isNullOrUndefined(room)) {

                setLoading(true);
                setTimeout(() => {
                    setLoading(false);
                    navigate('/YocoPayment', { state: { date, apartment, apartmentandbuilding: apartmentandbuilding.BuildingId, totalCost, serviceFee, chosenDefault:chosenPlan, nextPaymentDate, Email: userData.Email } });
                }, 5000);
            }
            else {
                setTimeout(() => {
                    setLoading(false);

                    sendEmailToStudent();

                }, 5000);


            }


        }
        else {
            console.log("User not logged in, opening registration modal");
            setShowRegistrationModal(true);
            return;
        }
    };

    // if (currentDay > 1 && currentDay < 25) {

    //     currentDay = currentDay - 1;
    //     earlyDate = currentDay / 100 * amount;

    //     AmountAfterDeduction = amount - earlyDate;

    //     amount = AmountAfterDeduction;

    // }
    // else if (currentDay > 24 && currentDay <= 31) {
    //     currentDay = currentDay - 1;
    //     lateDate = currentDay / 100 * amount;

    //     AmountAfterIncrease = lateDate + amount;

    //     amount = AmountAfterIncrease;

    //     nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1, 1);

    // }

    return (
        <>

            <div class="containerinvoice" >


                <div className="rounded-lg lg:shadow-lg lg:p-3 lg:border border-gray-100 booking-summary">
                    <div>
                        <div className="flex">
                            <p className="content-font uppercase font-semibold text-gray-500">Booking Summary</p>
                        </div>
                        <img
                            alt="Classic Ensuite"
                            src={apartment.ApartmentRoomImage ? `https://backendjaes.passiontrials.co.za/src${apartment.ApartmentRoomImage}` : defaults}
                            width="301"
                            height="200"
                            className="w-full h-40 object-cover rounded-md my-2"
                        />
                    </div>
                    <div className="text-gray-700">
                        <h3 className="subsection-module__title">{apartmentandbuilding.BuildingName}</h3>
                        <ul className="content-font">
                            <li className="flex justify-between pb-0.5 flex-col lg:flex-row mb-2 lg:mb-1">
                                <span className="flex-shrink-0 lg:mr-2 font-semibold">Room Type</span>
                                <span>{apartment.LivingArrangement}</span>
                            </li>
                            <li className="flex justify-between mb-2 lg:mb-1">
                                <span className="flex-shrink-0 lg:mr-2 font-semibold">Suburb</span>
                                <span>{apartmentandbuilding.Suburb}</span>
                            </li>
                        </ul>
                        <div className="divider my-3"></div>
                        <ul className="content-font">
                            <li className="flex items-center gap-x-6 justify-between md:justify-start">
                                <div>
                                    <p>Move-in</p>
                                    <p className="text-base font-semibold">{pickedDate}</p>

                                </div>
                                <svg className="svg" fill="none" stroke="currentColor" strokeWidth="2.5" width="40" height="40" viewBox="0 0 24 24">
                                    <path d="M8.25 4.5l7.5 7.5-7.5 7.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                                <div className="text-right md:text-left">
                                    <p>Move-out</p>
                                    <p className="text-base font-semibold">30 Nov 2025</p>
                                </div>
                            </li>
                        </ul>
                        <div className="divider my-3"></div>
                        <ul className="mb-2">
                            <li className="flex justify-between">
                                <span className="font-semibold">Rent</span>
                                {fundingOption === 'nsfas' ? (
                                    <span className="text-theme-orange font-semibold">NSFAS Funded</span>

                                ) : (
                                    <span className="text-theme-orange font-semibold">R{apartment.ApartmentPrice}</span>

                                )}

                            </li>

                            <li className="flex justify-between pb-0.5">
                                <span className="content-font">Service Fee</span>
                                <span>R{serviceFeeCost}</span>
                            </li>
                            {fundingOption !== 'nsfas' ? (<li className="flex items-center gap-x-6 justify-between md:justify-start">
                                <span className="content-font" style={{ padding: '2px' }}>Transport</span>
                                <span>R{transportCost}</span>
                            </li>
                            ) : (
                                <li className="flex items-center gap-x-6 justify-between md:justify-start">
                                    <span className="content-font" style={{ padding: '2px' }}>Transport</span>
                                    <span className="strikethrough">R{250}</span>
                                </li>
                            )
                            }


                        </ul>

                    </div>
                </div>

                {/* Render the registration modal */}
                <div className="full-page-content">
                    {/* <span className="close-btn" onClick={handleClose}>×</span> */}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",


                        }}
                    >
                        <h1 className="subsection-module__title">Hi, let's complete your booking form</h1>
                        <img
                            src={logo}
                            alt="Logo"
                            className="logo"
                            style={{
                                width: "100px", // Adjust width as needed
                                height: "auto", // Maintain aspect ratio
                            }}
                        />
                    </div>
                    <h2 className="subsection-module__title">Step {step}/5: </h2>

                    {/* Progress Bar */}
                    {/* <div className="progress-container">
                        {[1, 2, 3, 4, 5].map((bar) => (
                            <div key={bar} className={`progress-bar ${step >= bar ? 'filled' : ''}`} />

                        ))}
                    </div> */}
                    <div className="progress-container">
                        {(fundingOption === "nsfas" ? [1, 3, 4, 5] : [1, 2, 3, 4, 5]).map((bar) => (
                            <div key={bar} className={`progress-bar ${step >= bar ? "filled" : ""}`} />
                        ))}
                    </div>

                    {/* Step Content */}
                    {step === 1 && (
                        <div>
                            {/* Funding Source Title */}
                            <label style={{ textAlign: 'left', display: 'block', fontSize: '20px' }}>
                                Personal Details <span style={{ color: 'red' }}>*</span>
                            </label>

                            {/* Funding Options (Dynamically Highlighted) */}
                            <div className="amber-form-group2">
                                <div>
                                    <label className={fundingOption === "self-funded" ? "selected" : ""}>
                                        <input
                                            type="radio"
                                            name="fundingOption"
                                            value="self-funded"
                                            checked={fundingOption === "self-funded"}
                                            readOnly
                                        />
                                        self-funded
                                    </label>

                                    <label className={fundingOption === "nsfas" ? "selected" : ""}>
                                        <input
                                            type="radio"
                                            name="fundingOption"
                                            value="nsfas"
                                            checked={fundingOption === "nsfas"}
                                            readOnly
                                        />
                                        NSFAS
                                    </label>

                                    <label className={fundingOption === "bursary" ? "selected" : ""}>
                                        <input
                                            type="radio"
                                            name="fundingOption"
                                            value="bursary"
                                            checked={fundingOption === "bursary"}
                                            readOnly
                                        />
                                        Other Bursaries
                                    </label>
                                </div>
                            </div>

                            {/* Dynamic Input Fields Based on Funding Option */}

                            {((fundingOption === "nsfas" &&
                                (isNullOrUndefined(apartment?.RoomDesignation) ||
                                    apartment?.RoomDesignation?.toLowerCase() !== "nsfas")) ||
                                ((fundingOption === "self-funded" || fundingOption === "bursary") &&
                                    apartment?.RoomDesignation?.toLowerCase() === "nsfas")) ? (
                                <div>
                                    <p
                                        style={{
                                            color: "red",
                                            fontSize: "16px",
                                            margin: "20px 0",
                                            textAlign: "center",
                                        }}
                                    >
                                        {fundingOption === "nsfas"
                                            ? "This room does not support NSFAS-funded students. Please choose another room."
                                            : "This room is designated for NSFAS-funded students only. Please choose another room."}
                                    </p>
                                    <button className="btn456" onClick={() => navigate(-1)}>
                                        Go Back
                                    </button>
                                </div>
                            ) : (

                                <div className="form-grid">

                                    {showLoginPrompt && (
                                        <p
                                            style={{
                                                color: "red",
                                                fontSize: "16px",
                                                margin: "auto",
                                                textAlign: "center",
                                            }}
                                        >
                                            You need to log in to proceed. Please log in to continue with your booking.
                                        </p>
                                    )}

                                    {fundingOption === "self-funded" && (
                                        <>

                                            <div className="amber-form-group">
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Name <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Eg. Single , Married , Etc..." value={userData.Name} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Email Address <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter funders address" value={userData.Email} required />
                                                </div>

                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Institution <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Eg. Single , Married , Etc..." value={userData.InstituteName} required />
                                                </div>
                                            </div>
                                            <div className="amber-form-group">
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Surname <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter funders address" value={userData.Surname} required />
                                                </div>


                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Contact <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Eg. Single , Married , Etc..." value={userData.PhoneNumber} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Home Address <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter Home address" value={userData.HomeAddress} required />
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {fundingOption === "nsfas" && (
                                        <>
                                            <div className="amber-form-group">
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>NSFAS Reference Number <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter NSFAS reference number" value={userData.NsfasReferenceNumber} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Name <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter your email adress here." value={userData.Name} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Institution <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter your home address here." value={userData.InstituteName} required />
                                                </div>
                                            </div>
                                            <div className="amber-form-group">
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Email Address <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter NSFAS reference number" value={userData.Email} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Surname <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter your email adress here." value={userData.Surname} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Home Address <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter your home address here." value={userData.HomeAddress} required />
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {fundingOption === "bursary" && (
                                        <>
                                            <div className="amber-form-group">
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Bursary Name <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter bursary organization name" value={userData.BurseryOrganizationName} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Reference Number <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter bursary reference number" value={userData.BurseryReferenceNumber} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Bursary Type <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Full bursary , partial bursary , Etc..." value={userData.BurseryType} required />
                                                </div>
                                            </div>
                                            <div className="amber-form-group">
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Bursary Start Date <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter the date funding starts " value={userData.BurseryStartDate} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Bursary End Date <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter the end of your funding for the year. " value={userData.BurseryEndDate} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Coverage Details <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Accomodation, tution , food , transport Etc ... " value={userData.CoverageDetails} required />
                                                </div>
                                            </div>
                                            <div className="amber-form-group">
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Bursary Benefactor Email <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter phone number or email. " value={userData.Email} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Institution <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" placeholder="Enter your institution. e.g DUT, MUT " value={userData.InstituteName} required />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {!isLoggedIn ? (<button className="btn456" onClick={() => setShowRegistrationModal(true)} >Login to proceed</button>
                                    ) : (<button className="btn456" onClick={nextStep} disabled={!fundingOption}>Next</button>
                                    )}                                </div>
                            )}
                            {/* Next Button */}

                        </div>
                    )}

                    {step === 2 && (
                        <div>
                            <label style={{ textAlign: 'left', display: 'block', fontSize: '20px' }}>
                                Transport <span style={{ color: 'red' }}>*</span>
                            </label>
                            <div className="transport-options" style={{ marginTop: '20px' }}>
                                <div
                                    className={`transport-option-wrapper ${transport === 'yes' ? 'selected' : ''}`}
                                    onClick={() => fundingOption !== 'nsfas' && setTransport('yes')}
                                >
                                    <input
                                        type="radio"
                                        name="transport"
                                        id="transportYes"
                                        value="yes"
                                        checked={transport === 'yes'}
                                        onChange={(e) => setTransport(e.target.value)}
                                        disabled={fundingOption === "nsfas"}
                                        className="radio-button"
                                    />
                                    <label htmlFor="transportYes" className="transport-label">
                                        <div className="transport-option">
                                            <p className="subsection-module__title">Yes, I need transport</p>
                                            <p className="subsection-module__subtitle">
                                                <span className="discount-highlight">SAVE 45%</span> R250 <span className="strikethrough">R450</span><br />
                                                Enjoy safe, reliable transport to and from your institution.<br />

                                                <span className="discount-highlight">Transport Deal ends 01 Feb 2025.</span>
                                            </p>
                                        </div>
                                    </label>
                                </div>
                                <div
                                    className={`transport-option-wrapper ${transport === 'no' ? 'selected' : ''}`}
                                    onClick={() => fundingOption !== "nsfas" && setTransport('no')}
                                >
                                    <input
                                        type="radio"
                                        name="transport"
                                        id="transportNo"
                                        value="no"
                                        checked={transport === 'no'}
                                        onChange={(e) => setTransport(e.target.value)}
                                        disabled={fundingOption === "nsfas"}
                                        className="radio-button"
                                    />
                                    <label htmlFor="transportNo" className="transport-label">
                                        <div className="transport-option">
                                            <p className="subsection-module__title">No, I do NOT need transport</p>
                                            <p className="subsection-module__subtitle">
                                                Choose this option if you have your own transport arrangements.
                                            </p>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="form-grid2">
                                <button className="btn456" onClick={prevStep}>Back</button>
                                <button className="btn456" onClick={nextStep} disabled={!transport}>Next</button>
                            </div>
                        </div>
                    )}

                    {step === 3 && (
                        <div>
                            <label style={{ textAlign: 'left', display: 'block', fontSize: '20px' }}>
                                Terms and Conditions <span style={{ color: 'red' }}>*</span>
                            </label>
                            <div className="booking-form scrollable">
                                <div className="step">Terms and conditions</div>
                                <p className="step">JAES STUDENT HOMES - GENERAL HOUSE RULES</p>
                                <p className="step">The following is the general house rules for all properties. Each property has specific added terms that are to be adhered to in addition to the following:</p>

                                <ul className="bullet-list">
                                    <li>&#8226; Communal areas should be kept clean at all times.</li>
                                    <li>&#8226; Gates and house entrances should be locked AT ALL TIMES for tenants’ safety.</li>
                                    <li>&#8226; Rooms should be kept locked when tenant is not on the premises...</li>
                                    <li>&#8226; Every tenant’s privacy should be respected...</li>
                                    <li>&#8226; No smoking permitted inside the premises...</li>
                                    <li>&#8226; No washing is to be hanged in the bathroom.</li>
                                    <li>&#8226; No more than 2 visitors per lessee allowed...</li>
                                    <li>&#8226; No visitors allowed before 10am and after 21:00 pm. NO SLEEPOVERS.</li>
                                    <li>&#8226; Down time after 21:30 pm.</li>
                                </ul>

                                <div className="step3" style={{ textAlign: 'left', display: 'block' }}>Fines (of up to R950) will be charged to tenant account for each rule break.</div>
                                <hr />

                                <label style={{ textAlign: 'left', display: 'block' }}>
                                    Check all boxes to accept terms and conditions <span style={{ color: 'red' }}>*</span>
                                </label>
                                <div style={{ textAlign: 'left', display: 'block' }}>
                                    By ticking the boxes below, you confirm that you have read, understood, and agreed to the Terms and Conditions, General House Rules, and Emergency Protocols outlined above. Please note, non-compliance with these rules may result in penalties and may prevent you from successfully booking or reserving a space.                                </div>
                                <div className="form-grid2">
                                    {CHECKBOX_OPTIONS.map(({ id, label, stateKey }) => (
                                        <div key={id} className="checkbox-wrapper" onClick={() => toggleCheckbox(stateKey)}>
                                            <input
                                                id={id}
                                                name={id}
                                                type="checkbox"
                                                checked={checkboxStates[stateKey]}
                                                onChange={() => toggleCheckbox(stateKey)}
                                            />
                                            <label className="terms-label" htmlFor={id}>
                                                <span className="label-text">{label}</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
                                                    <mask fill="white" id={`path-1-inside-1_476_5-${id}`}>
                                                        <rect height="200" width="200"></rect>
                                                    </mask>
                                                    <rect mask={`url(#path-1-inside-1_476_5-${id})`} strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
                                                    <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
                                                </svg>
                                            </label>
                                        </div>
                                    ))}
                                </div>

                                <style>{`
  /* Container for checkboxes */
.checkbox-container {
display: inline, /* Align checkboxes side by side */
  flex-wrap: wrap; /* Allow wrapping to the next line if necessary */
  gap: 15px; /* Add spacing between checkboxes */
  justify-content: flex-start; /* Align items to the left */
  margin: 20px 0; /* Add spacing around the container */
}

.checkbox-wrapper {
  display: inline, /* Display each checkbox and its label inline */
  align-items: center; /* Align items vertically */
  gap: 10px; /* Add spacing between the checkbox and the label */
}

.checkbox-wrapper input[type="checkbox"] {
  display: none; /* Hide the default checkbox */
}

.checkbox-wrapper .terms-label {
  cursor: pointer;
  display: in-line;
  align-items: center;
}

.checkbox-wrapper .terms-label .label-text {
  margin-right: 10px; /* Add spacing after the text */
}

.checkbox-wrapper .checkbox-svg {
  width: 30px;
  height: 30px;
}

.checkbox-wrapper .checkbox-box {
  fill: rgba(207, 205, 205, 0.425);
  stroke: #ff5a5f;
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper .checkbox-tick {
  stroke: #ff5a5f;
  stroke-dasharray: 172;
  stroke-dashoffset: 172;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-box,
.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-tick {
  stroke-dashoffset: 0;
}


`}</style>
                            </div>
                            <div className="form-grid2">
                                <button className="btn456" onClick={prevStep}>Back</button>
                                <button
                                    className="btn456"
                                    onClick={nextStep}
                                    disabled={!Object.values(checkboxStates).every((checked) => checked)}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    )}
                    {step === 4 && (
                        <div>
                            <label style={{ textAlign: 'left', display: 'block', fontSize: '20px' }}>
                                Booking Form <span style={{ color: 'red' }}>*</span>
                            </label>


                            {/* <div className="transport-options" style={{ marginTop: '20px' }}>
                                <div
                                    className={`transport-option-wrapper ${serviceFee === 'yes' ? 'selected' : ''}`}
                                >
                                    <input
                                        type="radio"
                                        name="serviceFee"
                                        id="serviceYes"
                                        value="yes"
                                        checked={serviceFee === 'yes'}
                                        onChange={(e) => setServiceFee(e.target.value)}
                                        className="radio-button"
                                    />
                                    <label htmlFor="transportYes" className="transport-label">
                                        <div className="transport-option">
                                            <p className="subsection-module__title">Service Fee</p>
                                            <p className="subsection-module__subtitle">
                                                <span className="discount-highlight">R1 850</span><br />
                                                Service Fee includes , cleaning services upon arrival and proccessing of documentaion.<br />


                                            </p>
                                        </div>
                                    </label>
                                </div>

                            </div> */}
                            <div className="booking-form scrollable">
                                {/* <div className="step">SERVICE FEE Acknowledgement</div> */}


                                {/* <div>
                                    Service fee is a non-refundable charge that covers administrative costs associated with processing your booking or reservation. This fee ensures that all necessary arrangements, documentation, and support are in place to provide a seamless and efficient service. By paying the service fee of R1850.00, you contribute to maintaining the quality and reliability of our operations, ensuring your booking process is handled with care and professionalism.
                                </div> 
                                <div className="divider my-3"></div>


                                {/* <label style={{ textAlign: 'left', display: 'block' }}>
                                    Check all boxes to accept terms and conditions <span style={{ color: 'red' }}>*</span>
                                </label> 
                                <div>
                                    By ticking the box below, you confirm that you have read, understood, and agreed to the Service Fee policy outlined above. Please note, failure to acknowledge the service fee may prevent you from successfully booking or reserving a space.  </div>
                                {/* <div className="form-grid2">
                                    {/* {CHECKBOX_OPTIONS.map(({ id, label, stateKey }) => (
                                        <div key={id} className="checkbox-wrapper" onClick={() => toggleCheckbox(stateKey)}>
                                            <input
                                                id={id}
                                                name={id}
                                                type="checkbox"
                                                checked={checkboxStates[stateKey]}
                                                onChange={() => toggleCheckbox(stateKey)}
                                            />
                                            <label className="terms-label" htmlFor={id}>
                                                <span className="label-text">{label}</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
                                                    <mask fill="white" id={`path-1-inside-1_476_5-${id}`}>
                                                        <rect height="200" width="200"></rect>
                                                    </mask>
                                                    <rect mask={`url(#path-1-inside-1_476_5-${id})`} strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
                                                    <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
                                                </svg>
                                            </label>
                                        </div>
                                    ))} 
   
                                    <div className="checkbox-wrapper" onClick={() => setServiceFee(serviceFee === 'yes' ? '' : 'yes')}>
                                        <input
                                            id="serviceYes"
                                            name="serviceFee"
                                            type="checkbox"
                                            checked={serviceFee === 'yes'}
                                            onChange={(e) => setServiceFee(e.target.checked ? 'yes' : '')}
                                        />
                                        <label className="terms-label" htmlFor="serviceFeeAcknowledgment">
                                            <span className="label-text">I acknowledge and agree to the Service Fee.</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
                                                <mask fill="white" id="path-1-inside-1_476_5-serviceFeeAcknowledgment">
                                                    <rect height="200" width="200"></rect>
                                                </mask>
                                                <rect mask="url(#path-1-inside-1_476_5-serviceFeeAcknowledgment)" strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
                                                <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
                                            </svg>
                                        </label>
                                    </div>
                                </div> */}

                                <div className="container2">
                                    <div className="header">
                                        <img src={logo} alt="Logo" className="logo" />
                                        <div className="booking-box"><h1>BOOKING NUMBER :{apartment?.RoomIdentifier} </h1></div>
                                    </div>
                                    <h1>STUDENT HOMES 2025 - BOOKING FORM</h1>

                                    <div className="content">
                                        <p>The booking form herein, the undersigned, later on referred to as the “Client”, agrees to the detailed clauses below.</p>
                                        <p>The Client agrees to pay the required booking/deductible service fee to JAES, for the purposes of removal of the rental premises from the market for the timeline stated below and/or administrative costs for the transport service.</p>
                                    </div>

                                    <div className="section-title">Service Fees</div>

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Service</th>
                                                <th>Description</th>
                                                <th>Fee</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Service booking fee</td>
                                                <td>The service fee is an annual booking and administrative charge. It encompasses essential booking costs and contributes to offsetting initial move-in expenses, as well as subsidising additional amenities included in the student homes.</td>
                                                <td>R1,850.00</td>
                                            </tr>
                                            <tr>
                                                <td>Transport administrative fee</td>
                                                <td>The annual transport administrative fee covers the registration and transport card (for access into the system). It covers the administration and the booking platform as well. Note it’s a once-off fee.</td>
                                                <td>R450.00</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="section-title">Client Details</div>

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Field</th>
                                                <th>Information</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Full Name</td>
                                                <td>{userData.Surname}{' '}{userData.Name}</td>
                                            </tr>
                                            <tr>
                                                <td>ID/Passport Number</td>
                                                <td>{userData.IdNumber}</td>
                                            </tr>
                                            <tr>
                                                <td>Services</td>
                                                <td>
                                                    <div className="checkbox-item">
                                                        <input type="checkbox" id="emergency-protocol" name="emergency-protocol" checked onChange={() => { { } }} />
                                                        <label htmlFor="emergency-protocol">Student Accommodation</label>
                                                    </div>
                                                    <div className="checkbox-item">
                                                        <input type="checkbox" id="house-rules" name="house-rules"   value={transport}
                                        checked={transport === 'yes'}  />
                                                        <label htmlFor="house-rules">Transport</label>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="section-title">Contact Information</div>

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Field</th>
                                                <th>Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Tenant Address/Student Home</td>
                                                <td>{userData.HomeAddress}</td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td>{userData.Email} </td>
                                            </tr>
                                            <tr>
                                                <td>Cell</td>
                                                <td>{userData.PhoneNumber}</td>
                                            </tr>
                                            <tr>
                                                <td>WhatsApp</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="section-title">Terms & Conditions</div>

                                    <div className="terms">
                                        <p style={{ textAlign: 'left' }}>1. The service booking fee and Transport administrative fee is <span className="underline">NOT REFUNDABLE</span> under any circumstances.</p>
                                        <p style={{ textAlign: 'left' }}>2. The Client confirms the information given above is true and accurate and is responsible for informing JAES should any details given above alter.</p>
                                        <p style={{ textAlign: 'left' }}>3. If the Client fails to sign the Terms of Residence or Terms of Service (by the 1st February 2025, for the period February to 31st November 2025) or cancels, JAES reserves the right to cancel the service.</p>
                                        <p style={{ textAlign: 'left' }}>4. If applicant signs the Terms of Residence and fails to move in, applicant will be responsible for the initial term or until property rents again.</p>
                                        <p style={{ textAlign: 'left' }}>5. The annual service booking fee for the duration, 15th February to 15th November, does not include the food package or transport service.</p>
                                        <p style={{ textAlign: 'left' }}>6. The annual transport administrative fee is solely for the purposes of the transport service for the duration, 15th February to 15th November 2025.</p>
                                    </div>

                                    <div className="signature-line" style={{ textAlign: 'left' }}>
                                        <p>SIGNATURE OF CLIENT: <span ><span style={{ borderBottom: '1px solid #000' }}><span style={{ opacity: '0' }}>---------</span></span><span style={{ borderBottom: '1px solid #000' }}>{userData.Name}</span><span style={{ borderBottom: '1px solid #000' }}><span style={{ opacity: '0' }}>------______----------</span></span></span><br /> </p>
                                    </div>
                                    <div className="signature-line" style={{ textAlign: 'left' }}>
                                        <p>DATE: <span >__<span style={{ borderBottom: '1px solid #000' }}>{new Date().toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}</span>__</span></p>
                                    </div>
                                    <div className="checkbox-wrapper" onClick={() => setServiceFee(serviceFee === 'yes' ? '' : 'yes')}>
                                        <input
                                            id="serviceYes"
                                            name="serviceFee"
                                            type="checkbox"
                                            checked={serviceFee === 'yes'}
                                            onChange={(e) => setServiceFee(e.target.checked ? 'yes' : '')}
                                        />
                                        <label className="terms-label" htmlFor="serviceFeeAcknowledgment">
                                            <span className="label-text">I acknowledge and agree to the booking form.</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
                                                <mask fill="white" id="path-1-inside-1_476_5-serviceFeeAcknowledgment">
                                                    <rect height="200" width="200"></rect>
                                                </mask>
                                                <rect mask="url(#path-1-inside-1_476_5-serviceFeeAcknowledgment)" strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
                                                <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
                                            </svg>
                                        </label>
                                    </div>
                                    <style>{`
.container2 {
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border: 2px solid #000;
    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
    gap: 3px;
}
h1 {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 5px;
    text-transform: uppercase;
}
.section-title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #000;
    text-decoration: underline;
}
.content {
    font-size: 14px;
    line-height: 1.5;
    color: #000;
    margin-bottom: 5px;
}
.table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}
.table th, .table td {
    border: 1px solid #000;
    padding: 10px;
    text-align: left;
    font-size: 14px;
}
.checkbox-section {
    margin: 15px 0;
    font-size: 14px;
    display: flex;
    gap: 20px;
    align-items:flex-start;
}
.terms {
    font-size: 14px;
    line-height: 1.5;
    margin-top: 5px;
    border: 1px solid #000;
    padding: 10px;
    background-color: #f9f9f9;
}
p {
    margin: 5px 0;
}
.signature-line {
    margin: 20px 0;
    font-size: 14px;
    padding-top: 15px;
}
.underline {
    text-decoration: underline;
}
.section-title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #000;
    text-decoration: underline;
}
.content {
    font-size: 14px;
    line-height: 1.5;
    color: #000;
    margin-bottom: 5px;
}
.table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}
.table th, .table td {
    border: 1px solid #000;
    padding: 10px;
    text-align: left;
    font-size: 14px;
}
.checkbox-section {
    margin: 15px 0;
    font-size: 14px;
    display: flex;
    gap: 20px;
    align-items:flex-start;
}
.terms {
    font-size: 14px;
    line-height: 1.5;
    margin-top: 5px;
    border: 1px solid #000;
    padding: 10px;
    background-color: #f9f9f9;
}
p {
    margin: 5px 0;
}
.signature-line {
    margin: 20px 0;
    font-size: 14px;
    padding-top: 15px;
}
.underline {
    text-decoration: underline;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}
.booking-box {
    border: 2px solid #000;
    padding: 25px;
    font-weight: bold;
    font-size: 1.2em;
    font-family: Arial, sans-serif;
    margin-bottom: 15px;
}`}</style>
                                </div>


                                <style>{`
  /* Container for checkboxes */
.checkbox-container {
display: inline, /* Align checkboxes side by side */
  flex-wrap: wrap; /* Allow wrapping to the next line if necessary */
  gap: 15px; /* Add spacing between checkboxes */
  justify-content: flex-start; /* Align items to the left */
  margin: 20px 0; /* Add spacing around the container */
}

.checkbox-wrapper {
  display: inline, /* Display each checkbox and its label inline */
  align-items: center; /* Align items vertically */
  gap: 10px; /* Add spacing between the checkbox and the label */
}

.checkbox-wrapper input[type="checkbox"] {
  display: none; /* Hide the default checkbox */
}

.checkbox-wrapper .terms-label {
  cursor: pointer;
  display: in-line;
  align-items: center;
}

.checkbox-wrapper .terms-label .label-text {
  margin-right: 10px; /* Add spacing after the text */
}

.checkbox-wrapper .checkbox-svg {
  width: 30px;
  height: 30px;
}

.checkbox-wrapper .checkbox-box {
  fill: rgba(207, 205, 205, 0.425);
  stroke: #ff5a5f;
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper .checkbox-tick {
  stroke: #ff5a5f;
  stroke-dasharray: 172;
  stroke-dashoffset: 172;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-box,
.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-tick {
  stroke-dashoffset: 0;
}


`}</style>
                            </div>
                            <div className="form-grid2">
                                <button className="btn456" onClick={prevStep}>Back</button>
                                <button className="btn456" onClick={nextStep} disabled={!serviceFee}>Next</button>
                            </div>
                        </div>
                    )}
                    {step === 5 && (
                        <div>

                            <label style={{ textAlign: 'left', display: 'block', fontSize: '20px' }}>
                                Booking your home <span style={{ color: 'red' }}>*</span>
                            </label>

                            <div className="contentinvoice">

                                <div className="amber-form-grid">
                                    {/* <div className="form-grid">
                                        <Bus size={20} />
                                        <p className="subsection-module__title">
                                            <strong>Effortless Commuting:</strong>
                                        </p>
                                        <p className="subsection-module__subtitle">
                                            Reliable daily transport to get you where you need to be, on time, every time.
                                        </p>
                                    </div>

                                    <div className="form-grid">
                                        <Star size={20} />
                                        <p className="subsection-module__title">
                                            <strong>Prime Campus Location:</strong>
                                        </p>
                                        <p className="subsection-module__subtitle">
                                            Immerse yourself in a vibrant neighborhood, just steps away from your academic pursuits.
                                        </p>
                                    </div>

                                    <div className="form-grid">
                                        <PhoneForwarded size={20} />
                                        <p className="subsection-module__title">
                                            <strong>Round-the-Clock Support: </strong>
                                        </p>
                                        <p className="subsection-module__subtitle">
                                            Our dedicated team is always ready to assist, ensuring a smooth and stress-free stay.
                                        </p>
                                    </div>

                                    <div className="form-grid">
                                        <MapPinned size={20} />
                                        <p className="subsection-module__title">
                                            <strong>Privacy and Peace: </strong>
                                        </p>
                                        <p className="subsection-module__subtitle">
                                            Your own personal space, designed for relaxation and focus.
                                        </p>
                                    </div> */}


                                    {fundingOption === 'nsfas' ? (

                                        <div className="amber-form-grid">
                                            <div className="form-grid">
                                                <Bus size={20} />
                                                <p className="subsection-module__title">
                                                    <strong>Effortless Commuting:</strong>
                                                </p>
                                                <p className="subsection-module__subtitle">
                                                    Reliable daily transport to get you where you need to be, on time, every time.
                                                </p>
                                            </div>

                                            <div className="form-grid">
                                                <Star size={20} />
                                                <p className="subsection-module__title">
                                                    <strong>Prime Campus Location:</strong>
                                                </p>
                                                <p className="subsection-module__subtitle">
                                                    Immerse yourself in a vibrant neighborhood, just steps away from your academic pursuits.
                                                </p>
                                            </div>

                                            <div className="form-grid">
                                                <PhoneForwarded size={20} />
                                                <p className="subsection-module__title">
                                                    <strong>Round-the-Clock Support: </strong>
                                                </p>
                                                <p className="subsection-module__subtitle">
                                                    Our dedicated team is always ready to assist, ensuring a smooth and stress-free stay.
                                                </p>
                                            </div>

                                            <div className="form-grid">
                                                <MapPinned size={20} />
                                                <p className="subsection-module__title">
                                                    <strong>Privacy and Peace: </strong>
                                                </p>
                                                <p className="subsection-module__subtitle">
                                                    Your own personal space, designed for relaxation and focus.
                                                </p>
                                            </div>
                                        </div>
                                    ) : (<div style={{ marginTop: '2px', display: 'flex', flexDirection: 'column', gap: '12px', alignItems: 'center' }}>
                                        <div
                                            // className={`transport-option-wrapper ${selectedPlan === `${apartment.ApartmentPrice}` ? 'selected' : ''}`}
                                            style={{
                                                width: '100%',
                                                maxWidth: '500px',
                                                border: '1px solid #d9534f',
                                                borderRadius: '8px',
                                                padding: '15px',
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                backgroundColor: selectedPlan === `${apartment.ApartmentPrice}` ? '#fbe9e7' : '#fff',
                                                boxShadow: selectedPlan === `${apartment.ApartmentPrice}` ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
                                            }}
                                            onClick={() => {
                                                setSelectedPlan(`${apartment.ApartmentPrice}`);
                                                handleCardClick(apartment.ApartmentPrice);
                                            }}
                                        >
                                            <input
                                                type="radio"
                                                name="promo"
                                                id="payInFull"
                                                value={`${apartment.ApartmentPrice}`}
                                                checked={selectedPlan === `${apartment.ApartmentPrice}`}
                                                onChange={(e) => setSelectedPlan(e.target.value)}
                                                // className="radio-button"
                                                style={{ display: 'none' }}
                                            />
                                            <label htmlFor="payInFull" style={{ display: 'block', cursor: 'pointer' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <p className='subsection-module__title' style={{ fontWeight: 'bold', margin: 0 }}>Pay in Full</p>

                                                    <Star size={20} fill="#CD7F32" color='CD7F32' />
                                                    <Star size={20} fill="#CD7F32" color='CD7F32' />
                                                    <Star size={20} fill="#CD7F32" color='CD7F32' />

                                                </div>
                                                <label className='bookYourPerfectAccommodation-module__title amber-form-group' style={{ fontSize: '14px', color: '#555', marginTop: '8px' }}>
                                                    Settle your full rent upfront and guarantee your spot without any delays. Quick, simple, and hassle-free                                                 </label>

                                            </label>
                                        </div>
                                        <div
                                            // className={`transport-option-wrapper ${selectedPlan === `${apartment.ApartmentPrice / 2}` ? 'selected' : ''}`}
                                            style={{
                                                width: '100%',
                                                maxWidth: '500px',
                                                border: '1px solid #d9534f',
                                                borderRadius: '8px',
                                                padding: '15px',
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                backgroundColor: selectedPlan === `${apartment.ApartmentPrice / 2}` ? '#fbe9e7' : '#fff',
                                                boxShadow: selectedPlan === `${apartment.ApartmentPrice / 2}` ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
                                            }}
                                            onClick={() => {
                                                const halfRent = apartment.ApartmentPrice / 2;
                                                setSelectedPlan(`${halfRent}`);
                                                handleCardClick(halfRent);
                                            }}>
                                            <input
                                                type="radio"
                                                name="promo"
                                                id="halfRent"
                                                value={`${apartment.ApartmentPrice / 2}`}
                                                checked={selectedPlan === `${apartment.ApartmentPrice / 2}`}
                                                onChange={(e) => setSelectedPlan(e.target.value)}
                                                // className="radio-button"
                                                style={{ display: 'none' }}
                                            />
                                            <label htmlFor="halfRent" style={{ display: 'block', cursor: 'pointer' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <p className='subsection-module__title' style={{ fontWeight: 'bold', margin: 0 }}>PAY 50% RENT NOW!</p>

                                                    <Star size={20} color='grey' fill="grey" />
                                                    <Star size={20} color='grey' fill="grey" />

                                                </div>
                                                <label className='bookYourPerfectAccommodation-module__title amber-form-group' style={{ fontSize: '14px', color: '#555', marginTop: '8px' }}>
                                                    Get a 50% discount on your first month’s rent as part of this promotional offer.
                                                </label>

                                            </label>
                                        </div>

                                        <div
                                            // className={`transport-option-wrapper ${selectedPlan === '0' ? 'selected' : ''}`}
                                            onClick={() => {
                                                setSelectedPlan('0'); // Rent is zero for this promo
                                                handleCardClick(0);
                                            }}
                                            style={{
                                                width: '100%',
                                                maxWidth: '500px',
                                                border: '1px solid #d9534f',
                                                borderRadius: '8px',
                                                padding: '15px',
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                backgroundColor: selectedPlan === '0' ? '#fbe9e7' : '#fff',
                                                boxShadow: selectedPlan === '0' ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
                                            }}

                                        >
                                            <input
                                                type="radio"
                                                name="promo"
                                                id="zeroRent"
                                                value="0"
                                                checked={selectedPlan === '0'}
                                                onChange={(e) => setSelectedPlan(e.target.value)}
                                                // className="radio-button"
                                                style={{ display: 'none' }}

                                            />
                                            <label htmlFor="zeroRent" style={{ display: 'block', cursor: 'pointer' }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <p className='subsection-module__title' style={{ fontWeight: 'bold', margin: 0 }}>PAY R0 RENT UPFRONT!!</p>

                                                    <Star size={20} color='gold' fill="#FFD700" />


                                                </div>
                                                <label className='bookYourPerfectAccommodation-module__title amber-form-group' style={{ fontSize: '14px', color: '#555', marginTop: '8px' }}>
                                                    Secure your spot today without paying rent upfront. Pay your rent only before you move-in-no stress, no hassle!                                                </label>

                                            </label>
                                        </div>
                                    </div>)}
                                </div>


                                <div className="rounded-lg lg:shadow-lg lg:p-3 lg:border border-gray-100 booking-summary">
                                    <div>
                                        <div className="flex">
                                            <p className="content-font uppercase font-semibold text-gray-500">Booking Summary</p>
                                        </div>
                                    </div>
                                    <div className="text-gray-700">
                                        <h3 className="subsection-module__title">{apartmentandbuilding.BuildingName} - {apartmentandbuilding.ApartmentNumber}</h3>
                                        <div className="divider my-3"></div>

                                        <ul className="mb-2">
                                            <li className="flex justify-between">
                                                <span className="font-semibold">Rent</span>
                                                {fundingOption === 'nsfas' ? (
                                                    <span className="text-theme-orange font-semibold">NSFAS Funded</span>

                                                ) : (<span className="text-theme-orange font-semibold">R{selectedPlan}</span>
                                                )}

                                            </li>
                                            <li className="flex justify-between pb-0.5">
                                                <span className="content-font">Service Fee (Fee) :</span>
                                                <span>R{serviceFeeCost}</span>
                                            </li>
                                            {fundingOption !== 'nsfas' && (
                                                <li className="flex justify-between pb-0.5">
                                                    <span className="content-font">Transport (Fee) :</span>
                                                    <span>R{transportCost}</span>
                                                </li>
                                            )}
                                            <div className="divider my-3"></div>
                                            <li className="flex justify-between">
                                                {fundingOption === 'nsfas' ? (<span className="font-semibold">Inclusive Fee's Due:</span>) : (<span className="font-semibold">Total Due:</span>)}
                                                <span className="text-theme-orange font-semibold">R{totalCost}</span>

                                            </li>
                                            <div className="form-grid2">
                                                <button className="btn456" onClick={prevStep}>Back</button>
                                                <button className="btn456" onClick={() => handleBookingPayment()}>Submit</button>
                                            </div>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )}


                </div>
                {showRegistrationModal && (
                    <RegistrationModal
                        // Pass down clickedAction
                        clickedAction={clickedAction}
                        onSuccess={handleRegistrationSuccess}
                        onClose={() => {
                            setShowRegistrationModal(false);
                            if (!isLoggedIn) {
                                setShowLoginPrompt(true); // Show login prompt if user cancels without logging in
                            }
                        }}
                        date={date}
                        apartment={apartment}
                        apartmentandbuilding={apartmentandbuilding}
                        totalCost={totalCost}
                        serviceFee={serviceFee}
                        chosenDefault={chosenPlan}
                        nextPaymentDate={nextPaymentDate}

                    />

                )}
            </div >
            <style>{`
  /* Container for checkboxes */
.checkbox-container {
display: inline, /* Align checkboxes side by side */
  flex-wrap: wrap; /* Allow wrapping to the next line if necessary */
  gap: 15px; /* Add spacing between checkboxes */
  justify-content: flex-start; /* Align items to the left */
  margin: 20px 0; /* Add spacing around the container */
}

.checkbox-wrapper {
  display: inline, /* Display each checkbox and its label inline */
  align-items: center; /* Align items vertically */
  gap: 10px; /* Add spacing between the checkbox and the label */
}

.checkbox-wrapper input[type="checkbox"] {
  display: none; /* Hide the default checkbox */
}

.checkbox-wrapper .terms-label {
  cursor: pointer;
  display: in-line;
  align-items: center;
}

.checkbox-wrapper .terms-label .label-text {
  margin-right: 10px; /* Add spacing after the text */
}

.checkbox-wrapper .checkbox-svg {
  width: 20px;
  height: 20px;
}

.checkbox-wrapper .checkbox-box {
  fill: rgba(207, 205, 205, 0.425);
  stroke: #ff5a5f;
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper .checkbox-tick {
  stroke: #ff5a5f;
  stroke-dasharray: 172;
  stroke-dashoffset: 172;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-box,
.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-tick {
  stroke-dashoffset: 0;
}


`}</style>


        </>
    );
}

export default NewInvoice4;



