import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

function BuildingRegister() {
  const location = useLocation();
  const navigate = useNavigate();

  const { userId, role, email } = location.state || {};

  const [buildingData, setBuildingData] = useState({
    BuildingName: '',
    Address: '',
    Floors: '',
    Units: '',
    OwnerId: userId || '',
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBuildingData({ ...buildingData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch('https://backendjaes.passiontrials.co.za/register_building', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(buildingData),
      });

      const data = await response.json();

      if (data.error) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: data.message,
          showConfirmButton: false,
          timer: 5500,
        });
        return;
      }

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Building registered successfully!",
        showConfirmButton: false,
        timer: 5500,
      });

      // Navigate to a dashboard or home page after successful building registration
      navigate('/dashboard', { state: { userId, role, email } });
    } catch (error) {
      console.error('Error during building registration:', error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Something went wrong!",
        showConfirmButton: false,
        timer: 5500,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="form-card">
            <h1>Register Your Building</h1>
            <div className="line"></div>
            <p>Logged in as: <strong>{email}</strong></p>
            <div className="input-row">
              <input
                name="BuildingName"
                value={buildingData.BuildingName}
                onChange={handleChange}
                placeholder="Building Name"
                className="zhongxin-input"
                required
              />
              <input
                name="Address"
                value={buildingData.Address}
                onChange={handleChange}
                placeholder="Address"
                className="zhongxin-input"
                required
              />
            </div>
            <div className="input-row">
              <input
                name="Floors"
                value={buildingData.Floors}
                onChange={handleChange}
                placeholder="Number of Floors"
                type="number"
                className="zhongxin-input"
                required
              />
              <input
                name="Units"
                value={buildingData.Units}
                onChange={handleChange}
                placeholder="Number of Units"
                type="number"
                className="zhongxin-input"
                required
              />
            </div>
            <button type="submit" className="btn-primary" disabled={loading}>
              {loading ? 'Registering...' : 'Register Building'}
            </button>
          </div>
        </form>
      </div>
      <style>
        {`
        .container {
          border: 1px solid #ddd;
          border-radius: 10px;
          width: 100%;
          max-width: 500px;
          margin: 20px auto;
          padding: 20px;
        }

        .form-card {
          background-color: #fff;
          padding: 20px;
          border-radius: 10px;
          text-align: center;
        }

        .line {
          height: 1px;
          background-color: #ddd;
          margin: 10px 0 20px;
          width: 100%;
        }

        .input-row {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          margin-bottom: 20px;
        }

        .zhongxin-input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
          font-size: 14px;
        }

        .btn-primary {
          width: 100%;
          padding: 10px;
          background-color: #ff385c;
          color: #fff;
          border: none;
          border-radius: 5px;
          font-size: 16px;
          cursor: pointer;
        }

        .btn-primary:disabled {
          background-color: #ddd;
          cursor: not-allowed;
        }

        .btn-primary:hover {
          background-color: #e73352;
        }
        `}
      </style>
    </div>
  );
}

export default BuildingRegister;
