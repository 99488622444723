import React, { useState } from "react";
import houseIcon from "./icons8-house-50.png"; // Adjust the path to your image file
import doorIcon from "./icons8-door-sensor-alarmed-48.png"; // Adjust the path to your image file
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation

const Steps3Admin = () => {
  const [typeOfPlace, settypeOfPlace] = useState(null);
  const navigate = useNavigate(); // Initialize the navigate function
  const location = useLocation();

  const { typeOfHouse } = location.state || {};


  const handleNext = () => {
    if (typeOfPlace) {
      navigate("/Steps3_1Admin", { state: { typeOfHouse, typeOfPlace } }); // Pass typeOfPlace to /Steps3Admin
    } else {
      alert("Please select an option before proceeding.");
    }
  };

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };
  const handleOptionClick = (option) => {
    settypeOfPlace(option);
  };

  return (
    <div>
      <style>{`
        .container {
          max-width: 600px;
          margin: 10px auto;
          padding: 20px;
          text-align: center;
        }
        .container h3 {
          font-size: 24px;
          margin-bottom: 20px;
          color: #242323;
          text-align: left;
        }
        .options-container {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
        .option {
          border: 1px solid #ddd;
          border-radius: 8px;
          padding: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          transition: border-color 0.3s;
        }
        .option:hover {
          border: 2px solid #000;
        }
        .option.selected {
          border: 2px solid #000; /* Highlight color for selected option */
        }
        .option img {
          max-width: 30px;
          margin-left: 15px;
        }
        .option .text {
          text-align: left;
          flex-grow: 1;
        }
        .option .text h4 {
          font-size: 18px;
          margin: 0;
          color: #333;
        }
        .option .text p {
          font-size: 12px;
          color: #555;
          margin: 5px 0 0;
        }
             /* Responsive Styles for Mobile */
        @media (max-width: 600px) {
          .option {
            width: 100%; /* Decrease width on small screens */
          }
          .container h3 {
            font-size: 20px;
          }
          .option img {
            max-width: 25px;
          }
        }

         .progress-step1cont {
  position: fixed; /* Fix the container at a specific position */
  bottom: 0; /* Place it at the bottom of the viewport */
  left: 0;
  right: 0;
  width: 100%; /* Make sure it spans the full width */
  max-width: 1200px;
  margin: 0 auto; /* Center it horizontally */
  padding: 0 10px;
  box-sizing: border-box;
  background: #fff; /* Add a background to prevent overlap issues */
  z-index: 1000; /* Ensure it stays above other elements */
}

        .progress-bar {
          height: 4px;
          background: #e0e0e0;
          margin-bottom: 5px;
          position: relative;
        }
        .progress {
          height: 100%;
          width: 50%;
          background: #000;
          transition: width 0.3s ease-in-out;
        }
        .buttons-step1cont {
          display: flex;
          justify-content: space-between; /* Space between buttons */
          align-items: center;
          width: 100%;
          margin-top: 10px; /* Add some space above buttons */
        }
        .back-button {
          text-decoration: underline;
          color: #000;
          background: none;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 12px; /* Reduced font size */
          padding: 15px 0px; /* Smaller padding */
        }
        .next-button {
          background: #000;
          max-width: 50px;
          color: #fff;
          border: none;
          padding: 5px 10px; /* Smaller padding */
          border-radius: 4px;
          font-size: 12px; /* Reduced font size */
          cursor: pointer;
        }        
@media (max-width: 768px) {
  .buttons-step1cont {
    justify-content: flex-start; /* Align all buttons to the left */
  }
  .next-button {
    margin-left: 150px; /* Add some spacing from the left edge */
  }
}
      `}</style>
      <div className="container">
        <h3>What type of place will Tenants have?</h3>
        <div className="options-container">
          <div
            className={`option ${
              typeOfPlace === "entirePlace" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("entirePlace")}
          >
            <div className="text">
              <h4>An entire house</h4>
              <p>Tenants have the whole house to themselves.</p>
            </div>
            <img src={houseIcon} alt="Icon" />
          </div>
          <div
            className={`option ${
              typeOfPlace === "room" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("room")}
          >
            <div className="text">
              <h4>A room</h4>
              <p>Tenants have their own room in a home, plus access to shared spaces.</p>
            </div>
            <img  src={doorIcon} alt="Icon" />
          </div>
          <div
            className={`option ${
              typeOfPlace === "outsideBuildings" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("outsideBuildings")}
          >
            <div className="text">
              <h4>Outside Buildings</h4>
              <p>Tenants sleep Outside buildings room.</p>
            </div>
            <img src={houseIcon} alt="Icon" />
          </div>
        </div>
      </div>
      <div className="progress-step1cont">
        <div className="progress-bar">
          <div className="progress"></div>
        </div>
        <div className="buttons-step1cont">
          <button className="back-button" onClick={handleBack}>
            Back
          </button>
          <button className="next-button" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Steps3Admin;
