import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

import vd from './step7.mp4';

const Steps8Admin = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const handleNext = () => {
    navigate("/RegularAdmin"); // Navigate to /steps2Admin
  };

  return (
    <div>
      <style>{`
        .step1cont {
          display: flex;
          align-items: center;
          max-width: 1200px;
          width: 100%;
          margin: 20px auto;
          padding: 10px;
          box-sizing: border-box;
          flex-wrap: wrap;
        }
        @media (min-width: 768px) {
          .step1cont {
            flex-wrap: nowrap;
          }
          .video-section {
            order: 2;
            width: 60%;
          }
          .text-section {
            order: 1;
            width: 40%;
          }
            .back-button {
          text-decoration: underline;
          color: #000;
          background: none;
          border: none;
          max-width: 150px;
          cursor: pointer;
          font-size: 12px; /* Reduced font size */
          padding: 15px 0px; /* Smaller padding */
        }
        .next-button {
          background: #000;
          max-width: 150px;
          color: #fff;
          border: none;
          padding: 5px 10px; /* Smaller padding */
          border-radius: 4px;
          font-size: 12px; /* Reduced font size */
          cursor: pointer;
        }
        }
        .text-section {
          order: 2;
          width: 100%;
          margin-bottom: 10px;
        }
        .text-section h2 {
          font-size: 14px;
          margin-bottom: 5px;
          color: #888;
          text-align: left;
        }
        .text-section h3 {
          font-size: 28px;
          margin-bottom: 10px;
          color: rgb(36, 35, 35);
          text-align: left;
        }
        .text-section p {
          font-size: 14px;
          color: #555;
          line-height: 1.4;
          text-align: left;
        }
        .video-section {
          order: 1;
          width: 100%;
          margin-bottom: 10px;
        }
        .video-section video {
          display: block;
          width: 100%;
          max-width: 100%;
          height: 400px;
        }
        .progress-step1cont {
          width: 100%;
          max-width: 1200px;
          margin: 10px auto;
          padding: 0 10px;
          box-sizing: border-box;
        }
        .progress-bar {
          height: 4px;
          background: #e0e0e0;
          margin-bottom: 5px;
          position: relative;
        }
        .progress {
          height: 100%;
          width: 100%;
          background: #000;
          transition: width 0.3s ease-in-out;
        }
        .buttons-step1cont {
          display: flex;
          justify-content: space-between; /* Space between buttons */
          align-items: center;
          width: 100%;
          margin-top: 10px; /* Add some space above buttons */
        }
        .back-button {
          text-decoration: underline;
          color: #000;
          background: none;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 12px; /* Reduced font size */
          padding: 15px 0px; /* Smaller padding */
        }
        .next-button {
          background: #000;
          max-width: 50px;
          color: #fff;
          border: none;
          padding: 5px 10px; /* Smaller padding */
          border-radius: 4px;
          font-size: 12px; /* Reduced font size */
          cursor: pointer;
        }
      `}</style>
      <div className="step1cont">
        <div className="text-section">
          <h2>Final  Step</h2>
          <h3>Your Booking Has Been Submitted Successfully</h3>
          <p>
          Your details have been successfully submitted. Please wait for confirmation via email or call. Thank you for your patience! 😊       </p>
        </div>
        <div className="video-section">
          <video autoPlay muted playsInline>
            <source src={vd} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="progress-step1cont">
        <div className="progress-bar">
          <div className="progress"></div>
        </div>
        <div className="buttons-step1cont">
        <button className="back-button" onClick={() => navigate(-1)}>
  Back
</button>

          <button className="next-button" onClick={handleNext}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default Steps8Admin;
